import * as types from '../actions/actionTypes';
import { paginationLimit } from '../../constants';

const defaultBots = {
  limit: paginationLimit,
  page: 0,
  resultCount: 0,
  totalPages: 1,
  bots: [],
};

export default function botReducer(state = {
  bots: defaultBots,
  error: '',
  addedBot: null,
  isSearching: false,
  isSearchingBotMembers: false,
}, action) {
  switch (action.type) {
    case types.BOT_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.CLEAR_BOT_ERROR: {
      return {
        ...state,
        error: '',
      };
    }
    case types.LOAD_BOTS_SUCCESS:
    case types.SEARCH_BOTS_SUCCESS: {
      return {
        ...state,
        bots: action.payload,
        isSearching: false,
      };
    }
    case types.BOTS_LOADING: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case types.BOT_MEMBERS_LOADING: {
      return {
        ...state,
        isSearchingBotMembers: true,
      };
    }
    case types.LOAD_BOTS_FAILURE:
    case types.SEARCH_BOTS_FAILURE: {
      return {
        ...state,
        bots: defaultBots,
        error: action.payload,
        isSearching: false,
      };
    }
    case types.ADD_BOTS_SUCCESS: {
      return {
        ...state,
        addedBot: action.payload,
      };
    }
    case types.ADD_BOTS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.LOAD_BOT_SUCCESS: {
      return {
        ...state,
        selectedBot: action.payload,
        isSearchingBotMembers: false,
      };
    }
    case types.SHOW_BOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        password: action.payload,
      };
    }
    case types.HIDE_BOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        password: null,
      };
    }
    case types.CLEAR_BOT_SUCCESS: {
      return {
        ...state,
        selectedBot: null,
        password: null,
        isSearchingBotMembers: false,
      };
    }
    case types.LOGOUT_FAILURE:
    case types.LOGOUT_SUCCESS: {
      return {
        bots: defaultBots,
        error: '',
      };
    }
    default: {
      return state;
    }
  }
}
