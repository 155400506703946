import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
  Button, Input, Icon, Label,
} from 'rtm-ui-components';
import Message from '../../../../SharedComponents/Message/Message';
import { parseURLParams, invalidPassword } from '../../../../../utils';
import * as authActions from '../../../../../store/actions/auth';
import './ResetPasswordForm.css';

const passwordsDonotMatch = values => !!values.password
  && !!values.confirmpassword && values.password !== values.confirmpassword;

// password matches config setting

const validate = (values, passwordConfig) => {
  const error = {};

  if (!values.password) {
    error.password = 'Required';
  } else if (invalidPassword(values.password, passwordConfig)) {
    error.password = passwordConfig && passwordConfig.description;
  }
  if (!values.confirmpassword) {
    error.confirmpassword = 'Required';
  } else if (passwordsDonotMatch(values)) {
    error.confirmpassword = 'The two passwords do not match.';
  }
  return error;
};


class ResetPasswordForm extends Component {
  state = {
    id: '',
    token: '',
    error: {},
  };

  componentDidMount() {
    const { checkPasswordResetToken } = this.props;
    const { id, token } = parseURLParams(window.location.search);
    this.setState({ token, id });
    checkPasswordResetToken(token);
  }

  isDisabled = () => {
    const { password, confirmpassword, error } = this.state;
    if (!password || !password.trim()) return true;
    if (!confirmpassword || !confirmpassword.trim()) return true;
    if (Object.getOwnPropertyNames(error).length) return true;
    return false;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    const { password, token } = this.state;
    onSubmit({ token, password });
  };

  handleInput = async (t) => {
    const { passwordConfig } = this.props;
    await this.setState({ [t.name]: t.value });
    const { password, confirmpassword } = this.state;
    const error = validate({ password, confirmpassword }, passwordConfig);
    this.setState({ error });
  };

  render() {
    const {
      passwordConfig, submitting, resetPasswordError,
    } = this.props;
    const { id, token, error } = this.state;
    return (
      <div>
        <form onSubmit={async (e) => { await this.handleSubmit(e); }} className="signup-form">
          <p className="login-link"><Link className="login-link-text" to="/login"><Icon size="small" name="arrowLeft" /><strong>Login</strong></Link></p>
          <div className="form-message-container">
            {(resetPasswordError) && (<Message negative header={resetPasswordError} />)}
          </div>
          <div className="password-form-field">
            <Input name="id" type="hidden" defaultValue={id} />
            <Input name="token" type="hidden" defaultValue={token} />
          </div>
          <div className="password-form-field">
            <Label
              htmlFor="password"
              text="Password"
              info={passwordConfig && passwordConfig.description}
            />
            <Input placeholder="" name="password" type="password" size="small" onChange={e => this.handleInput(e.target)} error={error.password} fluid />
          </div>
          <div className="password-form-field">
            <label htmlFor="confirmpassword">Confirm Password</label>
            <Input placeholder="" name="confirmpassword" type="password" size="small" onChange={e => this.handleInput(e.target)} error={error.confirmpassword} fluid />
          </div>
          <Button
            loading={submitting}
            variant="primary"
            type="submit"
            id="signup-btn"
            fluid
            disabled={this.isDisabled()}
          >
            RESET PASSWORD
          </Button>
        </form>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => (bindActionCreators({
  checkPasswordResetToken: authActions.checkPasswordResetToken,
}, dispatch));

const mapStateToProps = state => ({
  passwordConfig: state.configs.publicConfigs['password.strength'],
  resetPasswordError: state.auth.resetPasswordError,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
