import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRangePicker } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import {
  Input, Button, Label, Icon, RadioButton,
} from 'rtm-ui-components';
import Message from '../../../SharedComponents/Message/Message';
import * as userActions from '../../../../store/actions/users';
import HasRole from '../../../../roles/HasRole';
import { isValidEmail, invalidUsername, invalidName } from '../../../../utils';
import '../../../../assets/stylesheets/EditForm.css';

class EditUserForm extends Component {
  state = {
    submitting: false,
    submitted: false,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    role: '',
    external: false,
    organisation: '',
    focusedInput: null,
    activityStartTime: null,
    activityEndTime: null,
    touched: {
      firstName: true,
      lastName: true,
      username: true,
      email: true,
    },
    formErrors: {
      firstName: false,
      lastName: false,
      username: false,
      email: false,
    },
  }

  componentDidMount() {
    const { initialValues } = this.props;
    this.setState({
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
      username: initialValues.username,
      email: initialValues.email,
      role: initialValues.role,
      external: initialValues.role === 'ext',
      activityEndTime: initialValues.activityEndTime,
      activityStartTime: initialValues.activityStartTime,
      organisation: initialValues.organisation,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      firstName, lastName, username, email, external, activityStartTime, activityEndTime, organisation, role,
    } = this.state;
    const { updateUser, user, searchUsers } = this.props;
    this.setState({
      submitting: true,
    });
    const submittedDetails = {
      ...user,
      firstName,
      lastName,
      username,
      email,
      role,
      external,
    };
    if (external) {
      submittedDetails.activityStartTime = activityStartTime ? activityStartTime.clone().utc().startOf('date').toISOString() : undefined;
      submittedDetails.activityEndTime = activityEndTime ? activityEndTime.clone().utc().endOf('date').toISOString() : undefined;
      submittedDetails.organisation = organisation;
    }
    await updateUser(submittedDetails);
    searchUsers();
    this.setState({ submitted: true, submitting: false });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const { formErrors, touched } = this.state;
    const { nameConfig, usernameConfig } = this.props;
    switch (name) {
      case 'firstName':
      case 'lastName':
        if (invalidName(value, nameConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        touched[name] = true;
        break;
      case 'username':
        if (invalidUsername(value, usernameConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        break;
      case 'email':
        if (!isValidEmail(value)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors, [name]: value, submitted: false,
    });
  }

  isDisabled = () => {
    const {
      formErrors, role, external,
    } = this.state;
    const { initialValues } = this.props;
    let error = false;
    Object.keys(formErrors).forEach((key) => {
      if (formErrors[key]) {
        error = true;
      }
    });
    if (error) { return true; }

    let notChanged = true;
    const fields = ['firstName', 'lastName', 'username', 'email'];
    if (external) {
      fields.push('organisation');
      const extFields = ['activityStartTime', 'activityEndTime'];
      extFields.forEach((field) => {
        // eslint-disable-next-line react/destructuring-assignment
        if(initialValues[field] !== null && this.state[field] !== null){
          if (initialValues[field].format('LL') !== this.state[field].format('LL')) {
            notChanged = false;
          }
        }
      });
    }
    fields.forEach((field) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (initialValues[field] !== this.state[field]) {
        notChanged = false;
      }
    });
    if (initialValues.role !== role) {
      notChanged = false;
    }
    return notChanged;
  }

  changeRole = (event) => {
    if (event.target.value === 'ext') {
      this.setState({
        role: event.target.value,
        external: true,
        submitted: false,
      });
    } else {
      this.setState({
        role: event.target.value,
        external: false,
        submitted: false,
      });
    }
  }

  render() {
    const {
      onCancel,
      usernameConfig, nameConfig, error, initialValues,
    } = this.props;
    const {
      role,
      activityStartTime, activityEndTime, focusedInput, formErrors, submitting, external, submitted,
    } = this.state;
    const deniedPolicies = JSON.parse(localStorage.getItem("deniedPolicies"));
    return (
      <div className="details-edit-container">
        <form onSubmit={this.handleSubmit} id="add-user-form" className="add-entity-view-form">
          <div className="header-bar">
            <span className="header">EDIT DETAILS</span>
          </div>
          <div className="edit-form-fields">
            <div className="edit-fields-left">
              <div className="add-entity-field">
                <Label text="First name" info={nameConfig && nameConfig.description} htmlFor="firstname-field" />
                <Input
                  fluid
                  type="text"
                  placeholder="First Name"
                  id="firstname-field"
                  defaultValue={initialValues.firstName}
                  onChange={this.handleChange}
                  name="firstName"
                  error={formErrors.firstName}
                  disabled={deniedPolicies.deniedPolicies.includes("user.firstame")}
                />
              </div>
              <div className="add-entity-field">
                <Label text="Last name" info={nameConfig && nameConfig.description} htmlFor="lastname-field" />
                <Input
                  fluid
                  type="text"
                  placeholder="Last Name"
                  id="lastname-field"
                  defaultValue={initialValues.lastName}
                  onChange={this.handleChange}
                  name="lastName"
                  error={formErrors.lastName}
                  disabled={deniedPolicies.deniedPolicies.includes("user.lastname")}
                />
              </div>
              <HasRole requiredRole="sysAdmin">
                <div className="add-entity-field">
                  <Label text="Username" info={usernameConfig && usernameConfig.description} htmlFor="username-field" />
                  <Input
                    fluid
                    type="text"
                    placeholder="Username"
                    id="username-field"
                    defaultValue={initialValues.username}
                    name="username"
                    onChange={this.handleChange}
                    error={formErrors.username}
                    disabled={deniedPolicies.deniedPolicies.includes("user.username")}
                  />
                </div>
              </HasRole>
                <div className="add-entity-field" >
                <Label text="Email" htmlFor="email-field" />
                <Input
                  fluid
                  type="email"
                  placeholder="Email"
                  id="email-field"
                  defaultValue={initialValues.email}
                  name="email"
                  onChange={this.handleChange}
                  error={formErrors.email}
                  disabled={deniedPolicies.deniedPolicies.includes("user.email")}
                />
              </div>
              <HasRole requiredRole="admin">
                <Label text="Roles" />
                <div className="roles">
                  <RadioButton
                    className="role"
                    label={(
                      <span className="role-icon-wrapper"><Icon color="primary" name="user" size="small" /> User</span>
                    )}
                    id="user"
                    name="role"
                    onChange={this.changeRole}
                    checked={role === 'user'}
                    value="user"
                  />
                  <RadioButton
                    className="role"
                    label={(
                      <span className="role-icon-wrapper"><Icon style={{ fill: '#E551A5' }} name="admin" size="small" /> Admin</span>
                    )}
                    id="admin"
                    name="role"
                    onChange={this.changeRole}
                    defaultChecked={initialValues.role === 'admin'}
                    value="admin"
                  />
                  <HasRole requiredRole="sysAdmin">
                    <RadioButton
                      className="role"
                      label={(
                        <span className="role-icon-wrapper"><Icon style={{ fill: '#2EC7AA' }} name="sysadmin" size="small" /> Sysadmin</span>
                      )}
                      id="sysadmin"
                      name="role"
                      onChange={this.changeRole}
                      defaultChecked={initialValues.role === 'sysadmin'}
                      value="sysadmin"
                    />
                  </HasRole>
                  <RadioButton
                    className="role"
                    label={(
                      <span className="role-icon-wrapper"><Icon style={{ fill: '#0E0D76' }} name="externalUser" size="small" /> External user</span>
                    )}
                    id="ext"
                    name="role"
                    onChange={this.changeRole}
                    defaultChecked={initialValues.role === 'ext'}
                    value="ext"
                  />
                </div>
                { external ? (
                  <React.Fragment>
                    <div className="field">
                      <Label
                        text="Active period"
                        htmlFor="activeStartDateField"
                        info="Activity starts on selected start date at 00:00 UTC and ends at end date at 23:59 UTC"
                      />
                      <DateRangePicker
                        startDatePlaceholderText="DD.MM.YYYY"
                        startDate={activityStartTime} // momentPropTypes.momentObj or null,
                        startDateId="activeStartDateField" // PropTypes.string.isRequired,
                        endDatePlaceholderText="DD.MM.YYYY"
                        endDate={activityEndTime} // momentPropTypes.momentObj or null,
                        endDateId="activeEndDateField" // PropTypes.string.isRequired,
                        onDatesChange={(values) => {
                          this.setState({
                            activityStartTime: values.startDate ? moment(values.startDate.utc().toISOString()) : null,
                            activityEndTime: values.endDate ? moment(values.endDate.utc().toISOString()) : null,
                          });
                        }} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focus => this.setState({ focusedInput: focus })} // PropTypes.func.isRequired,
                        openDirection={OPEN_UP}
                        noBorder
                        required
                        numberOfMonths={1}
                        displayFormat="DD.MM.YYYY"
                        hideKeyboardShortcutsPanel
                      />
                    </div>
                    <div className="add-entity-field">
                      <Label text="Organisation" htmlFor="organisation-field" />
                      <Input
                        fluid
                        type="text"
                        placeholder="organisation (optional)"
                        id="organisation-field"
                        name="organisation"
                        onChange={this.handleChange}
                        defaultValue={initialValues.organisation}
                      />
                    </div>
                  </React.Fragment>
                ) : null }
              </HasRole>
            </div>
            <div className="side">
              <div className="edit-form-buttons">
                <Button
                  loading={submitting}
                  variant="primary"
                  type="submit"
                  id="edit-user-btn"
                  disabled={this.isDisabled()}
                >
                  Save
                </Button>
                <Button
                  id="close-btn"
                  onClick={onCancel}
                  variant="secondary"
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          <div className="form-message-container">
            {(submitted && !error)
              && (
                <Message positive header="User Updated" />
              )
            }
            {(submitted && error)
              && (
                <Message negative header={error} />
              )
            }
          </div>
        </form>
      </div>
    );
  }
}

// const EditUserReduxForm = reduxForm({ form: 'editUser', validate, enableReinitialize: true })(EditUserForm);

const mapDispatchToProps = dispatch => (bindActionCreators({
  deactivateUser: userActions.deactivateUser,
  activateUser: userActions.activateUser,
  updateUser: userActions.updateUser,
}, dispatch));

const mapStateToProps = (state) => {
  const { selectedUser: user } = state.users;
  return {
    initialValues: {
      firstName: user.firstName,
      username: user.username,
      lastName: user.lastName,
      role: user.appRole,
      email: user.email,
      uuid: user.uuid,
      active: user.active,
      activityEndTime: user.activityEndTime ? moment(user.activityEndTime).utc() : moment().add(1, 'months'),
      activityStartTime: user.activityStartTime ? moment(user.activityStartTime).utc() : moment(),
      organisation: user.organisation,
    },
    user,
    loggedInUser: state.auth.user,
    usernameConfig: state.configs.configs['username.pattern'],
    nameConfig: state.configs.configs['name.pattern'],
    error: state.users.addUserError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm);
