/* eslint-disable no-plusplus */
import * as types from '../actions/actionTypes';

export default function configsReducer(state = {
  configs: {},
  publicConfigs: {},
}, action) {
  switch (action.type) {
  case types.GET_CONFIGS: {
    const confs = {};
    for (let i = 0; i < action.payload.length; i++) {
      confs[action.payload[i].name] = action.payload[i];
    }
    return {
      ...state, configs: { ...confs },
    };
  }
  case types.GET_PUBLIC_CONFIGS: {
    const confs = {};
    for (let i = 0; i < action.payload.length; i++) {
      confs[action.payload[i].name] = action.payload[i];
    }
    return {
      ...state, publicConfigs: { ...confs },
    };
  }
  case types.LOGOUT_FAILURE:
  case types.LOGOUT_SUCCESS: {
    return { configs: {}, publicConfigs: {} };
  }
  default: {
    return state;
  }
  }
}
