import * as types from './actionTypes';

const getNotificationSuccess = message => ({ type: types.GET_NOTIFICATION_SUCCESS, payload: message });
const getNotificationFailure = error => ({ type: types.GET_NOTIFICATION_FAILURE, payload: error });
const setNotificationSuccess = message => ({ type: types.SET_NOTIFICATION_SUCCESS, payload: message });
const setNotificationFailure = error => ({ type: types.SET_NOTIFICATION_FAILURE, payload: error });
const deleteNotificationSuccess = () => ({ type: types.DELETE_NOTIFICATION_SUCCESS });
const deleteNotificationFailure = error => ({ type: types.DELETE_NOTIFICATION_FAILURE, payload: error });

export const getNotification = () => async (dispatch, getState, api) => {
  try {
    const message = await api.get('/notification');
    dispatch(getNotificationSuccess(message));
  } catch (error) {
    dispatch(getNotificationFailure(error));
  }
};

export const hideNotification = () => async (dispatch) => {
  dispatch({ type: types.HIDE_NOTIFICATION });
};

export const setNotification = message => async (dispatch, getState, api) => {
  try {
    const result = await api.postbody('/notification', { message });
    dispatch(setNotificationSuccess(result));
  } catch (error) {
    dispatch(setNotificationFailure(error));
  }
};

export const deleteNotification = () => async (dispatch, getState, api) => {
  try {
    await api.delete('/notification');
    dispatch(deleteNotificationSuccess());
  } catch (error) {
    dispatch(deleteNotificationFailure(error));
  }
};
