import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalsActions from '../../store/actions/modals';
import Notifications from './Notifications/Notifications';

class NotificationsModals extends Component {
  render() {
    const { modals, closeModal } = this.props;
    const allModals = modals.map(item => <Notifications key={item.id} item={item} onClose={() => closeModal(item)} />);
    const rendered = allModals.length ? <div>{allModals}</div> : null;
    return (
      rendered
    );
  }
}
const mapStateToProps = state => ({
  modals: state.modals.modals,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  closeModal: modalsActions.closeModal,
}, dispatch));
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsModals);
