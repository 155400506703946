import * as types from './actionTypes';

const loadAuditLogsSuccess = logs => ({ type: types.LOAD_AUDITLOGS_SUCCESS, payload: logs });
const loadAuditLogsFailure = error => ({ type: types.LOAD_AUDITLOGS_FAILURE, payload: error });
const loadClearAuditEvents = () => ({ type: types.CLEAR_AUDIT_EVENTS });
const loadingAuditLogs = () => ({ type: types.AUDITLOGS_LOADING });
const loadAuditLogOperationsSuccess = operations => ({ type: types.LOAD_AUDITLOGOPERATIONS_SUCCESS, payload: operations });
const loadAuditLogOperationsFailure = operations => ({ type: types.LOAD_AUDITLOGOPERATIONS_FAILURE, payload: operations });

const getErrorMessage = error => ((error.response) ? error.response.dataMessage : error.message);


export const getAuditLogs = (params = {}) => async (dispatch, getState, api) => {
  try {
    dispatch(loadingAuditLogs());
    const urlParams = new URLSearchParams({ ...params });
    const response = await api.get(`/auditevents/search?${urlParams}`);
    dispatch(loadAuditLogsSuccess(response));
  } catch (error) {
    dispatch(loadAuditLogsFailure(getErrorMessage(error)));
  }
};


export const clearAuditEvents = () => async (dispatch, getState, api) => {
  dispatch(loadClearAuditEvents());
};

export const getAuditLogOperations = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get('/auditevents/operations');
    dispatch(loadAuditLogOperationsSuccess(response));
  } catch (error) {
    dispatch(loadAuditLogOperationsFailure(getErrorMessage(error)));
  }
};
