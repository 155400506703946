/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AuthPage from './AuthPage/AuthPage';
import * as authActions from '../../store/actions/auth';

const shouldRedictToPasswordRecovery = authError => /Your password has expired/.test(authError);
// /Your password has expired/.test(authError)
//     && ((location && location.state
//     && location.state.prevPath !== '/recoverpassword'));


const Login = (props) => {
  const { from } = props.location.state && props.location.state.from.pathname !== '/logout' ? props.location.state : { from: { pathname: '/' } };

  const { user, login, authError } = props;

  if (user) {
    return (<Redirect to={from} />);
  }

  if (shouldRedictToPasswordRecovery(authError)) {
    return (<Redirect to="/recoverpassword" />);
  }

  return (
    <AuthPage
      loginPage
      submitAction={login}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  authError: state.auth.loginError,
  authMessage: state.auth.message,
});
const mapDispatchToProps = dispatch => (bindActionCreators({
  login: authActions.login,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Login);
