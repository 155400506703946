import React, { Component } from 'react';
import { Notification } from 'rtm-ui-components';

class Notifications extends Component {
  onClose() {
    const { item, onClose } = this.props;
    if (item.onClose) {
      item.onClose();
      onClose(item);
    } else {
      onClose(item);
    }
  }

  onConfirm() {
    const { item, onClose } = this.props;
    if (item.onConfirm) {
      item.onConfirm();
      onClose(item);
    } else {
      onClose(item);
    }
  }

  render() {
    const { item } = this.props;
    const { type } = item;
    if (type === 'Error') {
      const { text } = item;
      return (
        <Notification
          id="error-modal"
          open={!!item} onClose={() => this.onClose()}
          onCancel={() => this.onClose()}
          size="tiny"
          header="ERROR"
          cancelButtonId="error-modal-close"
          cancelButton="Close"
        >{text}
        </Notification>
      );
    }
    if (type === 'Password_Expiration') {
      const { text, title, until } = item;
      return (
        <Notification
          id="password-expiration-modal"
          open={!!item} onClose={() => this.onClose()}
          onConfirm={() => this.onConfirm()}
          onCancel={() => this.onClose()}
          size="tiny"
          header={title}
          cancelButton="Later"
          cancelButtonId="password-prompt-modal-later"
          confirmButton="Yes"
          confirmButtonId="password-prompt-modal-yes"
        >
          <div>
            <div>
              {until}
            </div>
            <div>
              {text}
            </div>
          </div>
        </Notification>
      );
    }
    if (type === 'Redirect') {
      const { text, title } = item;
      return (
        <Notification
          id="redirect-modal"
          open={!!item} onClose={() => this.onClose()}
          onCancel={() => this.onClose()}
          size="tiny"
          header={title}
          cancelButtonId="redirect-modal-close"
          cancelButton="Close"
        >{text}
        </Notification>
      );
    }
    return (
      null
    );
  }
}


export default Notifications;
