/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Input, Label, Icon, TextArea, Checkbox,
} from 'rtm-ui-components';
import Message from '../../../SharedComponents/Message/Message';
import HasRole from '../../../../roles/HasRole';
import { invalidName, invalidGroupShortname } from '../../../../utils';
import * as groupActions from '../../../../store/actions/groups';
import '../../../../assets/stylesheets/EditForm.css';

class EditGroupForm extends Component {
  state = {
    submitting: false,
    submitted: false,
    longname: '',
    name: '',
    description: '',
    isPublic: '',
    uuid: '',
    touched: {
      longname: false,
      name: false,
      description: false,
      isPublic: false,
    },
    formErrors: {
      longname: false,
      name: false,
      description: false,
      isPublic: false,
    },
  }

  componentDidMount() {
    const { initialValues } = this.props;
    this.setState({
      longname: initialValues.longname,
      name: initialValues.name,
      description: initialValues.description,
      isPublic: initialValues.isPublic,
      uuid: initialValues.uuid,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      longname, name, description, isPublic, uuid,
    } = this.state;
    const { updateGroup, searchGroups } = this.props;
    this.setState({ submitting: true, submitted: false });
    const submittedDetails = {
      longname,
      name,
      description,
      isPublic,
      uuid,
    };
    await updateGroup(submittedDetails);
    searchGroups();
    this.setState({ submitting: false, submitted: true });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const { formErrors, touched } = this.state;
    const { nameConfig, groupNameConfig } = this.props;
    switch (name) {
      case 'longname':
        if (invalidName(value, nameConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        touched[name] = true;
        break;
      case 'name':
        if (invalidGroupShortname(value, groupNameConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        touched[name] = true;
        break;
      case 'description':
        touched[name] = true;
        break;
      default:
        break;
    }
    this.setState({
      formErrors, touched, [name]: value, submitted: false,
    });
  }

  isDisabled = () => {
    const { formErrors } = this.state;
    const { group } = this.props;
    let error = false;
    Object.keys(formErrors).forEach((key) => {
      if (formErrors[key]) {
        error = true;
      }
    });
    if (error) { return true; }
    const fields = ['name', 'longname', 'description', 'isPublic'];
    let notChanged = true;
    fields.forEach((field) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (group[field] !== this.state[field]) {
        notChanged = false;
      }
    });
    return notChanged;
  }

  toggleIsPublic = () => {
    const { isPublic } = this.state;
    this.setState({
      isPublic: !isPublic,
    });
  }

  render() {
    const {
      onCancel, error, nameConfig, groupNameConfig,
    } = this.props;
    const {
      longname, name, formErrors, description, submitting, isPublic, submitted,
    } = this.state;
    return (
      <div className="details-edit-container">
        <form onSubmit={this.handleSubmit} id="add-group-form" className="add-entity-view-form">
          <div className="header-bar">
            <span className="header">EDIT DETAILS</span>
          </div>
          <div className="edit-form-fields">
            <div className="edit-fields-left">
              <div className="add-entity-field">
                <Label htmlFor="longname-field" text="Group Name" info={nameConfig && nameConfig.description} />
                <Input
                  fluid
                  type="text"
                  placeholder="Group Name"
                  name="longname"
                  error={formErrors.longname}
                  id="longname-field"
                  value={longname}
                  onChange={e => this.handleChange(e)}
                />
              </div>
              <HasRole requiredRole="groupNameEdit">
                <div className="add-entity-field">
                  <Label htmlFor="name-field" text="Group ID" info={groupNameConfig && groupNameConfig.description} />
                  <Input
                    fluid
                    type="text"
                    placeholder="Group ID"
                    name="name"
                    error={formErrors.name}
                    id="name-field"
                    value={name}
                    onChange={e => this.handleChange(e)}
                  />
                </div>
              </HasRole>
              <div className="add-entity-field">
                <Label htmlFor="description" text="Description" />
                <TextArea
                  fluid
                  value={description}
                  name="description"
                  id="description"
                  error={formErrors.description}
                  onChange={(e => this.handleChange(e))}
                  placeholder="Description of Group"
                  rows="4"
                />
              </div>
              <div>
                <Label text="Status" />
                <div className="status roles">
                  <Checkbox
                    className="role"
                    id="edit-group-public"
                    checked={isPublic}
                    label={(<span className="role-icon-wrapper"><Icon name="public" color="primary" ariaLabel="public" />Public</span>)}
                    name="isPublic"
                    onChange={this.toggleIsPublic}
                  />
                </div>
              </div>
            </div>
            <div className="side">
              <div className="edit-form-buttons">
                <Button
                  variant="primary"
                  loading={submitting}
                  type="submit"
                  id="edit-group-btn"
                  disabled={this.isDisabled()}
                >
                  Save
                </Button>
                <Button
                  id="close-btn"
                  onClick={onCancel}
                  variant="secondary"
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          <div className="form-message-container">
            {(submitted && !error)
              && (
                <Message positive className="updated-entity" header="Group Updated" />
              )
            }
            {submitted && error
              && (
                <Message negative header={error} />
              )
            }
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  updateGroup: groupActions.updateGroup,
}, dispatch));

const mapStateToProps = (state) => {
  const { selectedGroup: group } = state.groups;
  return {
    initialValues: {
      name: group.name,
      longname: group.longname,
      description: group.description,
      uuid: group.uuid,
      isPublic: group.isPublic,
    },
    group,
    error: state.groups.error,
    nameConfig: state.configs.configs['grouplongname.pattern'],
    groupNameConfig: state.configs.configs['groupname.pattern'],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupForm);
