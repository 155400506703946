import PropTypes from 'prop-types';

export const User = {
  id: PropTypes.number,
  uuid: PropTypes.string,
  active: PropTypes.bool,
  email: PropTypes.string,
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isPassthrough: PropTypes.bool,
  passwordExpirationTime: PropTypes.string,
  passwordExpirationMinutes: PropTypes.number,
  appRole: PropTypes.string,
  isBot: PropTypes.bool,
  isPublic: PropTypes.bool,
  source: PropTypes.string,
  loggedOut: PropTypes.bool,
  created: PropTypes.string,
  updated: PropTypes.string,
  locked: PropTypes.bool,
  enabled: PropTypes.bool,
  credentialsNonExpired: PropTypes.bool,
  localUser: PropTypes.bool,
  accountNonExpired: PropTypes.bool,
  accountNonLocked: PropTypes.bool,
};

export const Bot = {
  id: PropTypes.number,
  uuid: PropTypes.string,
  active: PropTypes.bool,
  email: PropTypes.string,
  username: PropTypes.string,
  isPassthrough: PropTypes.bool,
  appRole: PropTypes.string,
  isBot: PropTypes.bool,
  isPublic: PropTypes.bool,
  source: PropTypes.string,
  loggedOut: PropTypes.bool,
  created: PropTypes.string,
  updated: PropTypes.string,
  locked: PropTypes.bool,
  enabled: PropTypes.bool,
  credentialsNonExpired: PropTypes.bool,
  localUser: PropTypes.bool,
  accountNonExpired: PropTypes.bool,
  accountNonLocked: PropTypes.bool,
};

export const Group = {
  id: PropTypes.number,
  uuid: PropTypes.string,
  name: PropTypes.string,
  longname: PropTypes.string,
  description: PropTypes.string,
  isPublic: PropTypes.bool,
  created: PropTypes.string,
  updated: PropTypes.string,
};

export const Role = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
};

export const Operations = {
  CREATE_USER: PropTypes.string,
  SEND_INVITATION: PropTypes.string,
  DELETE_USER: PropTypes.string,
  USER_DEACTIVATE: PropTypes.string,
  USER_ACTIVATE: PropTypes.string,
  USER_UNLOCK: PropTypes.string,
  CHANGE_PASSWORD: PropTypes.string,
  PASSWORD_RESET: PropTypes.string,
  PASSWORD_RESET_LINK: PropTypes.string,
  UPDATE_USER: PropTypes.string,
  UPDATE_USER_ROLE: PropTypes.string,
  CREATE_GROUP: PropTypes.string,
  UPDATE_GROUP: PropTypes.string,
  ADD_GROUP_MEMBER: PropTypes.string,
  UPDATE_GROUP_MEMBER: PropTypes.string,
  REMOVE_GROUP_MEMBER: PropTypes.string,
  DELETE_GROUP: PropTypes.string,
  CREATE_BOT: PropTypes.string,
  UPDATE_BOT: PropTypes.string,
  UPDATE_BOT_ROLE: PropTypes.string,
  BOT_DEACTIVATE: PropTypes.string,
  BOT_ACTIVATE: PropTypes.string,
  BOT_UNLOCK: PropTypes.string,
  ADD_BOT_MEMBER: PropTypes.string,
  UPDATE_BOT_MEMBER: PropTypes.string,
  REMOVE_BOT_MEMBER: PropTypes.string,
  AUTHENTICATION_SUCCESS: PropTypes.string,
  AUTHENTICATION_FAILURE: PropTypes.string,
};

export const Options = {
  key: PropTypes.string,
  value: PropTypes.bool,
  text: PropTypes.string,
  id: PropTypes.string,
};

export const Limit = [20, 50, 100, '20', '50', '100'];
