import React from 'react';
import PropTypes from 'prop-types';
import './Segment.css';

const Segment = ({ children }) => (
  <div className="segment-wrapper">
    {children}
  </div>
);

Segment.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default Segment;
