import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Dropdown, Spinner, Label,
} from 'rtm-ui-components';
import * as botActions from '../../../../store/actions/bots';
import * as userActions from '../../../../store/actions/users';
import AddEntityWrapper from '../../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import { botRoleFilters, botActivityFilters, botVisibilityFilters } from '../../../../constants';
import DetailsCard from '../../../SharedComponents/DetailsCard/DetailsCard';
import AddUserBots from './AddUserBots/AddUserBots';
import HasRole from '../../../../roles/HasRole';
import Table from '../../../SharedComponents/Table/Table';
import { icon, entityCardDetails, ucfirst } from '../../../../utils';
import AddFirstEntity from '../../../SharedComponents/AddFirstEntity/AddFirstEntity';
import '../../../../assets/stylesheets/DetailsViewContent.css';
import ChangeView from '../../../SharedComponents/ChangeView/ChangeView';


class UserBots extends Component {
  state = {
    filter: '',
    activityFilter: 'active',
    roleFilter: 'all',
    visibilityFilter: 'all',
    addBots: false,
    cards: true,
  };

  componentDidMount() {
    const { getSelectedUserBots } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    if (settings && settings.detailsListingCards !== undefined && !settings.detailsListingCards) {
      this.setState({ cards: false });
    }
    getSelectedUserBots();
  }

  onChangeFilter = async (value, type) => {
    if (value === 'all') {
      switch (type) {
        case 'userRole':
          await this.setState({ roleFilter: null });
          break;
        case 'visibility':
          await this.setState({ visibilityFilter: null });
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'userRole':
          await this.setState({ roleFilter: value });
          break;
        case 'visibility':
          await this.setState({ visibilityFilter: value });
          break;
        default:
          break;
      }
    }
    if (type === 'activity') {
      await this.setState({ activityFilter: value });
    }
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.detailsListingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  filterContent = (arrayToFilter) => {
    const filterOptions = arrayToFilter.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    return filterOptions;
  }

  filteredBots = (bots, filter, roleFilter, activityFilter, visibilityFilter) => {
    let filteredBots = [...bots];
    filteredBots = filteredBots.filter(({ user }) => user.username.includes(filter));

    if (['default', 'integration', 'admin'].includes(roleFilter)) {
      filteredBots = filteredBots.filter(({ user }) => user.appRole === roleFilter);
    }
    if (['active', 'inactive'].includes(activityFilter)) {
      filteredBots = filteredBots.filter(({ user }) => (
        user.active === (activityFilter === 'active')
      ));
    }
    if (activityFilter === 'locked') {
      filteredBots = filteredBots.filter(({ user }) => (
        !!user.locked
      ));
    }
    if (['private', 'public'].includes(visibilityFilter)) {
      filteredBots = filteredBots.filter(({ user }) => (visibilityFilter === 'public' && user.isPublic) || (visibilityFilter === 'private' && !user.isPublic));
    }

    return filteredBots;
  }

  toggleModal = () => {
    const { addBots } = this.state;
    this.setState({ addBots: !addBots });
  }

  removeUserBot = async (user, bot) => {
    const { removeUserBot, getSelectedUserBots } = this.props;
    await removeUserBot(user, bot);
    getSelectedUserBots();
  }

  addBotAdmin = async (user, bot) => {
    const { addBotAdmin, getSelectedUserBots } = this.props;
    await addBotAdmin(user, bot);
    getSelectedUserBots();
  }

  removeBotAdmin = async (user, bot) => {
    const { removeBotAdmin, getSelectedUserBots } = this.props;
    await removeBotAdmin(user, bot);
    getSelectedUserBots();
  }

  render() {
    const {
      user, getSelectedUserBots, loading,
    } = this.props;
    const {
      filter, activityFilter, roleFilter, visibilityFilter, addBots, cards,
    } = this.state;

    if (!user) {
      return null;
    }
    const userBotsFiltered = user && user.bots && this.filteredBots(user.bots, filter, roleFilter, activityFilter, visibilityFilter);

    let renderedType;
    if ((!user.bots || !user.bots.length) && loading) {
      renderedType = (
        <div className="details-list-container table">
          <Spinner />
        </div>
      );
    } else if (!user.bots || !user.bots.length) {
      renderedType = (
        <div className="details-list-container table">
          <AddFirstEntity iconName="addUserIcon" text="There are no bots." />
        </div>
      );
    } else if (cards) {
      const botsCards = userBotsFiltered.map(({ user: bot, role }) => {
        const details = entityCardDetails(bot);
        details.push({
          title: 'User\'s role',
          value: ucfirst(role.name),
        });
        const actions = [
          {
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addBotAdmin(user, bot) : () => this.removeBotAdmin(user, bot),
            requiredRole: 'botEdit',
            resource: bot,
          },
          {
            id: 'remove-bot',
            title: 'Remove bot from user',
            onClick: () => this.removeUserBot(user, bot),
            requiredRole: 'groupEdit',
            resource: bot,
          },
        ];
        return (
          <DetailsCard
            key={bot.uuid}
            icon={icon(bot)}
            isPrivate={!bot.isPublic}
            isLocked={bot.locked}
            name={bot.username}
            description={bot.appRole}
            details={details}
            link={{ title: 'Edit bot', target: `/bots/${bot.uuid}` }}
            actions={actions}
          />
        );
      });
      renderedType = (
        <div className="details-list-container cards">
          { botsCards }
        </div>
      );
    } else {
      const botsTableContent = userBotsFiltered.map(({ user: bot, role }) => {
        const details = entityCardDetails(bot);
        details.push({
          title: 'User\'s role',
          value: ucfirst(role.name),
        });
        const actions = [
          {
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addBotAdmin(user, bot) : () => this.removeBotAdmin(user, bot),
            requiredRole: 'botEdit',
            resource: bot,
          },
          {
            id: 'remove-bot',
            title: 'Remove bot from user',
            onClick: () => this.removeUserBot(user, bot),
            requiredRole: 'groupEdit',
            resource: bot,
          },
        ];
        return {
          user: bot, role, details, actions,
        };
      });
      const botsTable = <Table botsPerGroup={botsTableContent || null} />;
      renderedType = (
        <div className="details-list-container table">
          { botsTable }
        </div>
      );
    }

    return (
      <div className="details-membership-row" id="users-page">
        <div className="user-group-form userBots">
          <div>
            <div className="details-inputs">
              <Input
                id="filter-user-bots-input"
                placeholder="Filter Bots"
                onChange={({ target }) => this.setState({ filter: target.value })}
                icon="search"
              />
              <div className="details-left">
                <HasRole requiredRole="userEdit" resource={user}>
                  <Button
                    id="add-user-to-group"
                    icon="add"
                    iconColor="black"
                    variant="primary"
                    onClick={this.toggleModal}
                  >Add User To Bots
                  </Button>
                </HasRole>
                <ChangeView cards={cards} changeListingStyle={this.changeListingStyle} />
              </div>
            </div>
            <div className="filter-container">
              <div className="filters-wrapper">
                <div>
                  <Label text="Type" />
                  <Dropdown defaultValue="All" options={this.filterContent(botRoleFilters)} onChange={item => this.onChangeFilter(item.value, 'userRole')} />
                </div>
                <div>
                  <Label text="Visibility" />
                  <Dropdown defaultValue="All" options={this.filterContent(botVisibilityFilters)} onChange={item => this.onChangeFilter(item.value, 'visibility')} />
                </div>
                <div>
                  <Label text="Status" />
                  <Dropdown defaultValue="Active" options={this.filterContent(botActivityFilters)} onChange={item => this.onChangeFilter(item.value, 'activity')} />
                </div>

              </div>
            </div>
          </div>
          <div className="details-entity-listing">
            { renderedType }
          </div>
          { addBots ? (
            <AddEntityWrapper
              onClose={this.toggleModal}
              open={addBots}
              title="Add User to Bots"
            >
              <AddUserBots
                addAction={() => { getSelectedUserBots(); this.toggleModal(); }}
                selectedUser={user}
              />
            </AddEntityWrapper>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  removeUserBot: botActions.removeUserBot,
  addBotAdmin: botActions.addBotAdmin,
  removeBotAdmin: botActions.removeBotAdmin,
  getSelectedUserBots: userActions.getSelectedUserBots,
}, dispatch));

const mapStateToProps = state => ({
  loading: state.users.isSearchingUserMemberships,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBots);
