import React from 'react';
import PropTypes from 'prop-types';
import ListingCard from '../ListingCard/ListingCard';
import { icon } from '../../../utils';
import {
  User, Bot, Group,
} from '../../../propTypes';
import './Blocks.css';

const cssClass = (user) => {
  if (!user.active) {
    return 'strike-through';
  } if (user.locked) {
    return 'locked';
  }
  return '';
};

const getInactiveLocked = (user) => {
  let str = '';
  if (user.active && user.locked) {
    str = ' (locked)';
  } else if (!user.active) {
    str = ' (inactive)';
  }
  return str;
};

const getUsersCards = users => users.map(user => (
  <ListingCard
    key={user.uuid}
    link={`/users/${user.uuid}`}
    icon={icon(user)}
    name={`${user.lastName} ${user.firstName}`}
    description={`${user.username}${getInactiveLocked(user)}`}
    cardClass={user.active && user.locked ? 'user-container locked' : 'user-container'}
    textClass={cssClass(user)}
    source={user.source}
  />
));

const getGroupsCards = groups => groups.map(group => (
  <ListingCard
    key={group.uuid}
    link={`/groups/${group.uuid}`}
    icon={{ name: 'group', color: 'primary' }}
    name={group.longname}
    description={group.name}
    cardClass="user-container"
    textClass={null}
    isPrivate={!group.isPublic}
    source={group.source}
  />
));

const getBotsColumns = bots => bots.map(bot => (
  <ListingCard
    key={bot.uuid}
    link={`/bots/${bot.uuid}`}
    icon={icon(bot)}
    name={bot.username}
    description={bot.active && bot.locked ? 'Locked' : bot.appRole}
    cardClass={bot.active && bot.locked ? 'user-container locked' : 'user-container'}
    textClass={cssClass(bot)}
    isPrivate={!bot.isPublic}
    privateIconColor={bot.enabled ? 'red' : 'grey'}
    source={bot.source}
  />
));

const Blocks = ({
  users, groups, bots, index,
}) => (
  <div className="card-row">
    <div className="cards-block">
      <div className="listing-index">
        <div className="index-value" id={index}>{index.toUpperCase()}</div>
      </div>
      <div className="cards">
        { users ? getUsersCards(users) : null}
        { groups ? getGroupsCards(groups) : null}
        { bots ? getBotsColumns(bots) : null}
      </div>
    </div>
  </div>
);

Blocks.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape(User)
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape(Group)
  ),
  bots: PropTypes.arrayOf(
    PropTypes.shape(Bot)
  ),
  index: PropTypes.string,
};

Blocks.defaultProps = {
  users: null,
  groups: null,
  bots: null,
  index: '',
};

export default Blocks;
