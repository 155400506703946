import React from 'react';
import PropTypes from 'prop-types';

const DetailRow = ({
  id, label, content, icon, icon2
}) => (
  <div id={id} className="col">
    <div className="head">{label} {icon} </div>
    <div className="value">{content} {icon2}</div>
  </div>
);

DetailRow.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  icon2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  content: PropTypes.string,
};

DetailRow.defaultProps = {
  id: '',
  icon: '',
  content: '',
  icon2: ''
};

export default DetailRow;
