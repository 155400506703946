import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Search } from 'rtm-ui-components';
import Tag from '../../../../Wrappers/Tag/Tag';
import { paginationLimit } from '../../../../../constants';
import * as userActions from '../../../../../store/actions/users';
import * as groupActions from '../../../../../store/actions/groups';

class AddGroupUsers extends Component {
  state = {
    selectedUsers: [],
    name: '',
    page: 1,
    limit: paginationLimit,
    loading: false,
  }

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ name: '' });
  };

  handleResultSelect = (result) => {
    const { selectedUsers } = this.state;
    const tempUsers = [...selectedUsers];
    tempUsers.push(result);
    this.setState({ selectedUsers: tempUsers });
    this.resetComponent();
  }

  handleSearchChange = async (event) => {
    await this.setState({ name: event.target.value });
    if (event.target.value.length < 1) {
      this.resetComponent();
    } else {
      this.searchUsers();
    }
  }

  handleSubmit = async () => {
    const { selectedUsers } = this.state;
    const {
      addAction, selectedGroup, addUserGroup,
    } = this.props;
    this.setState({ loading: true });
    const promises = selectedUsers.map(user => addUserGroup(user, selectedGroup));
    this.setState({ name: '', selectedUsers: [] });
    await Promise.all(promises);
    this.setState({ loading: false });
    addAction();
  }

  removeSelectedUser = (index) => {
    const { selectedUsers } = this.state;
    const tempUsers = [...selectedUsers];
    tempUsers.splice(index, 1);
    this.setState({ selectedUsers: tempUsers });
  }

  searchUsers = () => {
    const {
      name, page, limit,
    } = this.state;
    const { getUsers } = this.props;
    const params = {
      name: name || '',
      page: page - 1,
      limit,
    };
    getUsers(params);
  }

  renderUser = (userKey, index) => {
    const user = userKey;
    return (
      <Tag key={user.uuid} onDelete={() => this.removeSelectedUser(index)} name={user.key} text={user.text} />
    );
  };

  render() {
    const {
      selectedGroup, searchResults, isSearching,
    } = this.props;
    const { selectedUsers, loading } = this.state;
    const addedUsers = selectedGroup.users.members.map(({ user }) => user);
    const addedSelectedUsers = [...addedUsers, ...selectedUsers].map(u => u.username);
    const results = searchResults.map(u => ({
      id: `${u.username}-result`,
      key: u.username,
      title: '',
      text: `${u.firstName} ${u.lastName} (${u.username}) - ${u.email}`,
      uuid: u.uuid,
      firstName: u.firstName,
      lastName: u.lastName,
      email: u.email,
      username: u.username,
      isBot: u.isBot,
      description: `${u.firstName} ${u.lastName} (${u.username}) - ${u.email}`,
    }));

    const selectedUsersWrapper = selectedUsers && selectedUsers.map(this.renderUser);
    return (
      <div className="add-entity-group add-users">
        <Search
          fluid
          loading={isSearching}
          debounceTimer={300}
          id="add-users-to-group"
          placeholder="Search Users"
          onChange={e => this.handleSearchChange(e)}
          results={results}
          onResultSelect={this.handleResultSelect}
          disabledKeys={addedSelectedUsers}
        />
        {selectedUsersWrapper && (<div className="tags">{selectedUsersWrapper}</div>)}
        <div className="btn-container">
          <Button
            variant="primary"
            loading={loading}
            id="add-entity-btn"
            onClick={this.handleSubmit}
            disabled={!selectedUsers || selectedUsers.length === 0}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.users.users.users,
  isSearching: state.users.isSearching,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  addUserGroup: groupActions.addUserGroup,
  getUsers: userActions.getUsers,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupUsers);
