import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Search } from 'rtm-ui-components';
import Tag from '../../../../Wrappers/Tag/Tag';
import * as groupsActions from '../../../../../store/actions/groups';
import * as botActions from '../../../../../store/actions/bots';
import { paginationLimit } from '../../../../../constants';

class AddGroupBots extends Component {
  state = {
    selectedBots: [],
    name: '',
    loading: false,
  }

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ name: '' });
  };

  handleResultSelect = (result) => {
    const { selectedBots } = this.state;
    const tempBots = [...selectedBots];
    tempBots.push(result);
    this.setState({ selectedBots: tempBots });
    this.resetComponent();
  }

  handleSearchChange = async (event) => {
    await this.setState({ name: event.target.value });
    if (event.target.value.length < 1) {
      this.resetComponent();
    } else {
      this.searchBots();
    }
  }

  handleSubmit = async () => {
    const { selectedBots } = this.state;
    const {
      addAction, selectedGroup, addUserGroup,
    } = this.props;
    this.setState({ loading: true });
    const promises = selectedBots.map(user => addUserGroup(user, selectedGroup));
    this.setState({ name: '', selectedBots: [] });
    await Promise.all(promises);
    this.setState({ loading: false });
    addAction();
  }

  removeSelectedUser = (index) => {
    const { selectedBots } = this.state;
    const tempBots = [...selectedBots];
    tempBots.splice(index, 1);
    this.setState({ selectedBots: tempBots });
  }

  searchBots = () => {
    const {
      name,
    } = this.state;
    const { getBots } = this.props;
    const params = {
      name: name || '',
      page: 0,
      limit: paginationLimit,
    };
    getBots(params);
  }

  renderUser = (user, index) => (
    <Tag key={user.uuid} onDelete={() => this.removeSelectedUser(index)} name={user.key} text={`${user.username}`} />
  );

  render() {
    const {
      searchResults, isSearching, selectedGroup,
    } = this.props;
    const { selectedBots, loading } = this.state;
    const addedBots = selectedGroup.bots.members.map(({ user }) => user);
    const addedSelectedBots = [...addedBots, ...selectedBots].map(b => b.username);
    //let results = _.differenceWith(searchResults, [...addedBots, ...selectedBots], (u1, u2) => u1.uuid === u2.uuid);
    const results = searchResults.map(u => (
      {
        id: `${u.username}-result`,
        key: u.username,
        text: u.username,
        uuid: u.uuid,
        username: u.username,
        isBot: u.isBot,
        description: `${u.username}`,
      }
    ));

    const selectedBotsWrapper = selectedBots && selectedBots.map(this.renderUser);
    return (
      <div className="add-entity-group add-users">
        <Search
          fluid
          loading={isSearching}
          debounceTimer={300}
          id="add-bots-to-group"
          placeholder="Search Bots"
          onChange={e => this.handleSearchChange(e)}
          results={results}
          onResultSelect={this.handleResultSelect}
          disabledKeys={addedSelectedBots}
        />
        {selectedBotsWrapper && (<div className="tags">{selectedBotsWrapper}</div>)}
        <div className="btn-container">
          <Button
            variant="primary"
            loading={loading}
            id="add-entity-btn"
            onClick={this.handleSubmit}
            disabled={!selectedBots || selectedBots.length === 0}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.bots.bots.bots,
  isSearching: state.bots.isSearching,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  addUserGroup: groupsActions.addUserGroup,
  getBots: botActions.getBots,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupBots);
