/* eslint-disable no-lonely-if */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow/TableRow';
import EditTableRow from './EditTableRow/EditTableRow';
import TableHeader from './TableHeader/TableHeader';
import { ucfirst, icon } from '../../../utils';
import {
  User, Bot, Group, Role,
} from '../../../propTypes';
import './Table.css';

const getStatus = (user) => {
  if (user.locked) { return 'Locked'; }
  if (!user.active) { return 'Inactive'; }
  return 'Active';
};

const rowClass = (user) => {
  if (user.locked) { return 'row locked'; }
  return 'row';
};

const getVisibility = (bot) => {
  if (bot.isPublic) { return 'Public'; }
  return 'Private';
};

const userRows = (users, windowWidth) => (
  users.map((user) => {
    const cells = [{ value: `${user.lastName} ${user.firstName}`, key: 'name' },
      { value: user.username, key: 'username' },
      { value: user.email, key: 'email' },
    ];
    if (windowWidth > 900) {
      cells.push({ value: getStatus(user), key: 'status' });
      cells.push({ value: ucfirst(user.appRole), key: 'role' });
    }
    return (
      <TableRow
        key={user.uuid}
        uuid={user.uuid}
        icon={icon(user)}
        cells={cells}
        link={`/users/${user.uuid}`}
        rowClass={rowClass(user)}
      />
    );
  })
);

const botRows = (bots, windowWidth) => (
  bots.map((bot) => {
    const cells = [
      { value: bot.username, key: 'name' },
      { value: ucfirst(bot.appRole), key: 'role' },
      { value: getStatus(bot), key: 'status' },
      { value: getVisibility(bot), key: 'visibility' },
    ];
    if (windowWidth > 900) {
      cells.push({ value: '', key: 'blank' });
    }
    return (
      <TableRow
        key={bot.uuid}
        uuid={bot.uuid}
        icon={icon(bot)}
        cells={cells}
        link={`/bots/${bot.uuid}`}
        rowClass={rowClass(bot)}
        isPublic={bot.isPublic}
        privateIcon={bot.enabled ? 'private' : 'private-inactive'}
      />
    );
  })
);

const groupRows = (groups, windowWidth, nested = false) => (
  groups.map((group) => {
    const cells = [
      { value: group.longname, key: 'name' },
      { value: group.name, key: 'shortName' },
      { value: getVisibility(group), key: 'visibility' },
    ];
    if (!nested) {
      cells.push({ value: group.childGroups, key: 'childGroups' },);
    }
    if (windowWidth > 900 && nested) {
      cells.push({ value: '', key: 'blank' });
    }
    return (
      <TableRow
        key={group.uuid}
        uuid={group.uuid}
        icon={icon('group')}
        cells={cells}
        link={`/groups/${group.uuid}`}
        rowClass={rowClass(group)}
        isPublic={group.isPublic}
        privateIcon="private"
      />
    );
  })
);

const botRowsInGroup = (bots, windowWidth) => (
  bots.map(({
    user, actions, details, role,
  }) => {
    const cells = [
      { value: user.username, key: 'name' },
      { value: ucfirst(user.appRole), key: 'role' },
      { value: getStatus(user), key: 'status' },
      { value: getVisibility(user), key: 'visibility' },
    ];
    if (windowWidth > 900) {
      cells.push({ value: '', key: 'blank' });
    }
    return (
      <EditTableRow
        key={user.uuid}
        uuid={user.uuid}
        icon={icon(user)}
        cells={cells}
        link={{ title: 'Edit bot', target: `/bots/${user.uuid}` }}
        rowClass={rowClass(user)}
        isPublic={user.isPublic}
        privateIcon="private"
        privateIconColor={user.enabled && !user.locked ? 'red' : 'grey'}
        actions={actions}
        details={details}
        isAdmin={role.name === 'admin'}
      />
    );
  })
);

const editGroupRows = (groups, windowWidth, nested = false) => (
  groups.map(({
    group, actions, details, childGroups, role,
  }) => {
    const cells = [
      { value: group.longname, key: 'name' },
      { value: group.name, key: 'shortName' },
      { value: getVisibility(group), key: 'visibility' },
    ];
    if (!nested) {
      cells.push({ value: childGroups, key: 'childGroups' },);
    }
    if (windowWidth > 900 && nested) {
      cells.push({ value: '', key: 'blank' });
    }
    return (
      <EditTableRow
        key={group.uuid}
        uuid={group.uuid}
        icon={icon('group')}
        cells={cells}
        isPublic={group.isPublic}
        privateIcon="private"
        privateIconColor="red"
        rowClass={rowClass(group)}
        actions={actions}
        details={details}
        link={{ title: 'Edit group', target: `/groups/${group.uuid}` }}
        isAdmin={nested ? false : role.name === 'admin'}
      />
    );
  })
);

const userRowsInGroup = (users, windowWidth) => (
  users.map(({
    user, actions, details, role,
  }) => {
    const cells = [{ value: `${user.lastName} ${user.firstName}`, key: 'name' },
      { value: user.username, key: 'username' },
      { value: user.email, key: 'email' },
    ];
    if (windowWidth > 900) {
      cells.push({ value: getStatus(user), key: 'status' });
      cells.push({ value: ucfirst(user.appRole), key: 'role' });
    }
    return (
      <EditTableRow
        key={user.uuid}
        uuid={user.uuid}
        icon={icon(user)}
        cells={cells}
        isAdmin={role.name === 'admin'}
        link={{ id: 'edit-user', title: 'Edit user', target: `/users/${user.uuid}` }}
        rowClass={rowClass(user)}
        actions={actions}
        details={details}
      />
    );
  })
);

class Table extends Component {
  state={ windowWidth: window.innerWidth }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { windowWidth } = this.state;
    if (Math.abs(windowWidth - window.innerWidth) > 10) {
      this.setState({ windowWidth: window.innerWidth });
    }
  }

  render() {
    const {
      users, bots, groups, usersPerGroup, botsPerGroup, usersPerBot, nestedGroups, allGroups,
    } = this.props;
    const { windowWidth } = this.state;
    return (
      <div className="grid-container">
        {windowWidth >= 900 && (users || usersPerGroup || usersPerBot) ? (
          <TableHeader content={['Name', 'Username', 'Email', 'Status', 'Role']} />
        ) : null}
        {windowWidth < 900 && (users || usersPerGroup || usersPerBot) ? (
          <TableHeader content={['Name', 'Username', 'Email']} />
        ) : null}
        {bots || botsPerGroup ? (
          <TableHeader content={['Username', 'Role', 'Status', 'Visibility']} />
        ) : null}
        {groups ? (
          <TableHeader content={['Group Name', 'Group ID', 'Visibility', 'Member Via']} />
        ) : null}
        {nestedGroups || allGroups ? (
          <TableHeader content={['Group Name', 'Group ID', 'Visibility']} />
        ) : null}

        <div className="grid-body">
          {users ? userRows(users, windowWidth) : null}
          {bots ? botRows(bots, windowWidth) : null}
          {allGroups ? groupRows(allGroups, windowWidth, true) : null}

          {groups ? editGroupRows(groups, windowWidth) : null}
          {nestedGroups ? editGroupRows(nestedGroups, windowWidth, true) : null}
          {usersPerGroup ? userRowsInGroup(usersPerGroup, windowWidth) : null}
          {botsPerGroup ? botRowsInGroup(botsPerGroup, windowWidth) : null}
          {usersPerBot ? userRowsInGroup(usersPerBot, windowWidth) : null}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape(User)
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape(Group)
  ),
  nestedGroups: PropTypes.arrayOf(
    PropTypes.shape(Group)
  ),
  bots: PropTypes.arrayOf(
    PropTypes.shape(Bot)
  ),
  usersPerGroup: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape(User),
      role: PropTypes.shape(Role),
    }),
  ),
  botsPerGroup: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape(Bot),
      role: PropTypes.shape(Role),
    }),
  ),
  usersPerBot: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape(User),
      role: PropTypes.shape(Role),
    }),
  ),
};

Table.defaultProps = {
  users: null,
  groups: null,
  nestedGroups: null,
  bots: null,
  usersPerGroup: null,
  botsPerGroup: null,
  usersPerBot: null,
};

export default Table;
