import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TopNavigation, CurrentUser, Content } from 'rtm-ui-components';
import * as auditLogsActions from '../../store/actions/auditLogs';
import * as authActions from '../../store/actions/auth';
import PageContent from '../SharedComponents/PageContent';
import './AuditsPage.css';

class AuditsPage extends Component {
  render() {
    const {
      user, isLarge, version, userActions, hasBanner,
    } = this.props;
    return (
      <div id="logs">
        <div className="content-wrapper audits-content">
          <TopNavigation
            id="top-nav"
            isLarge={isLarge}
            currentUser={<CurrentUser actions={userActions} id="current-user" role={user.appRole} version={version ? version.value : ''} username={`${user.firstName} ${user.lastName}`} />}
          >
            <h3>Sysadmin</h3>
          </TopNavigation>
          <Content hasBanner={hasBanner} isLarge={isLarge}>
            <PageContent currentPage="sysadmin" />
          </Content>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUserRole: state.auth.user.appRole,
  logs: state.auditLogs.events,
  operations: state.configs.configs['audit.log.type.descriptions'] ? state.configs.configs['audit.log.type.descriptions'].value : {},
  user: state.auth.user,
  version: state.configs.configs['application.version'],
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getAuditLogs: auditLogsActions.getAuditLogs,
  getAuditLogOperations: auditLogsActions.getAuditLogOperations,
  getConfigs: authActions.getConfigs,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AuditsPage);
