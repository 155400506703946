import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'rtm-ui-components';
import './DetailsViewWrapper.css';

class DetailsViewWrapper extends Component {
  render() {
    const {
      topRow,
      infoRow,
      editEntity,
      listing,
      editPassword,
      closeModal,
      openModal,
    } = this.props;
    return (
      <Modal id="entity-details-view" size="custom" onClose={closeModal} open={openModal}>
        <div className="details-view-container">
          { topRow }
          <span className="details-view-content">
            { infoRow }
            {editEntity || null}
            {editPassword ? <div className="details-edit-container">{ editPassword }</div> : null}
            <div className="tabs-wrapper">{ listing }</div>
          </span>
        </div>
      </Modal>
    );
  }
}

DetailsViewWrapper.propTypes = {
  topRow: PropTypes.element.isRequired,
  infoRow: PropTypes.element.isRequired,
  editEntity: PropTypes.element,
  listing: PropTypes.element.isRequired,
  editPassword: PropTypes.element,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};

DetailsViewWrapper.defaultProps = {
  editPassword: null,
  editEntity: null,
};

export default DetailsViewWrapper;
