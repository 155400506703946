import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Search } from 'rtm-ui-components';
import Tag from '../../../../Wrappers/Tag/Tag';
import { paginationLimit } from '../../../../../constants';
import * as groupsActions from '../../../../../store/actions/groups';

class AddBotGroups extends Component {
  state = {
    name: '',
    selectedGroups: [],
    page: 1,
    limit: paginationLimit,
  }

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ name: '' });
  };

  handleResultSelect = (result) => {
    const { selectedGroups } = this.state;
    const tempUsers = [...selectedGroups];
    tempUsers.push(result);
    this.setState({ selectedGroups: tempUsers });
    this.resetComponent();
  }

  handleSearchChange = async (value) => {
    const { selectedGroups } = this.state;
    const { selectedBot } = this.props;
    await this.setState({ name: value });
    if (value.length < 1) {
      this.resetComponent();
    } else {
      // users already selected & users already in group
      const groupsInUser = selectedBot.groups || [];
      const prevGroups = [...selectedGroups, ...groupsInUser];
      this.searchGroups(value, prevGroups);
    }
  }

  searchGroups = () => {
    const {
      name, page, limit,
    } = this.state;
    const { getGroups } = this.props;
    const params = {
      name: name || '',
      page: page - 1,
      limit,
    };
    getGroups(params);
  }

  handleSubmit = async () => {
    const { selectedGroups } = this.state;
    const { addAction, selectedBot, addUserGroup } = this.props;
    this.setState({ loading: true });
    const promises = selectedGroups.map(group => addUserGroup(selectedBot, group));
    this.setState({ name: '', selectedGroups: [] });
    await Promise.all(promises);
    this.setState({ loading: false });
    addAction();
  }

  removeSelectedGroup = (index) => {
    const { selectedGroups } = this.state;
    const tempGroups = [...selectedGroups];
    tempGroups.splice(index, 1);
    this.setState({ selectedGroups: tempGroups });
  }

  renderGroup = (group, index) => (
    <Tag key={group.uuid} onDelete={() => this.removeSelectedGroup(index)} name={group.name} text={`${group.longname} (${group.name})`} />
  );

  render() {
    const {
      searchResults, isSearching, selectedBot,
    } = this.props;
    const { selectedGroups, loading } = this.state;
    const addedGroups = selectedBot.groups.map(({ group }) => group);
    const addedSelectedGroups = [...addedGroups, ...selectedGroups].map(g => g.uuid);
    //let results = _.differenceWith(searchResults, [...addedGroups, ...selectedGroups], (g1, g2) => g1.uuid === g2.uuid);
    const results = searchResults.map(g => ({
      key: g.uuid,
      text: `${g.longname} (${g.name})`,
      id: `${g.name}-result`,
      title: '',
      uuid: g.uuid,
      name: g.name,
      longname: g.longname,
      description: `${g.longname} (${g.name})`,
    }));

    const selectedGroupsWrapper = selectedGroups && selectedGroups.map(this.renderGroup);
    return (
      <div className="add-entity-user add-groups">
        <Search
          fluid
          loading={isSearching}
          id="add-groups-to-bot"
          placeholder="Search Groups"
          onChange={e => this.handleSearchChange(e.target.value)}
          results={results}
          onResultSelect={this.handleResultSelect}
          debounceTimer={300}
          disabledKeys={addedSelectedGroups}      
        />
        {selectedGroupsWrapper && (<div className="tags">{selectedGroupsWrapper}</div>)}
        <div className="btn-container">
          <Button
            loading={loading}
            type="submit"
            id="add-entity-btn"
            onClick={this.handleSubmit}
            variant="primary"
            disabled={!selectedGroups || selectedGroups.length === 0}
          >Add
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.groups.groups.groups,
  isSearching: state.groups.isSearching,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  addUserGroup: groupsActions.addUserGroup,
  getGroups: groupsActions.getGroups,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AddBotGroups);
