import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoggedInRoute from './RoutesWrappers/LoggedInRoute';
import FallbackRoute from './Authentication/FallbackRoute';

import Login from './Authentication/Login';
// import SignUp from './Authentication/SignUp';
import LogoutPage from './Authentication/LogoutPage';
import ErrorPage from './Authentication/ErrorPage/ErrorPage';

import AuditsPage from './Audits/AuditsPage';
import RecoverPassword from './Authentication/AuthPage/RecoverPassword/RecoverPassword';
import ResetPassword from './Authentication/AuthPage/ResetPassword/ResetPassword';

import UsersRoutes from './RoutesWrappers/UsersRoutes';
import GroupsRoutes from './RoutesWrappers/GroupsRoutes';
import BotsRoutes from './RoutesWrappers/BotsRoutes';

const Routes = () => (
  <Switch>
    <Route exact path="/users/password/change" component={ResetPassword} />
    <LoggedInRoute path="/groups" component={GroupsRoutes} />
    <LoggedInRoute path="/bots" component={BotsRoutes} />
    <LoggedInRoute exact path="/admin" component={AuditsPage} />
    <LoggedInRoute path="/users" component={UsersRoutes} />

    <Route path="/login" component={Login} />
    {/* <Route path="/signup" component={SignUp} /> */}
    <Route path="/logout" component={LogoutPage} />
    <Route path="/recoverpassword" component={RecoverPassword} />
    <Route path="/resetpassword" component={ResetPassword} />
    <Route path="/error" component={ErrorPage} />

    <FallbackRoute path="*" defaultRoute="/users" />
  </Switch>
);

export default Routes;
