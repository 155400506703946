import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Spinner } from 'rtm-ui-components';
import AddGroupForm from '../../Groups/AddGroupForm/AddGroupForm';
import AddUserForm from '../../Users/AddUserForm/AddUserForm';
import AddBotForm from '../../Bots/AddBotForm/AddBotForm';

import AddGroupUsers from '../../Groups/EditGroup/GroupUsers/AddGroupUsers/AddGroupUsers';
import AddBotUsers from '../../Bots/EditBot/BotUsers/AddBotUsers/AddBotUsers';
import AddUserGroups from '../../Users/EditUser/UserGroups/AddUserGroups/AddUserGroups';

import AddEntityWrapper from '../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import AddedEntityDetailsWrapper from '../../Wrappers/AddedEntityDetailsWrapper/AddedEntityDetailsWrapper';

import * as groupActions from '../../../store/actions/groups';
import * as botActions from '../../../store/actions/bots';
import * as userActions from '../../../store/actions/users';

import { icon } from '../../../utils';
import { refreshLoggedInUser } from '../../../store/actions/auth';


class AddEntity extends Component {
  state = {
    page: 0,
    lastPage: 0,
  };

  renderedPage = () => {
    const {
      onClose,
      searchGroups, addedGroup, addGroupOpen, clearSelectedGroup,
      searchBots, addBot, addedBot, addBotOpen, clearSelectedBot,
      searchUsers, addUser, addedUser, addUserOpen, clearSelectedUser,
    } = this.props;
    const { page, lastPage } = this.state;
    if (addGroupOpen) {
      let message = 'Group Created';
      if (page === 1 && lastPage === 2) {
        message = 'Users Added to Group';
      }
      if (page === 0) {
        return (
          <AddGroupForm
            onSubmit={async () => {
              await searchGroups();
              await this.setState({ lastPage: page, page: 1 });
            }}
          />
        );
      }
      if (page === 1) {
        return (
          <AddedEntityDetailsWrapper
            message={message}
            icon={{ name: 'group', color: 'primary' }}
            name={addedGroup.longname}
            description={addedGroup.name}
            visibility={addedGroup.isPublic ? 'Public' : 'Private'}
            extras={[
              { title: 'Description', value: addedGroup.description, key: `description-${addedGroup.uuid}` },
            ]}
            detailsLink={`/groups/${addedGroup.uuid}`}
            addToAction={() => { this.setState({ lastPage: page, page: 2 }); }}
            addToIcon="user"
            addToText="Add Users to Group"
            backAction={() => { this.setState({ lastPage: page, page: 0 }); }}
            backText="Create Another Group"
            close={onClose}
            isPrivate={!addedGroup.isPublic}
          />
        );
      }
      if (page === 2) {
        return (
          <AddGroupUsers
            addAction={() => { this.setState({ lastPage: page, page: 1 }); clearSelectedGroup(); }}
            selectedGroup={addedGroup}
          />
        );
      }
    }
    if (addBotOpen) {
      let message = 'Bot Created';
      if (page === 1 && lastPage === 2) {
        message = 'Users Added to Bot';
      }
      if (page === 0) {
        return (
          <AddBotForm
            onSubmit={async (data) => {
              await addBot(data);
              await searchBots();
              await this.setState({ lastPage: page, page: 1 });
            }}
          />
        );
      }
      if (page === 1) {
        return (
          <AddedEntityDetailsWrapper
            message={message}
            icon={icon(addedBot)}
            name={addedBot.username}
            description={addedBot.locked ? 'Locked' : addedBot.appRole}
            visibility={addedBot.isPublic ? 'Public' : 'Private'}
            extras={[
              { title: 'Email', value: addedBot.email, key: `email-${addedBot.uuid}` },
              { title: 'Password', value: addedBot.password, key: `password-${addedBot.uuid}` },
            ]}
            detailsLink={`/bots/${addedBot.uuid}`}
            addToAction={() => { this.setState({ lastPage: page, page: 2 }); }}
            addToIcon="user"
            addToText="Add Users to Bot"
            backAction={() => { this.setState({ lastPage: page, page: 0 }); }}
            backText="Add Another Bot"
            close={onClose}
            isPrivate={!addedBot.isPublic}
          />
        );
      }
      if (page === 2) {
        return (
          <AddBotUsers
            addAction={() => { this.setState({ lastPage: page, page: 1 }); clearSelectedBot(); }}
            selectedBot={addedBot}
          />
        );
      }
    }
    if (addUserOpen) {
      let message = 'User Created';
      if (page === 1 && lastPage === 2) {
        message = 'User Added to Groups';
      }
      if (page === 0) {
        return (
          <AddUserForm
            onSubmit={async (data) => {
              await addUser(data);
              await searchUsers();
              await this.setState({ lastPage: page, page: 1 });
            }}
          />
        );
      }
      if (page === 1) {
        return (
          <AddedEntityDetailsWrapper
            message={message}
            icon={icon(addedUser)}
            name={`${addedUser.lastName} ${addedUser.firstName}`}
            description={addedUser.username}
            visibility={null}
            extras={addedUser.appRole === 'ext'
              ? [
                { title: 'Email', value: addedUser.email, key: `email-${addedUser.uuid}` },
                {
                  title: 'Activity Period',
                  value: `${moment(addedUser.activityStartTime).format('DD.MM.YYYY')} - ${moment(addedUser.activityEndTime).format('DD.MM.YYYY')}`,
                  key: `activity-${addedUser.uuid}`,
                },
              ]
              : [{ title: 'Email', value: addedUser.email, key: `email-${addedUser.uuid}` }]
            }
            detailsLink={`/users/${addedUser.uuid}`}
            addToAction={() => { this.setState({ lastPage: page, page: 2 }); }}
            addToIcon="group"
            addToText="Add User to Groups"
            backAction={() => { this.setState({ lastPage: page, page: 0 }); }}
            backText="Add Another User"
            close={onClose}
          />
        );
      }
      if (page === 2) {
        return (
          <AddUserGroups
            addAction={() => { this.setState({ lastPage: page, page: 1 }); clearSelectedUser(); }}
            selectedUser={addedUser}
          />
        );
      }
    }
    return null;
  }

  render() {
    const {
      onClose,
      addGroupOpen,
      addBotOpen,
      addUserOpen,
    } = this.props;
    const { page } = this.state;
    let content = <Spinner />;
    if (addGroupOpen) {
      content = (
        <AddEntityWrapper
          id="add-new-entity"
          onClose={onClose}
          open={addGroupOpen}
          title={page !== 2 ? 'Create a New Group' : 'Add Users to Group'}
        >
          {this.renderedPage()}
        </AddEntityWrapper>
      );
    }
    if (addBotOpen) {
      content = (
        <AddEntityWrapper
          id="add-new-entity"
          onClose={onClose}
          open={addBotOpen}
          title={page !== 2 ? 'Create a New Bot' : 'Add Users to Bot'}
        >
          {this.renderedPage()}
        </AddEntityWrapper>
      );
    }
    if (addUserOpen) {
      content = (
        <AddEntityWrapper
          id="add-new-entity"
          onClose={onClose}
          open={addUserOpen}
          title={page !== 2 ? 'Create a New User' : 'Add User to Groups'}
        >
          {this.renderedPage()}
        </AddEntityWrapper>
      );
    }
    return (content);
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  addGroup: groupActions.addGroup,
  clearSelectedGroup: groupActions.clearSelectedGroup,
  getGroups: groupActions.getGroups,
  refreshLoggedInUser,
  addBot: botActions.addBot,
  clearSelectedBot: botActions.clearSelectedBot,
  addUser: userActions.addUser,
  clearSelectedUser: userActions.clearSelectedUser,
}, dispatch));

const mapStateToProps = state => ({
  addedGroup: state.groups.addedGroup,
  addedBot: state.bots.addedBot,
  addedUser: state.users.addedUser,
});

AddEntity.propTypes = {
  onClose: PropTypes.func.isRequired,
  searchGroups: PropTypes.func,
  addedGroup: PropTypes.object,
  addGroupOpen: PropTypes.bool,

  searchBots: PropTypes.func,
  addBot: PropTypes.func,
  addedBot: PropTypes.object,
  addBotOpen: PropTypes.bool,

  searchUsers: PropTypes.func,
  addUser: PropTypes.func,
  addedUser: PropTypes.object,
  addUserOpen: PropTypes.bool,
};

AddEntity.defaultProps = {
  searchGroups: null,
  addedGroup: null,
  addGroupOpen: false,

  searchBots: null,
  addBot: null,
  addedBot: null,
  addBotOpen: false,

  searchUsers: null,
  addUser: null,
  addedUser: null,
  addUserOpen: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEntity);
