import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '../TableCell/TableCell';

const TableHeader = ({
  id, content, customContent, children, className,
}) => {
  const textContent = content && content.map(text => (
    <TableCell key={text} text={text} />
  ));
  return (
    <div id={id} className={`${className} table-header`}>
      {customContent ? children : textContent}
    </div>
  );
};

TableHeader.propTypes = {
  id: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.string),
  customContent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  id: null,
  customContent: false,
  content: null,
  children: null,
  className: '',
};

export default TableHeader;
