import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'rtm-ui-components';
import history from '../../../history';
import TableCell from '../TableCell/TableCell';
import './TableRow.css';

const getCells = (cells, uuid, icon, isPublic, privateIconColor) => (
  cells.map((cell) => {
    if (cell.key === 'name') {
      return (
        <TableCell customContent key={`${uuid}${cell.key}${cell.value}`}>
          <span>
            <Icon {...icon} size="medium" />
            {isPublic ? null : <span className="private-icon-table"><Icon width="12px" height="16px" name="private" color={privateIconColor} /></span> }
            <span className="cell-value">{cell.value}</span>
          </span>
        </TableCell>
      );
    }
    return (
      <TableCell key={`${uuid}${cell.key}${cell.value}`} text={cell.value} />
    );
  })
);

const TableRow = ({
  link, icon, rowClass, cells, uuid, isPublic, privateIconColor,
}) => (
  <div key={uuid} className={`${rowClass} table-row`} onClick={() => (link ? history.push(link) : null)}>
    {getCells(cells, uuid, icon, isPublic, privateIconColor)}
  </div>
);

TableRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      key: PropTypes.string,
    })
  ).isRequired,
  uuid: PropTypes.string,
  icon: PropTypes.shape({
    style: PropTypes.object,
    name: PropTypes.string,
  }),
  isPublic: PropTypes.bool,
  link: PropTypes.string,
  rowClass: PropTypes.string,
  privateIconColor: PropTypes.oneOf(['red', 'grey']),
};

TableRow.defaultProps = {
  uuid: null,
  icon: null,
  isPublic: true,
  link: null,
  rowClass: '',
  privateIconColor: 'red',
};

export default TableRow;
