import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  Button, Input, Spinner, Dropdown, Label, Pagination,
} from 'rtm-ui-components';
import * as groupActions from '../../../../store/actions/groups';
import AddGroupUsers from './AddGroupUsers/AddGroupUsers';
import DetailsCard from '../../../SharedComponents/DetailsCard/DetailsCard';
import AddEntityWrapper from '../../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import {
  userRoleFilters, userActivityFilters, userGroupRoleFilters, paginationLimit,
} from '../../../../constants';
import HasRole from '../../../../roles/HasRole';
import Table from '../../../SharedComponents/Table/Table';
import AddFirstEntity from '../../../SharedComponents/AddFirstEntity/AddFirstEntity';
import ChangeView from '../../../SharedComponents/ChangeView/ChangeView';
import { icon, entityCardDetails, ucfirst } from '../../../../utils';
import '../../../../assets/stylesheets/DetailsViewContent.css';


class GroupUsers extends Component {
  state = {
    name: '',
    lastSearchName: '',
    activityFilter: 'active',
    roleFilter: null,
    groupRoleFilter: null,
    expandAddUser: false,
    limit: paginationLimit,
    page: 0,
    cards: true,
  }

  componentDidMount = async () => {
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    this.searchGroupUsers();
    if (settings && settings.detailsListingCards !== undefined && !settings.detailsListingCards) {
      this.setState({ cards: false });
    }
    this.setName = _.debounce(this.setName, 300);
  }

  onChangeFilter = async (item, type) => {
    if (item.value === 'all') {
      switch (type) {
        case 'userRole':
          await this.setState({ roleFilter: null, page: 0 });
          break;
        case 'groupRole':
          await this.setState({ groupRoleFilter: null, page: 0 });
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'userRole':
          await this.setState({ roleFilter: item.value, page: 0 });
          break;
        case 'groupRole':
          await this.setState({ groupRoleFilter: item.value, page: 0 });
          break;
        default:
          break;
      }
    }
    if (type === 'activity') {
      await this.setState({ activityFilter: item.value, page: 0 });
    }
    this.searchGroupUsers();
  }

  setName = async (value) => {
    if (!value || value.length < 3) {
      await this.setState({ name: null, page: 0 });
    } else {
      await this.setState({ name: value, page: 0 });
    }
    const { lastSearchName, name } = this.state;
    if (lastSearchName !== name) {
      this.searchGroupUsers();
    }
    await this.setState({ lastSearchName: name });
  }

  changePage = async (activePage) => {
    await this.setState({ page: activePage });
    this.searchGroupUsers();
  }

  toggleExpandAddUser = async () => {
    const { expandAddUser } = this.state;
    if (expandAddUser) {
      await this.searchGroupUsers();
    }
    this.setState({
      expandAddUser: !expandAddUser,
    });
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.detailsListingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  searchGroupUsers = () => {
    const {
      name, activityFilter, roleFilter, groupRoleFilter, page, limit,
    } = this.state;
    const { group, loadGroupUsers } = this.props;
    const params = {
      name: name || '',
      inactive: activityFilter === 'inactive',
      onlyLocked: activityFilter === 'locked',
      applicationRole: roleFilter || '',
      groupRole: groupRoleFilter || '',
      page,
      limit,
    };
    loadGroupUsers(group.uuid, params);
  }

  addAdmin = async (user, group) => {
    const { addAdmin } = this.props;
    await addAdmin(user, group);
    this.searchGroupUsers();
  }

  removeUser = async (user, group) => {
    const { removeUser } = this.props;
    await removeUser(user, group);
    this.searchGroupUsers();
  }

  removeAdmin = async (user, group) => {
    const { removeAdmin } = this.props;
    await removeAdmin(user, group);
    this.searchGroupUsers();
  }


  filterContent = (arrayToFilter) => {
    const filterOptions = arrayToFilter.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    return filterOptions;
  }

  render() {
    const {
      expandAddUser, cards,
    } = this.state;
    const { group, loading } = this.props;
    const groupUsersFiltered = group && group.users && group.users.members;
    let renderedType;
    if ((!groupUsersFiltered || !groupUsersFiltered.length) && loading) {
      renderedType = (
        <div className="details-list-container table">
          <Spinner />
        </div>
      );
    } else if (!groupUsersFiltered || !groupUsersFiltered.length) {
      renderedType = (
        <div className="details-list-container table">
          <AddFirstEntity iconName="addUserIcon" text="There are no users." />
        </div>
      );
    } else if (cards) {
      const usersCards = groupUsersFiltered.map(({ user, role }) => {
        const details = entityCardDetails(user);
        details.push({
          title: 'Role in group',
          value: ucfirst(role.name),
        });
        const actions = [
          {
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addAdmin(user, group) : () => this.removeAdmin(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
          {
            id: 'remove-user',
            title: 'Remove user from group',
            onClick: () => this.removeUser(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        return (
          <DetailsCard
            key={user.uuid}
            icon={icon(user)}
            name={`${user.firstName} ${user.lastName}`}
            description={user.username}
            details={details}
            link={{ title: 'Edit User', target: `/users/${user.uuid}` }}
            actions={actions}
            isAdmin={role.name === 'admin'}
          />
        );
      });
      renderedType = (
        <div className="details-list-container cards">
          { usersCards }
        </div>
      );
    } else {
      const tableUsers = groupUsersFiltered.map(({ user, role }) => {
        const details = entityCardDetails(user);
        details.push({
          title: 'Role in group',
          value: ucfirst(role.name),
        });
        let actions = [
          {
            id: 'remove-user',
            title: 'Remove user from group',
            onClick: () => this.removeUser(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        if (user.appRole !== 'ext') {
          actions = [{
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addAdmin(user, group) : () => this.removeAdmin(user, group),
            requiredRole: 'groupEdit',
            resource: group,
          }, ...actions];
        }
        return {
          user, role, details, actions,
        };
      });
      const usersTable = <Table usersPerGroup={tableUsers || null} />;
      renderedType = (
        <div className="details-list-container table">
          { usersTable }
        </div>
      );
    }
    const roleFilterOptions = this.filterContent(userRoleFilters);
    const activityFilterOptions = this.filterContent(userActivityFilters);
    const groupRoleFilterOptions = this.filterContent(userGroupRoleFilters);
    return (
      <div className="details-membership-row" id="groups-page">
        <div className="user-group-form groupUsers">
          <div>
            <div className="details-inputs">
              <Input
                onChange={(event) => {
                  const { value } = event.target;
                  this.setName(value);
                }}
                className="user-search"
                id="user-search-field"
                placeholder="Filter Users"
                icon="search"
              />
              <div className="details-left">
                <HasRole requiredRole="groupEdit" resource={group}>
                  <Button
                    variant="primary"
                    id="add-user-to-group"
                    iconColor="black"
                    icon="add"
                    onClick={() => this.toggleExpandAddUser()}
                  >
                    Add Users to the Group
                  </Button>
                </HasRole>
                <ChangeView cards={cards} changeListingStyle={this.changeListingStyle} />
              </div>
            </div>
            <div className="filter-container">
              <div className="filters-wrapper">
                <div>
                  <Label text="User Type" htmlFor="group-user-type" />
                  <Dropdown id="group-user-type" defaultValue={roleFilterOptions[0].text} options={roleFilterOptions} onChange={item => this.onChangeFilter(item, 'userRole')} />
                </div>
                <div>
                  <Label text="User Status" htmlFor="group-user-status" />
                  <Dropdown id="group-user-status" title="User Status" defaultValue={activityFilterOptions[0].text} options={activityFilterOptions} onChange={item => this.onChangeFilter(item, 'activity')} />
                </div>
                <div>
                  <Label text="Group Role" htmlFor="group-role" />
                  <Dropdown id="group-role" title="Group Role" defaultValue={groupRoleFilterOptions[0].text} options={groupRoleFilterOptions} onChange={item => this.onChangeFilter(item, 'groupRole')} />
                </div>
              </div>
            </div>
          </div>
          <div className="details-entity-listing">
            { renderedType }
          </div>
          { groupUsersFiltered !== 0 ? (
            <Pagination
              id="group-users-pagination"
              totalPages={group.users ? group.users.totalPages : 1}
              activePage={group.users ? group.users.page : 0}
              onPageChange={this.changePage}
            />
          ) : null }
          {expandAddUser ? (
            <AddEntityWrapper
              onClose={this.toggleExpandAddUser}
              open={expandAddUser}
              title="Add Users to Group"
            >
              <AddGroupUsers
                selectedGroup={group}
                addAction={this.toggleExpandAddUser}
              />
            </AddEntityWrapper>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  removeUser: groupActions.removeUserGroup,
  addAdmin: groupActions.addGroupAdmin,
  removeAdmin: groupActions.removeGroupAdmin,
  loadGroupUsers: groupActions.loadGroupUsers,
}, dispatch));

const mapStateToProps = state => ({
  loading: state.groups.isSearchingGroupMembers,
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupUsers);
