import * as types from '../actions/actionTypes';
import { paginationLimit } from '../../constants';

const defaultUsers = {
  limit: paginationLimit,
  page: 0,
  resultCount: 0,
  totalPages: 1,
  users: [],
};

const defaultState = {
  users: defaultUsers,
  error: '',
  selectedUser: null,
  isSearching: false,
  isSearchingUserMemberships: false,
  isEditingUser: false,
  userEdiMessage: '',
  userEditError: '',
  addedUser: null,
};

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case types.USERS_LOADING: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case types.LOAD_USERS_SUCCESS:
    case types.SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        isSearching: false,
        error: '',
      };
    }
    case types.LOAD_USERS_FAILURE:
    case types.SEARCH_USERS_FAILURE: {
      return {
        ...state,
        users: defaultUsers,
        error: action.payload,
        isSearching: false,
      };
    }
    case types.ADD_USER_SUCCESS: {
      return {
        ...state,
        addedUser: action.payload,
        addUserError: '',
      };
    }
    case types.EDITING_USER: {
      return {
        ...state,
        isEditingUser: true,
        userEditMessage: '',
        userEditError: '',
      };
    }
    case types.EDITING_USER_ERROR: {
      return {
        ...state,
        isEditingUser: false,
        userEditError: action.payload,
      };
    }
    case types.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isEditingUser: false,
        userEditMessage: action.payload,
      };
    }
    case types.CLEAR_USER_ERRORS: {
      return {
        ...state,
        addUserError: '',
        error: '',
        userEditError: '',
        userEdiMessage: '',
      };
    }
    case types.ADD_USER_FAILURE: {
      return {
        ...state,
        addUserError: action.payload,
        addedUser: null,
      };
    }
    case types.ADD_USER_TO_GROUP_FAILURE: {
      return {
        ...state,
      };
    }
    case types.ADD_USER_TO_GROUP_SUCCESS: {
      return {
        ...state,
        message: action.payload,
      };
    }
    case types.USER_MEMBERSHIPS_LOADING: {
      return {
        ...state,
        isSearchingUserMemberships: true,
      };
    }
    case types.LOAD_USER_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload,
        isSearching: false,
        isSearchingUserMemberships: false,
      };
    }
    case types.CLEAR_USER_SUCCESS: {
      return {
        ...state,
        selectedUser: null,
        isSearchingUserMemberships: false,
      };
    }
    case types.LOGOUT_FAILURE:
    case types.LOGOUT_SUCCESS: {
      return {
        ...defaultState,
      };
    }
    default: {
      return state;
    }
  }
}
