import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Search } from 'rtm-ui-components';
import _ from 'lodash';
import Tag from '../../../../Wrappers/Tag/Tag';
import * as userActions from '../../../../../store/actions/users';
import * as botActions from '../../../../../store/actions/bots';
import { paginationLimit } from '../../../../../constants';

class AddBotUsers extends Component {
  state = {
    name: '',
    selectedUsers: [],
    page: 1,
    limit: paginationLimit,
    loading: false,
  }

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ name: '' });
  };

  handleResultSelect = (result) => {
    const { selectedUsers } = this.state;
    const tempUsers = [...selectedUsers];
    tempUsers.push(result);
    this.setState({ selectedUsers: tempUsers });
    this.resetComponent();
  }

  handleSearchChange = async (value) => {
    await this.setState({ name: value });
    if (value.length < 1) {
      this.resetComponent();
    } else {
      this.searchUsers(value);
    }
  }

  handleSubmit = async () => {
    const { selectedUsers } = this.state;
    const { addAction, selectedBot, addUserBot } = this.props;
    this.setState({ loading: true });
    const promises = selectedUsers.map(user => addUserBot(user, selectedBot));
    this.setState({ name: '', selectedUsers: [] });
    await Promise.all(promises);
    this.setState({ loading: false });
    addAction();
  }

  searchUsers = () => {
    const { name, page, limit } = this.state;
    const { getUsers } = this.props;
    const params = {
      name: name || '',
      page: page - 1,
      limit,
    };
    getUsers(params);
  }

  removeSelectedUser = (index) => {
    const { selectedUsers } = this.state;
    const tempUsers = [...selectedUsers];
    tempUsers.splice(index, 1);
    this.setState({ selectedUsers: tempUsers });
  }

  renderUser = (user, index) => (
    <Tag name={user.username} key={user.uuid} onDelete={() => this.removeSelectedUser(index)} text={`${user.firstName} ${user.lastName}`} />
  );

  render() {
    const { searchResults, selectedBot, isSearching } = this.props;
    const { loading, selectedUsers } = this.state;
    let usersInBot = [];
    let results = [];
    let usersSelectedUsersInBot = [];
    if (selectedBot.users.length) {
      // detail view "add users to bot"-> users.map
      usersInBot = selectedBot.users && selectedBot.users.map(({ user }) => user);
      //results = _.differenceWith(searchResults, [...selectedUsers, ...usersInBot], (u1, u2) => u1.uuid === u2.uuid);
    } else {
      // edit after creating a bot-> users.botMembers.map
      if(selectedBot.users.length > 0){
        usersInBot = selectedBot.users && selectedBot.users.botMembers.map(({ user }) => user);
      }
      //results = _.differenceWith(searchResults, [...selectedUsers, ...usersInBot], (u1, u2) => u1.uuid === u2.uuid);
    }
    // let results = _.differenceWith(searchResults, [...selectedUsers, ...usersInBot], (u1, u2) => u1.uuid === u2.uuid);
    usersSelectedUsersInBot = [...selectedUsers, ...usersInBot].map(u => u.uuid);
    results = searchResults.map(u => ({
      key: u.uuid,
      text: `${u.firstName} ${u.lastName} (${u.username}) - ${u.email}`,
      id: `${u.username}-result`,
      uuid: u.uuid,
      title: '',
      firstName: u.firstName,
      lastName: u.lastName,
      email: u.email,
      username: u.username,
      description: `${u.firstName} ${u.lastName} (${u.username}) - ${u.email}`,
    }));

    const selectedUsersWrapper = selectedUsers && selectedUsers.map(this.renderUser);
    return (
      <div className="add-entity-user add-bots">
        <Search
          fluid
          loading={isSearching}
          id="add-users-to-bot"
          placeholder="Search Users"
          results={results}
          debounceTimer={300}
          onChange={e => this.handleSearchChange(e.target.value)}
          onResultSelect={this.handleResultSelect}
          disabledKeys={usersSelectedUsersInBot}
        />
        {selectedUsersWrapper && (<div className="tags">{selectedUsersWrapper}</div>)}
        <div className="btn-container">
          <Button
            loading={loading}
            id="add-entity-btn"
            type="submit"
            variant="primary"
            onClick={this.handleSubmit}
            disabled={!selectedUsers || selectedUsers.length === 0}
          >Add
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.users.users.users,
  isSearching: state.users.isSearching,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getUsers: userActions.getUsers,
  addUserBot: botActions.addUserBot,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AddBotUsers);
