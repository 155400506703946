import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Label } from 'rtm-ui-components';
import * as utils from '../../utils';
import {
  User, Bot, Group, Role, Operations,
} from '../../propTypes';

// sysadmin
import HasRole from '../../roles/HasRole';
import LogsBlock from '../Audits/LogsBlock/LogsBlock';

// global refactored
import Blocks from './Blocks/Blocks';
import CardsWrapper from '../Wrappers/CardsWrapper/CardsWrapper';
import TablesWrapper from '../Wrappers/TablesWrapper/TablesWrapper';
import ChangeView from './ChangeView/ChangeView';
import './PageContent.css';

const PageContent = ({
  logs, removeFilter, addFilter, addAndRemoveFilter, filters, operations, orderContent, value, className,
  allBots, allGroups, currentPage, changeListingType, cards, allUsers, totalPages, usersPage, changePage, filterDropdowns, totalResults, createButton,
}) => {
  let content = '';
  if (cards) {
    switch (currentPage) {
      case 'users':
        // eslint-disable-next-line no-case-declarations
        const usersIndex = utils.getUsersIndex(allUsers);
        content = (
          <CardsWrapper
            className={className}
            showPagination
            id="top-pagination"
            totalPages={totalPages}
            activePage={usersPage}
            onPageChange={changePage}
            blocks={_.map(usersIndex, (users, index) => (<Blocks key={index} users={users} index={index} />))}
          />
        );
        break;
      case 'groups':
        // eslint-disable-next-line no-case-declarations
        const groupsIndex = allGroups && utils.getGroupsIndex(allGroups);
        content = (
          <CardsWrapper
            className={className}
            showPagination
            id="top-pagination"
            totalPages={totalPages}
            activePage={usersPage}
            onPageChange={changePage}
            blocks={_.map(groupsIndex, (groups, index) => (<Blocks key={index} groups={groups} index={index} />))}
          />
        );
        break;
      case 'bots':
        // eslint-disable-next-line no-case-declarations
        const botsIndex = allBots && utils.getBotsIndex(allBots);
        content = (
          <CardsWrapper
            className={className}
            showPagination
            id="top-pagination"
            totalPages={totalPages}
            activePage={usersPage}
            onPageChange={changePage}
            blocks={_.map(botsIndex, (bots, index) => (<Blocks key={index} bots={bots} index={index} />))}
          />
        );
        break;
      default:
        break;
    }
  } else {
    switch (currentPage) {
      case 'users':
        content = (
          <TablesWrapper
            className={className}
            showPagination
            title="Users list"
            id="top-pagination"
            totalPages={totalPages}
            activePage={usersPage}
            onPageChange={changePage}
            users={allUsers}
          />
        );
        break;
      case 'groups':
        content = (
          <TablesWrapper
            className={className}
            showPagination
            id="top-pagination"
            totalPages={totalPages}
            activePage={usersPage}
            onPageChange={changePage}
            groups={allGroups}
          />
        );
        break;
      case 'bots':
        content = (
          <TablesWrapper
            className={className}
            showPagination
            id="top-pagination"
            totalPages={totalPages}
            activePage={usersPage}
            onPageChange={changePage}
            bots={allBots}
          />
        );
        break;
      case 'sysadmin':
        content = (
          <div id="list-table" className="list-container table">
            <HasRole requiredRole="sysAdmin">
              <LogsBlock
                removeFilter={removeFilter}
                addFilter={addFilter}
                addAndRemoveFilter={addAndRemoveFilter}
                logs={logs}
                filters={filters}
                operations={operations}
                value={value}
                orderContent={orderContent}
                totalPages={totalPages}
              />
            </HasRole>
            <HasRole requiredRole="nonSysAdmin">
              <div className="not-authorised">NOT AUTHORISED TO VIEW THIS PAGE</div>
            </HasRole>
          </div>
        );
        break;
      default:
        break;
    }
  }
  let filterContent = '';
  if (!(!cards && currentPage === 'sysadmin')) {
    filterContent = (
      <div className="header-container">
        <div className="filter-container">
          <div className="header-left">
            {filterDropdowns}
          </div>
          <div className="header-right">
            {createButton
            && (
              <div className="header-right-button">
                <HasRole requiredRole={createButton.requiredRole}>
                  <Button variant="primary" onClick={createButton.function} id={createButton.id} icon="add" iconColor="black">{createButton.text}</Button>
                </HasRole>
              </div>
            )
            }
            <div className="header-right-view">
              <Label text="View" />
              <ChangeView changeListingStyle={changeListingType} cards={cards} />
            </div>
          </div>
        </div>
        {totalResults && (<div> {totalResults}</div>)}
      </div>
    );
  }

  return (
    <div className="page-content">
      {filterContent}
      {content}
    </div>
  );
};

PageContent.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      created: PropTypes.string,
      principal: PropTypes.string,
      type: PropTypes.string,
      ipAddress: PropTypes.string,
      userName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.shape(Role),
      data: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
        remoteAddress: PropTypes.string,
      }),
    })
  ),
  removeFilter: PropTypes.func,
  addFilter: PropTypes.func,
  addAndRemoveFilter: PropTypes.func,
  filters: PropTypes.shape({
    page: PropTypes.number,
    asc: PropTypes.bool,
    user: PropTypes.string,
  }),
  operations: PropTypes.shape(Operations),
  allBots: PropTypes.arrayOf(
    PropTypes.shape(Bot)
  ),
  allGroups: PropTypes.arrayOf(
    PropTypes.shape(Group)
  ),
  allUsers: PropTypes.arrayOf(
    PropTypes.shape(User)
  ),
  currentPage: PropTypes.oneOf(['users', 'groups', 'bots', 'sysadmin']),
  changeListingType: PropTypes.func,
  cards: PropTypes.bool,
  totalPages: PropTypes.number,
  usersPage: PropTypes.number,
  changePage: PropTypes.func,
  createButton: PropTypes.shape({
    function: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

PageContent.defaultProps = {
  logs: null,
  removeFilter: null,
  addFilter: null,
  addAndRemoveFilter: null,
  filters: null,
  operations: null,
  allUsers: null,
  allBots: null,
  allGroups: null,
  currentPage: 'users',
  changeListingType: null,
  cards: false,
  totalPages: 0,
  usersPage: 0,
  changePage: null,
  createButton: null,
};


export default PageContent;
