import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Spinner } from 'rtm-ui-components';
import LoginForm from './LoginForm/LoginForm';
import RecoverPasswordForm from './RecoverPassword/RecoverPasswordForm/RecoverPasswordForm';
import ResetPasswordForm from './ResetPassword/ResetPasswordForm/ResetPasswordForm';
import Segment from '../../Wrappers/Segment/Segment';
import * as authActions from '../../../store/actions/auth';
import { parseURLParams } from '../../../utils';
import './AuthPage.css';

class AuthPage extends Component {
  constructor(props) {
    super();
    props.getPublicConfigs();
    this.state = {
      samlLoginTried: false,
    };
  }

  render() {
    const {
      samlEnabled, samlLoginUrl, samlLogin, user,
      loginPage, passwordrecoveryPage, passwordResetPage,
      submitAction, authError, authMessage, loading, loginFailed, instantSaml
    } = this.props;
    const { samlLoginTried } = this.state;
    const urlParams = parseURLParams(window.location.search);
    const SSO = ('SSO' in urlParams && urlParams.sso !== 'false') || (instantSaml && instantSaml.value);
    if (SSO && samlEnabled && samlEnabled.value && samlLoginUrl && !samlLoginTried) {
      samlLogin(samlLoginUrl.value);
      this.setState({ samlLoginTried: true });
    }
    const authClass = loginPage ? 'login' : 'signup';
    return (
      <div id="auth">
        { SSO && (!samlEnabled || (samlEnabled.value && !user))
          ? (
            <div className="loading-screen">
              <div className="loading-wrapper">
                <Icon id="eficode-loading-logo" name="RTMWhite" />
                <div className="loading-content">
                  <Spinner inverted />
                  <p>Loading ...</p>
                  <div className="login-success-container">
                    {loginFailed && !samlEnabled ? (<p>Login failed. It is possible that the account is locked, disabled, or the password has expired. Please contact your admin.</p>) : (<p></p>)}
                    {loginFailed && samlEnabled ? (<p>Login loading... If this takes a while it might be due to misconfigured routing on SAML or old cached saml token. Try to refresh or reauthenticate on your IDP.</p>) : (<p></p>)}
                  </div>
                </div>
              </div>
            </div>
          )
          : (
            <div className={`${authClass} auth-page`}>
              <div className="form-wrapper">
                <Segment>
                  { loginPage && <LoginForm loading={loading} onSubmit={submitAction} errorMessage={authError} authMessage={authMessage} samlEnabled={samlEnabled} />}
                  { passwordrecoveryPage && <RecoverPasswordForm onSubmit={submitAction} errorMessage={authError} />}
                  { passwordResetPage && <ResetPasswordForm onSubmit={submitAction} errorMessage={authError} />}
                </Segment>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => (bindActionCreators({
  getPublicConfigs: authActions.getPublicConfigs,
  samlLogin: authActions.samlLogin,
}, dispatch));
const mapStateToProps = state => ({
  instantSaml: state.configs.publicConfigs['instant.samlflow'],
  samlEnabled: state.configs.publicConfigs['saml.enabled'],
  samlLoginUrl: state.configs.publicConfigs['saml.discovery'],
  user: state.auth.user,
  loading: state.auth.loading,
  loginFailed: state.auth.loginFailed,
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
