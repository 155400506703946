import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'rtm-ui-components';
import './Tag.css';

const Tag = (props) => {
  const { text, onDelete, name } = props;
  return (
    <div className="tag">
      <span id={name} className="found-username">{text}</span>
      <Icon onClick={onDelete} size="tiny" name="delete" />
    </div>
  );
};

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string,
};

Tag.defaultProps = {
  name: '',
};

export default Tag;
