/* eslint-disable no-bitwise */
export const getCntPerCol = (arr, nCol) => arr.reduce((counters, item, index) => {
  const newCounters = [...counters];
  newCounters[index % nCol] = newCounters[index % nCol] + 1;
  return newCounters;
}, [0, 0, 0, 0]);

export const getCols = (arr, nCol) => getCntPerCol(arr, nCol)
  .reduce((accumulator, count) => [...accumulator.slice(count), accumulator.slice(0, count)], arr);

export const getUsersIndex = (users) => {
  users.sort((a, b) => {
    if (`${a.lastName.toLowerCase()}${a.firstName.toLowerCase()}${a.username.toLowerCase()}` > `${b.lastName.toLowerCase()}${b.firstName.toLowerCase()}${b.username.toLowerCase()}`) {
      return 1;
    }
    return -1;
  });

  const usersByIndex = users.reduce((accumulator, user) => {
    const index = user.lastName[0].toUpperCase();
    if (accumulator[index]) {
      return { ...accumulator, [index]: [...accumulator[index], user] };
    }
    return { ...accumulator, [index]: [user] };
  }, {});
  return usersByIndex;
};

export const getBotsIndex = (bots) => {
  bots.sort((a, b) => {
    if (`${a.username.toLowerCase()}}` > `${b.username.toLowerCase()}`) {
      return 1;
    }
    return -1;
  });

  const botsByIndex = bots.reduce((accumulator, bot) => {
    const index = bot.username[0].toLowerCase();
    if (accumulator[index]) {
      return { ...accumulator, [index]: [...accumulator[index], bot] };
    }
    return { ...accumulator, [index]: [bot] };
  }, {});
  return botsByIndex;
};

export const getGroupsIndex = (groups) => {
  groups.sort((a, b) => {
    if (`${a.longname.toLowerCase()}` >= `${b.longname.toLowerCase()}`) {
      return 1;
    }
    return -1;
  });
  const groupsByIndex = groups.reduce((accumulator, group) => {
    const index = group.longname[0].toUpperCase();
    if (accumulator[index]) {
      return { ...accumulator, [index]: [...accumulator[index], group] };
    }
    return { ...accumulator, [index]: [group] };
  }, {});
  return groupsByIndex;
};

export const getGroupsIndexDetailsView = (groups) => {
  groups.sort((a, b) => {
    if (`${a.group.longname.toLowerCase()}` >= `${b.group.longname.toLowerCase()}`) {
      return 1;
    }
    return -1;
  });
  const groupsByIndex = groups.reduce((accumulator, groupObject) => {
    const index = groupObject.group.longname[0].toUpperCase();
    if (accumulator[index]) {
      return { ...accumulator, [index]: [...accumulator[index], groupObject.group] };
    }
    return { ...accumulator, [index]: [groupObject.group] };
  }, {});
  return groupsByIndex;
};

export const getTotalRows = (results, type) => {
  const min = results.resultCount ? results.page * results.limit + 1 : 0;
  const max = (results.page + 1) * results.limit > results.resultCount ? results.resultCount : (results.page + 1) * results.limit;

  const str = `Showing ${min} - ${max} of ${results.resultCount} ${type}`;
  return str;
};


export const decodeTokenPayload = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

// export const isExpiredPassword = (config) => {
// impl-1
// if (!config) {
//   return false;
// }
// const {
//   year, monthValue, dayOfMonth, hour, minute,
// } = config;
// const date = new Date(year, monthValue, dayOfMonth, hour, minute);
// return date.getTime() < (new Date()).getTime();
// impl- 2
// const pwdExpEnabled = config.find(cfg => cfg.name === 'password.expiration.enabled');
// const pwdExpiration = config.find(cfg => cfg.name === 'password.expiration.minutes');
// return pwdExpEnabled.value && pwdExpiration.value < 0;
// };


export const ucfirst = string => string.charAt(0).toUpperCase() + string.slice(1);

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email) {
    return re.test(String(email).toLowerCase());
  }
  return true;
};

export const convertISODate = (isoDate) => {
  const date = new Date(isoDate);
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const timeUntilFromMinutes = (minutes) => {
  const minutesInDay = 60 * 24;
  const days = minutes / minutesInDay;
  if (days >= 1) {
    const fullDays = Math.floor(days);
    const d = fullDays > 1 ? 's' : '';
    return `${fullDays} day${d}`;
  }
  const hours = minutes / 60;
  if (hours >= 1) {
    const fullHours = Math.floor(hours);
    const h = fullHours > 1 ? 's' : '';
    return `${fullHours} hour${h}`;
  }
  const m = minutes > 1 ? 's' : '';
  return `${minutes} minute${m}`;
};

/*
  joins elements of an array with comma and 'and'
  if there's only one item in an array, returns the item
  if two items returns a and b
  if >2, returns a, ... , b and c.
*/
export const joinWithAnd = arr => [arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(arr.length < 2 ? '' : ' and ');

/*
  takes window.location.search > ?id=1&abc=true&pass=false
 returns {id: 1, abc: true, pass: false}
*/
export const parseURLParams = uri => uri.slice(1).split('&').map((keyVal) => {
  const obj = {};
  const [_key, _val] = keyVal.split('=');
  obj[_key] = _val;
  return obj;
}).reduce((acc, curr) => {
  for (const key in curr) {
    if (curr.hasOwnProperty(key)) {
      acc[key] = curr[key];
    }
  }
  return acc;
}, {});

export const createUUID = function b(a) {
  // eslint-disable-next-line no-mixed-operators
  return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
};

export const invalidPassword = (password, passwordConfig) => {
  const regexVal = passwordConfig && passwordConfig.value;
  const regex = new RegExp(regexVal);
  if (password) {
    return !regex.test(password);
  }
  return true;
};

export const invalidUsername = (username, usernameConfig) => {
  const regexVal = usernameConfig && usernameConfig.value;
  const regex = new RegExp(regexVal);
  if (username) {
    return !regex.test(username);
  }
  return true;
};

export const invalidName = (name, nameConfig) => {
  const regexVal1 = '^\\s+|\\s+$';
  const regexVal2 = nameConfig && nameConfig.value;
  const regex1 = new RegExp(regexVal1);
  const regex2 = new RegExp(regexVal2);
  if (name) {
    return (regex1.test(name) || !regex2.test(name));
  }
  return true;
};

export const invalidGroupShortname = (name, shortnameConfig) => {
  const regexVal = shortnameConfig && shortnameConfig.value;
  const regex = new RegExp(regexVal);
  if (name) {
    return !regex.test(name);
  }
  return true;
};

export const entityCardDetails = (entity) => {
  // group
  if (entity.isBot === undefined) {
    const details = [
      {
        title: 'Long name',
        value: entity.longname,
      },
      {
        title: 'Short name',
        value: entity.name,
      },
      {
        title: 'Visibility',
        value: entity.isPublic ? 'Public' : 'Private',
      },
      {
        title: 'Description',
        value: entity.description || '',
      },
    ];

    if (entity.externalId !== undefined) {
      details.push(
        {
          title: 'External',
          value: 'True',
        }
      );
    }
    return details;
  }
  // bot
  if (entity.isBot) {
    return [
      {
        title: 'Name',
        value: entity.username,
      },
      {
        title: 'Role',
        value: ucfirst(entity.appRole),
      },
      {
        title: 'Visibility',
        value: entity.isPublic ? 'Public' : 'Private',
      },
      {
        title: 'Status',
        value: entity.active ? 'Active' : 'Inactive',
      },
    ];
  }
  // user
  const details = [
    {
      title: 'Name',
      value: `${entity.firstName} ${entity.lastName}`,
    },
    {
      title: 'Username',
      value: entity.username,
    },
    {
      title: 'Email',
      value: entity.email,
    },
    {
      title: 'Role',
      value: entity.appRole !== 'ext' ? ucfirst(entity.appRole) : 'External User',
    },
    {
      title: 'Status',
      // eslint-disable-next-line no-nested-ternary
      value: entity.active ? (entity.locked ? 'Locked' : 'Active') : 'Inactive',
    },
  ];
  if (entity.externalId !== undefined) {
    details.push({
      title: 'External',
      value: 'true',
    });
  }
  return details;
};


export const icon = (entity) => {
  if (entity === 'group') {
    return {
      name: 'group', color: 'secondary',
    };
  }
  // bot
  if (entity.isBot) {
    if (!entity.enabled) {
      return {
        name: `${entity.appRole}Bot`,
        color: 'grey',
      };
    }
    if (entity.locked) {
      return {
        name: 'lockedBot',
        color: 'grey',
      };
    }
    const iconObj = { name: `${entity.appRole}Bot` };
    if (entity.appRole === 'default') {
      iconObj.style = { fill: 'var(--color-secondary)' };
    } else if (entity.appRole === 'integration') {
      iconObj.style = { fill: 'var(--color-purple)' };
    } else {
      iconObj.style = { fill: 'var(--color-blue)' };
    }
    return iconObj;
  }
  // user
  if (!entity.active) {
    return {
      name: entity.appRole === 'ext' ? 'externalUser' : entity.appRole,
      color: 'grey',
    };
  }
  if (entity.locked) {
    return {
      name: 'lockedUser', color: 'grey',
    };
  }
  if (entity.appRole === 'ext') {
    return {
      name: 'externalUser',
      style: { fill: 'var(--color-teal)' },
    };
  }
  const iconObj = { name: entity.appRole };
  if (entity.appRole === 'user') {
    iconObj.style = { fill: 'var(--color-orange)' };
  } else if (entity.appRole === 'admin') {
    iconObj.style = { fill: 'var(--color-purple)' };
  } else if (entity.appRole === 'sysadmin') {
    iconObj.style = { fill: 'var(--color-blue)' };
  }
  return iconObj;
};


export const logsIcon = (entity) => {
  let iconObj = { name: entity.name };
  if (entity.type === 'USER') {
    if (entity.name === 'user') {
      iconObj.color = 'orange';
    } else if (entity.name === 'admin') {
      iconObj.style = { fill: 'var(--color-purple)' };
    } else if (entity.name === 'sysadmin') {
      iconObj.style = { fill: 'var(--color-blue)' };
    } else if (entity.name === 'ext') {
      // TODO: check this, it probably never even hits, it should be handled with the EXT_USER case
      iconObj.style = { fill: 'var(--color-teal)' };
    }
    return iconObj;
  }
  if (entity.type === 'BOT') {
    if (entity.name === 'default') {
      iconObj.style = { fill: 'var(--color-secondary)' };
    } else if (entity.name === 'integration') {
      iconObj.style = { fill: 'var(--color-purple)' };
    } else {
      iconObj.style = { fill: 'var(--color-blue)' };
    }
  }
  if (entity.type === 'EXT_USER') {
    iconObj = {
      name: 'externalUser',
      style: { fill: 'var(--color-teal)' },
    };
  } else {
    iconObj = {
      name: 'undefinedUser',
      style: { fill: 'grey' },
    };
  }

  return iconObj;
};

export const roleClass = (role, callFor) => {
  if (callFor === 'admin') {
    if (role.name === 'admin') {
      return 'role active';
    }
    return 'role';
  }
  if (callFor === 'member') {
    if (role.name === 'member' || role.name === 'user') {
      return 'role active';
    }
    return 'role';
  }
  return true;
};

export const isValidIp = (address) => {
  const regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/g
  return address.match(regex);
};
