import React from 'react';
import './AddFirstEntity.css';
import PropTypes from 'prop-types';

const AddFirstEntity = ({
  iconName, text,
}) => (
  <div className="add-first-entity-wrapper">
    <div className={iconName} />
    <p>{text}</p>
  </div>
);

AddFirstEntity.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default AddFirstEntity;
