/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Input, Dropdown, Spinner, Label, Pagination,
} from 'rtm-ui-components';
import AddEntityWrapper from '../../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import * as groupActions from '../../../../store/actions/groups';
import { groupVisibilityFilters, paginationLimit } from '../../../../constants';
import HasRole from '../../../../roles/HasRole';
import DetailsCard from '../../../SharedComponents/DetailsCard/DetailsCard';
import Table from '../../../SharedComponents/Table/Table';
import AddNestedGroups from './AddNestedGroups/AddNestedGroups';
import AddFirstEntity from '../../../SharedComponents/AddFirstEntity/AddFirstEntity';
import '../../../../assets/stylesheets/DetailsViewContent.css';
import { entityCardDetails } from '../../../../utils';
import ChangeView from '../../../SharedComponents/ChangeView/ChangeView';

class NestedGroups extends Component {
  state = {
    name: '',
    lastSearchName: '',
    visibilityFilter: 'all',
    addGroups: false,
    cards: true,
    limit: paginationLimit,
    page: 0,
  };

  componentDidMount = async () => {
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    this.searchNestedGroups();
    if (settings && settings.detailsListingCards !== undefined && !settings.detailsListingCards) {
      this.setState({ cards: false });
    }
    this.setName = _.debounce(this.setName, 300);
  }

  setName = async (value) => {
    if (!value) {
      await this.setState({ name: null, page: 0 });
    } else {
      await this.setState({ name: value, page: 0 });
    }
    const { lastSearchName, name } = this.state;
    if (lastSearchName !== name) {
      this.searchNestedGroups();
    }
    await this.setState({ lastSearchName: name });
  }

  setVisibility = async (item) => {
    await this.setState({ visibilityFilter: item.value });
    this.searchNestedGroups();
  }

  searchNestedGroups = () => {
    const {
      name, page, limit, visibilityFilter,
    } = this.state;
    const { parentGroup, loadNestedGroups } = this.props;
    const params = {
      name: name || '',
      visibility: visibilityFilter === 'all' ? '' : visibilityFilter,
      page,
      limit,
    };
    loadNestedGroups(parentGroup.uuid, params);
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.detailsListingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  toggleModal = () => {
    const { addGroups } = this.state;
    this.setState({ addGroups: !addGroups });
  }

  changePage = async (activePage) => {
    await this.setState({ page: activePage });
    this.searchNestedGroups();
  }

  render() {
    const {
      parentGroup, removeNestedGroup, loading, location,
    } = this.props;
    const {
      addGroups, cards,
    } = this.state;
    const detailsgroupVisibilityFilters = groupVisibilityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const childGroups = parentGroup.childGroups && parentGroup.childGroups.groups;

    let renderedType;
    if ((!childGroups || !childGroups.length) && loading) {
      renderedType = (
        <div className="details-list-container table">
          <Spinner />
        </div>
      );
    } else if (!childGroups || !childGroups.length) {
      renderedType = (
        <div className="details-list-container table">
          <AddFirstEntity iconName="addGroupIcon" text="There are no nested groups." />
        </div>
      );
    } else if (cards) {
      const groupGroupsCards = childGroups.map((group) => {
        const details = entityCardDetails(group);
        const actions = [
          {
            id: 'remove-nested-group',
            title: 'Remove nested group',
            onClick: async () => {
              await removeNestedGroup(group, parentGroup);
              this.searchNestedGroups(parentGroup.uuid);
            },
            requiredRole: 'groupEdit',
            resource: parentGroup,
          },
        ];

        return (
          <DetailsCard
            key={group.uuid}
            icon={{ name: 'group', color: 'primary' }}
            isPrivate={!group.isPublic}
            name={group.longname}
            description={group.name}
            details={details}
            actions={actions}
            currentUrl={location.pathname}
            link={{ title: 'Edit group', target: `/groups/${group.uuid}` }}
          />
        );
      });
      renderedType = (
        <div className="details-list-container cards">
          { groupGroupsCards }
        </div>
      );
    } else {
      const childGroupsTableContent = childGroups.map((group) => {
        const details = entityCardDetails(group);
        const actions = [
          {
            id: 'remove-nested-group',
            title: 'Remove nested group',
            onClick: async () => {
              await removeNestedGroup(group, parentGroup);
              this.searchNestedGroups(parentGroup.uuid);
            },
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        return {
          details, group, actions,
        };
      });
      const groupGroupsTable = <Table nestedGroups={childGroupsTableContent || null} />;
      renderedType = (
        <div className="details-list-container table">
          { groupGroupsTable }
        </div>
      );
    }
    return (
      <div className="details-membership-row" id="groups-page">
        <div className="user-group-form">
          <div className="details-inputs">
            <Input
              className="user-search"
              id="filter-nested-groups-input"
              placeholder="Filter Nested Groups"
              onChange={(event) => {
                const { value } = event.target;
                this.setName(value);
              }}
              icon="search"
            />
            <div className="details-left">
              <HasRole requiredRole="groupEdit" resource={parentGroup}>
                <Button
                  variant="primary"
                  id="add-nested-group"
                  iconColor="black"
                  icon="add"
                  onClick={this.toggleModal}
                >
                  Add Nested Groups
                </Button>
              </HasRole>
              <ChangeView cards={cards} changeListingStyle={this.changeListingStyle} />
            </div>
          </div>
          <div className="filter-container">
            <div className="filters-wrapper">
              <div>
                <Label text="Group Visibility" />
                <Dropdown defaultValue={detailsgroupVisibilityFilters[0].text} options={detailsgroupVisibilityFilters} onChange={item => this.setVisibility(item)} />
              </div>
            </div>
          </div>
          <div className="details-entity-listing">
            { renderedType }
          </div>
          <Pagination
            id="group-nested-groups-pagination"
            totalPages={parentGroup.childGroups ? parentGroup.childGroups.totalPages : 1}
            activePage={parentGroup.childGroups ? parentGroup.childGroups.page : 0}
            onPageChange={this.changePage}
          />
          {addGroups ? (
            <AddEntityWrapper
              onClose={this.toggleModal}
              open={addGroups}
              title="Add Nested Groups"
            >
              <AddNestedGroups
                addAction={() => { this.searchNestedGroups(); this.toggleModal(); }}
                selectedGroup={parentGroup}
              />
            </AddEntityWrapper>
          ) : null}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => (bindActionCreators({
  removeNestedGroup: groupActions.removeNestedGroup,
  loadNestedGroups: groupActions.loadNestedGroups,
}, dispatch));

const mapStateToProps = state => ({
  loading: state.groups.isSearchingGroupMembers,
});

export default connect(mapStateToProps, mapDispatchToProps)(NestedGroups);
