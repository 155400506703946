import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRangePicker } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';
import {
  Input, Label, Checkbox, Button,
} from 'rtm-ui-components';
import 'react-dates/lib/css/_datepicker.css';
import * as usersActions from '../../../store/actions/users';
import Message from '../../SharedComponents/Message/Message';
import { isValidEmail, invalidUsername, invalidName } from '../../../utils';
import '../../../assets/stylesheets/AddEntityForm.css';
import './AddUserForm.css';

class AddUserForm extends Component {
  state = {
    submitting: false,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    external: false,
    organisation: '',
    focusedInput: null,
    activityStartTime: null,
    activityEndTime: null,
    touched: {
      firstName: false,
      lastName: false,
      username: false,
      email: false,
    },
    formErrors: {
      firstName: false,
      lastName: false,
      username: false,
      email: false,
    },
  }

  componentDidMount() {
    const { clearErrors } = this.props;
    clearErrors();
  }

  toggleIsExternal = () => {
    const { external } = this.state;
    this.setState({
      external: !external,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      firstName, lastName, username, email, external, activityStartTime, activityEndTime, organisation,
    } = this.state;
    const { onSubmit } = this.props;
    this.setState({ submitting: true });

    const submittedDetails = {
      firstName,
      lastName,
      username,
      email,
      external,
    };
    if (external) {
      submittedDetails.activityStartTime = activityStartTime ? activityStartTime.clone().utc().startOf('date').toISOString() : undefined;
      submittedDetails.activityEndTime = activityEndTime ? activityEndTime.clone().utc().endOf('date').toISOString() : undefined;
      submittedDetails.organisation = organisation;
    }
    try {
      await onSubmit(submittedDetails);
    // eslint-disable-next-line no-empty
    } catch (error) {}
    this.setState({ submitting: false });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const { formErrors, touched } = this.state;
    const { nameConfig, usernameConfig } = this.props;
    switch (name) {
      case 'firstName':
      case 'lastName':
        if (invalidName(value, nameConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        touched[name] = true;
        break;
      case 'username':
        if (invalidUsername(value, usernameConfig)) {
          formErrors.username = true;
        } else {
          formErrors.username = false;
        }
        touched.username = true;
        break;
      case 'email':
        if (!isValidEmail(value)) {
          formErrors.email = true;
        } else {
          formErrors.email = false;
        }
        touched.email = true;
        break;
      default:
        break;
    }
    this.setState({ formErrors, touched, [name]: value });
  }

  isDisabled = () => {
    const {
      formErrors, touched, external, activityStartTime, activityEndTime,
    } = this.state;
    let error = false;
    Object.keys(formErrors).forEach((key) => {
      if (formErrors[key]) {
        error = true;
      }
    });
    Object.keys(touched).forEach((key) => {
      if (!touched[key]) {
        error = true;
      }
    });
    if (external && (!activityStartTime || !activityEndTime)) {
      error = true;
    }
    return error;
  }

  render() {
    const {
      usernameConfig, nameConfig, error,
    } = this.props;
    const {
      activityStartTime, activityEndTime, focusedInput, formErrors, submitting, external,
    } = this.state;
    return (
      <div className="add-entity-view">
        {error ? (
          <div className="form-message-container">
            <Message negative header={error} />
          </div>
        ) : null
        }
        <form onSubmit={this.handleSubmit} id="add-user-form" className="add-entity-view-form">
          <div className="add-entity-field">
            <Label text="First name" info={nameConfig && nameConfig.description} htmlFor="firstname-field" />
            <Input
              fluid
              type="text"
              placeholder="First Name"
              name="firstName"
              id="firstname-field"
              onChange={this.handleChange}
              error={formErrors.firstName}
            />
          </div>
          <div className="add-entity-field">
            <Label info={nameConfig && nameConfig.description} text="Last name" htmlFor="lastName-field" />
            <Input
              fluid
              type="text"
              placeholder="Last Name"
              id="lastname-field"
              name="lastName"
              onChange={this.handleChange}
              error={formErrors.lastName}
            />
          </div>
          <div className="add-entity-field">
            <Label text="Username" info={usernameConfig && usernameConfig.description} htmlFor="username-field" />
            <Input
              fluid
              type="text"
              placeholder="Username"
              id="username-field"
              name="username"
              onChange={this.handleChange}
              error={formErrors.username}
            />
          </div>
          <div className="add-entity-field">
            <Label text="Email" htmlFor="email-field" />
            <Input
              fluid
              type="email"
              placeholder="Email"
              id="email-field"
              name="email"
              onChange={this.handleChange}
              error={formErrors.email}
            />
          </div>
          <div className="add-entity-field">
            <Checkbox
              id="external-user-field"
              onChange={this.toggleIsExternal}
              name="external"
              label="External User"
            />
          </div>
          { external ? (
            <React.Fragment>
              <div className="field">
                <Label
                  text="Active period"
                  htmlFor="activeStartDateField"
                  info="Activity starts on selected start date at 00:00 UTC and ends at end date at 23:59 UTC"
                />
                <DateRangePicker
                  startDatePlaceholderText="DD.MM.YYYY"
                  startDate={activityStartTime} // momentPropTypes.momentObj or null,
                  startDateId="activeStartDateField" // PropTypes.string.isRequired,
                  endDatePlaceholderText="DD.MM.YYYY"
                  minimumNights={1}
                  endDate={activityEndTime} // momentPropTypes.momentObj or null,
                  endDateId="activeEndDateField" // PropTypes.string.isRequired,
                  onDatesChange={values => this.setState({ activityStartTime: values.startDate, activityEndTime: values.endDate })} // PropTypes.func.isRequired,
                  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focus => this.setState({ focusedInput: focus })} // PropTypes.func.isRequired,
                  openDirection={OPEN_UP}
                  noBorder
                  required
                  numberOfMonths={1}
                  displayFormat="DD.MM.YYYY"
                  hideKeyboardShortcutsPanel
                />
              </div>
              <div className="add-entity-field">
                <Label text="Organisation" htmlFor="organisation-field" />
                <Input
                  fluid
                  type="text"
                  placeholder="organisation (optional)"
                  id="organisation-field"
                  name="organisation"
                  onChange={this.handleChange}
                  error={formErrors.organisation}
                />
              </div>
            </React.Fragment>
          ) : null }
          <div className="add-entity-create-button">
            <Button
              loading={submitting}
              variant="primary"
              type="submit"
              id="create-user-btn"
              disabled={this.isDisabled()}
            >Create a New User
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  clearErrors: usersActions.clearFormErrors,
}, dispatch));

const mapStateToProps = state => ({
  usernameConfig: state.configs.configs['username.pattern'],
  nameConfig: state.configs.configs['name.pattern'],
  error: state.users.addUserError,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
