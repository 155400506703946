import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'rtm-ui-components';
import TableCell from '../TableCell/TableCell';
import DetailsEntityPopup from '../../DetailsEntityPopup/DetailsEntityPopup';
import { User, Group, Bot } from '../../../../propTypes';
import '../TableRow/TableRow.css';

const getCells = (cells, uuid, icon, isPublic, privateIconColor, isAdmin) => (
  cells.map((cell) => {
    if (cell.key === 'name') {
      return (
        <TableCell customContent key={`${uuid}${cell.key}${cell.value}`}>
          <span>
            <Icon {...icon} size="medium" />
            {isPublic ? null : <span className="private-icon-table"><Icon width="12px" height="16px" name="private" color={privateIconColor} /></span>}
            {isAdmin ? <span className="admin-icon-table"><Icon width="12px" height="16px" name="solidAdmin" color="purple" /></span> : null }
            <span className="cell-value">{cell.value}</span>
          </span>
        </TableCell>
      );
    }
    return (
      <TableCell key={`${uuid}${cell.key}${cell.value}`} text={cell.value} />
    );
  })
);

const EditTableRow = ({
  link, icon, rowClass, cells, uuid, isPublic, privateIconColor, actions, details, isAdmin,
}) => (
  <Popup
    on="click"
    position="top"
    className="details-card-popup"
    yOffset={20}
    xOffset={12}
    trigger={(
      <div key={uuid} className={`${rowClass} table-row`}>
        {getCells(cells, uuid, icon, isPublic, privateIconColor, isAdmin)}
      </div>
    )}
  >
    <DetailsEntityPopup actions={actions} link={link} details={details} />
  </Popup>
);

EditTableRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      key: PropTypes.string,
    })
  ).isRequired,
  uuid: PropTypes.string,
  icon: PropTypes.shape({
    style: PropTypes.object,
    name: PropTypes.string,
  }),
  isPublic: PropTypes.bool,
  rowClass: PropTypes.string,
  isAdmin: PropTypes.bool,
  privateIconColor: PropTypes.oneOf(['red', 'grey', 'privateIconColor']),
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      requiredRole: PropTypes.string.isRequired,
      resource: PropTypes.oneOfType([PropTypes.shape(User), PropTypes.shape(Bot), PropTypes.shape(Group)]).isRequired,
    })
  ),
};

EditTableRow.defaultProps = {
  uuid: null,
  icon: null,
  isPublic: true,
  link: null,
  rowClass: '',
  privateIconColor: 'red',
  actions: [],
  isAdmin: false,
};

export default EditTableRow;
