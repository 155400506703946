import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'rtm-ui-components';
import { Redirect } from 'react-router-dom';
import './ErrorPage.css';
import history from '../../history';

class ErrorPage extends Component {
  state = {
    refreshed: false,
    redirectTo: '',
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('from')) {
      this.setState({ refreshed: true, redirectTo: urlParams.get('from') });
    }
    const { location } = this.props;
    const { from } = location.state;
    history.replace(`/error?from=${from.pathname}`, location.state);
  }

  render() {
    const { refreshed, redirectTo } = this.state;
    const { location } = this.props;
    const { errorMessage, errorLink, linkText } = location.state;
    return (
      refreshed
        ? <Redirect to={redirectTo} />
        : (
          <div className="error-page">
            <div className="error-wrapper">
              <Icon id="eficode-loading-logo" name="RTMWhite" />
              <div className="error-content">
                <p>{errorMessage}</p>
                {errorLink ? <a href={errorLink} className="error-link">{linkText}</a> : null}
              </div>
            </div>
          </div>
        )
    );
  }
}
const mapStateToProps = state => ({
  version: state.configs.publicConfigs['application.version'],
});

export default connect(mapStateToProps, null)(ErrorPage);
