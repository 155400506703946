import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'rtm-ui-components';
import Divider from '../../SharedComponents/Divider/Divider';
import './AddedEntityDetailsWrapper.css';

const extraInfo = (extras) => {
  const temp = [];
  extras.forEach((extra) => {
    temp.push(
      <div key={extra.key} className="extra">
        <div className="extra-title">{extra.title}</div>
        <div className="extra-content">{extra.value}</div>
      </div>);
  });
  return temp;
};

const AddedEntityDetailsWrapper = ({
  message, icon, name, description, visibility,
  extras, detailsLink, addToAction, addToIcon, addToText,
  backAction, backText, close, isPrivate,
}) => (
  <div className="add-entity-details">
    <div className="status-message">
      {message}
    </div>
    <div className="add-entity-entity-details-container">
      <div className="details">
        <Icon {...icon} size="medium" />
        {isPrivate
          ? (<div className="visibility-icon"><Icon name="private" color="primary" size="small" /></div>)
          : (null)}
        <div className="card-details">
          <div className="entity-name">{name}</div>
          <div className="more-info">{description}</div>
        </div>
        {visibility ? <div className="visibility">{visibility}</div> : null}
      </div>
      <div className="extra-info">
        {extraInfo(extras)}
      </div>
    </div>
    <Link to={{ pathname: detailsLink }}>
      <Button
        fluid
        variant="primary"
        type="submit"
        id="edit-entity-btn"
        onClick={close}
      >
        Edit
      </Button>
    </Link>
    <Divider>or</Divider>
    <div className="button-box" onClick={addToAction}>
      <Icon name={addToIcon} color="primary" size="medium" />
      <div className="button-box-text">{addToText}</div>
      <Icon name="arrowRight" />
    </div>
    <div className="button-box" onClick={backAction}>
      <Icon name="add" size="medium" color="primary" />
      <div className="button-box-text">{backText}</div>
      <Icon name="arrowRight" />
    </div>
  </div>
);

AddedEntityDetailsWrapper.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.string,
  visibility: PropTypes.string,
  extras: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  detailsLink: PropTypes.string,
  addToAction: PropTypes.func.isRequired,
  addToIcon: PropTypes.string,
  addToText: PropTypes.string,
  backAction: PropTypes.func.isRequired,
  backText: PropTypes.string,
  close: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool,
};

AddedEntityDetailsWrapper.defaultProps = {
  message: '',
  icon: '',
  name: '',
  description: '',
  visibility: '',
  extras: null,
  detailsLink: '',
  addToIcon: '',
  addToText: '',
  backText: '',
  isPrivate: false,
};

export default AddedEntityDetailsWrapper;
