import * as types from '../actions/actionTypes';

const initialState = {
  editViewStack: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.NAV_CLEAR_EDIT_VIEW: {
      return {
        ...state,
        editViewStack: [],
      };
    }
    case types.NAV_PUSH_EDIT_VIEW: {
      const newStack = [...state.editViewStack];
      newStack.push(action.payload);
      return {
        ...state,
        editViewStack: newStack,
      };
    }
    case types.NAV_POP_EDIT_VIEW: {
      const newStack = [...state.editViewStack];
      newStack.pop();
      return {
        ...state,
        editViewStack: newStack,
      };
    }
    case types.LOGOUT_FAILURE:
    case types.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
