export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const CLEAR_AUTH_MESSAGE = 'CLEAR_AUTH_MESSAGE';

export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_LINK_SUCCESS = 'RESET_PASSWORD_LINK_SUCCESS';
export const RESET_PASSWORD_LINK_FAILURE = 'RESET_PASSWORD_LINK_FAILURE';

export const USERS_LOADING = 'USER_LOADING';
export const USER_MEMBERSHIPS_LOADING = 'USER_MEMBERSHIPS_LOADING';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';
export const CLEAR_USERS_SUCCESS = 'CLEAR_GROUPS_SUCCESS';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const ADD_USER_TO_GROUP_FAILURE = 'ADD_USER_TO_GROUP_FAILURE';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const CLEAR_USER_SUCCESS = 'CLEAR_USER_SUCCESS';
export const UPDATE_SELF = 'UPDATE_SELF';
export const EDITING_USER = 'EDITING_USER';
export const EDITING_USER_ERROR = 'EDITING_USER_ERROR';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';

export const BOTS_LOADING = 'BOTS_LOADING';
export const LOAD_BOTS_SUCCESS = 'LOAD_BOTS_SUCCESS';
export const LOAD_BOTS_FAILURE = 'LOAD_BOTS_FAILURE';
export const LOAD_USER_BOTS_SUCCESS = 'LOAD_USER_BOTS_SUCCESS';
export const SEARCH_BOTS_SUCCESS = 'SEARCH_BOTS_SUCCESS';
export const SEARCH_BOTS_FAILURE = 'SEARCH_BOTS_FAILURE';
export const CLEAR_BOTS_SUCCESS = 'CLEAR_BOTS_SUCCESS';
export const ADD_BOTS_SUCCESS = 'ADD_BOTS_SUCCESS';
export const ADD_BOTS_FAILURE = 'ADD_BOTS_FAILURE';
export const UPDATE_BOTS_SUCCESS = 'UPDATE_BOTS_SUCCESS';
export const LOAD_BOT_SUCCESS = 'LOAD_BOT_SUCCESS';
export const SHOW_BOT_PASSWORD_SUCCESS = 'SHOW_BOT_PASSWORD_SUCCESS';
export const HIDE_BOT_PASSWORD_SUCCESS = 'HIDE_BOT_PASSWORD_SUCCESS';
export const CLEAR_BOT_SUCCESS = 'CLEAR_BOT_SUCCESS';
export const BOT_ERROR = 'BOT_ERROR';
export const CLEAR_BOT_ERROR = 'CLEAR_BOT_ERROR';
export const BOT_MEMBERS_LOADING = 'BOT_MEMBERS_LOADING';

export const GET_GROUP_STARTED = 'GET_GROUP_STARTED';
export const GROUPS_LOADING = 'GROUP_LOADING';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_FAILURE = 'LOAD_GROUP_FAILURE';
export const LOAD_USER_GROUPS_SUCCESS = 'LOAD_USER_GROUPS_SUCCESS';
export const SEARCH_GROUPS_SUCCESS = 'SEARCH_GROUPS_SUCCESS';
export const SEARCH_GROUPS_FAILURE = 'SEARCH_GROUPS_FAILURE';
export const SEARCH_GROUPS_STARTED = 'SEARCH_GROUPS_STARTED';
export const CLEAR_GROUPS_SUCCESS = 'CLEAR_GROUPS_SUCCESS';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const LOAD_GROUP_SUCCESS = 'LOAD_GROUP_SUCCESS';
export const CLEAR_GROUP_SUCCESS = 'CLEAR_GROUP_SUCCESS';
export const CLEAR_GROUPS_ERROR = 'CLEAR_GROUPS_ERROR';
export const GROUP_MEMBERS_LOADING = 'GROUP_MEMBERS_LOADING';

export const PASSWORD_CONFIG_SUCCESS = 'PASSWORD_CONFIG_SUCCESS';
export const USERNAME_CONFIG_SUCCESS = 'USERNAME_CONFIG_SUCCESS';
export const SAML_ENABLED_CONFIG_SUCCESS = 'SAML_ENABLED_CONFIG_SUCCESS';
export const SAML_URL_CONFIG_SUCCESS = 'SAML_URL_CONFIG_SUCCESS';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const LOAD_AUDITLOGS_SUCCESS = 'LOAD_AUDITLOGS_SUCCESS';
export const LOAD_AUDITLOGS_FAILURE = 'LOAD_AUDITLOGS_FAILURE';
export const SEARCH_AUDITLOGS_SUCCESS = 'SEARCH_AUDITLOGS_SUCCESS';
export const SEARCH_AUDITLOGS_FAILURE = 'SEARCH_AUDITLOGS_FAILURE';
export const AUDITLOGS_LOADING = 'AUDITLOGS_LOADING';
export const LOAD_AUDITLOGOPERATIONS_SUCCESS = 'LOAD_AUDITLOGOPERATIONS_SUCCESS';
export const LOAD_AUDITLOGOPERATIONS_FAILURE = 'LOAD_AUDITLOGOPERATIONS_FAILURE';
export const CLEAR_AUDIT_EVENTS = 'CLEAR_AUDIT_EVENTS';
export const GET_CONFIGS = 'GET_CONFIGS';
export const GET_PUBLIC_CONFIGS = 'GET_PUBLIC_CONFIGS';


// Navigation
export const NAV_PUSH_EDIT_VIEW = 'NAV_PUSH_EDIT_VIEW';
export const NAV_POP_EDIT_VIEW = 'NAV_POP_EDIT_VIEW';
export const NAV_CLEAR_EDIT_VIEW = 'NAV_CLEAR_EDIT_VIEW';

// Notifications
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_NOTIFICATION_SUCCESS = 'SET_NOTIFICATION_SUCCESS';
export const SET_NOTIFICATION_FAILURE = 'SET_NOTIFICATION_FAILURE';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
