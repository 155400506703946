import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'rtm-ui-components';
import PropTypes from 'prop-types';
import HasRole from '../../../roles/HasRole';
import { User, Group, Bot } from '../../../propTypes';
import * as navigationActions from '../../../store/actions/navigation';
import './DetailsEntityPopup.css';

class DetailsEntityPopup extends Component {
  render() {
    const {
      details, link, actions, pushEditView, location,
    } = this.props;
    return (
      <div className="card-details-container">
        <div className="card-details">
          {details.map(detail => (
            <div className="card-detail" key={detail.title}>
              <div className="card-detail-title">{detail.title}</div>
              <div className="card-detail-value">{detail.value}</div>
            </div>
          ))}
        </div>
        <div className="card-details-actions">
          {link ? (
            <Link
              to={{
                pathname: link.target,
              }}
              onClick={() => { pushEditView(location.pathname); }}
            >
              <Button
                id={link.id}
                variant="secondary"
              >{link.title}
              </Button>
            </Link>
          )
            : null}
          {actions.map(action => (
            <HasRole key={action.title} requiredRole={action.requiredRole} resource={action.resource}>
              <Button
                id={action.id}
                variant="secondary"
                onClick={action.onClick}
              >
                {action.title}
              </Button>
            </HasRole>
          ))}
        </div>
      </div>
    );
  }
}

DetailsEntityPopup.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      requiredRole: PropTypes.string.isRequired,
      resource: PropTypes.oneOfType([PropTypes.shape(User), PropTypes.shape(Bot), PropTypes.shape(Group)]).isRequired,
    })
  ),
};
DetailsEntityPopup.defaultProps = {
  link: null,
  actions: [],
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  pushEditView: navigationActions.pushEditView,
}, dispatch));

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsEntityPopup));
