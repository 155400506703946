import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'rtm-ui-components';
import { convertISODate } from '../../../utils.js';
import HasRole from '../../../roles/HasRole';
import DetailRow from './DetailRow/DetailRow';
import './DetailsInfoRow.css';
import { showPassword } from '../../../store/actions/bots.js';

const DetailsInfoRow = ({
  group, user, username, role, unlockUser, bot, status, password, togglePassword, passwordIcon, copyPassword, copyPasswordText
}) => (
  <div className="details-info-row">
    <div className="details-row">
      { group ? (
        <div className="row">
          <DetailRow id="col-group-id" label="Group ID" content={group.name} />
          <DetailRow id="col-visibility-id" label="Visibility" content={group.isPublic ? 'Public' : 'Private'} />
          <DetailRow id="col-description-id" label="Description" content={group.description} />
          <DetailRow label="Source" content={group.source} />
          <DetailRow id="col-created-id" label="Created" content={convertISODate(group.created)} />
        </div>
      ) : null }
      { user ? (
        <div className="row">
          <DetailRow label="Username" content={username} />
          <DetailRow label="Role" content={role} />
          <DetailRow label="Source" content={user.source} />
          {!user.active || user.locked
            ? (
              <DetailRow
                label="Status" content={!user.active ? 'Inactive'
                  : <div className="locked">Locked <HasRole requiredRole="admin"><div id="unlock-user-button" onClick={() => unlockUser(user.uuid)} className="unlock-button"><Icon name="lock" color="red" size="small" /></div></HasRole></div>
                }
              />
            )
            : null
          }
          <DetailRow label="Email" content={user.email} />
          { user.appRole === 'ext'
            ? (
              <DetailRow
                label="Activity Period"
                content={`${moment(user.activityStartTime).format('DD.MM.YYYY')} - ${moment(user.activityEndTime).format('DD.MM.YYYY')}`}
              />
            )
            : null
          }
          { user.appRole === 'ext' && user.organisation
            ? <DetailRow label="Organisation" content={user.organisation} />
            : null
          }
        </div>
      ) : null}
      { bot ? (
        <div className="row">
          <DetailRow label="Role" content={role} />
          {status}
          <DetailRow label="Visibility" content={bot.isPublic ? 'Public' : 'Private'} />
          <DetailRow label="Email" content={bot.email} />
          <DetailRow label="Source" content={bot.source} />
          <DetailRow label="Created" content={convertISODate(bot.created)} />
          {bot.password
            ? (
              <HasRole requiredRole="botPassword" resource={bot}>
                  <DetailRow
                  id="password"
                  icon={<Icon size="tiny" actionable onClick={togglePassword} name={passwordIcon} />}
                  icon2={<button style={ { display:"inline-flex" , flexDirection:"column", backgroundColor:"transparent", border:"none", alignItems:"center", cursor: "pointer"}} onClick={copyPassword} type='button'><Icon size="tiny" name="copy" />{copyPasswordText}</button> }
                  label="Password"
                  content={password}
                  />
              </HasRole>
            ) : null}
        </div>
      ) : null}
    </div>
  </div>
);

DetailsInfoRow.getBotPasswordRow = () =>{
  if(showPassword){

  }else{
    
  }
}

DetailsInfoRow.propTypes = {
  group: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  user: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  bot: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  username: PropTypes.string,
  role: PropTypes.string,
  unlockUser: PropTypes.func,
  status: PropTypes.string,
  password: PropTypes.string,
  togglePassword: PropTypes.func,
  passwordIcon: PropTypes.oneOf(['showPassword', 'hidePassword']),
};

DetailsInfoRow.defaultProps = {
  group: null,
  user: null,
  bot: null,
  username: '',
  role: '',
  unlockUser: null,
  status: '',
  password: '',
  togglePassword: null,
  passwordIcon: 'showPassword',
};

export default DetailsInfoRow;
