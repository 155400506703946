/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Input, Checkbox, Label, TextArea, Popup, Icon,
} from 'rtm-ui-components';
import Message from '../../SharedComponents/Message/Message';
import * as groupsActions from '../../../store/actions/groups';
import { invalidName, invalidGroupShortname } from '../../../utils';
import '../../../assets/stylesheets/AddEntityForm.css';

class AddGroupForm extends Component {
  state = {
    submitting: false,
    longname: '',
    name: '',
    description: '',
    isPublic: false,
    touched: {
      name: false,
      longname: false,
    },
    formErrors: {
      name: false,
      longname: false,
    },
  }

  componentDidMount() {
    const { clearErrors } = this.props;
    clearErrors();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      longname, name, description, isPublic,
    } = this.state;
    const { onSubmit, addGroup } = this.props;
    this.setState({ submitting: true });

    const submittedDetails = {
      longname,
      name,
      description,
      isPublic,
    };
    try {
      await addGroup(submittedDetails);
      const { error } = this.props;
      if (!error) {
        await onSubmit(submittedDetails);
      }
    // eslint-disable-next-line no-empty
    } catch (err) {}
    this.setState({ submitting: false });
  }

  toggleIsPublic = () => {
    const { isPublic } = this.state;
    this.setState({
      isPublic: !isPublic,
    });
  }

  handleChange = (event) => {
    event.preventDefault();
    let groupName = '';
    let groupID = '';
    const { name, value } = event.target;
    const { formErrors, touched } = this.state;
    const { groupNameConfig, nameConfig } = this.props;
    switch (name) {
      case 'name':
        if (invalidGroupShortname(value, groupNameConfig) || !value) {
          formErrors.name = true;
        } else {
          formErrors.name = false;
        }
        touched.name = true;
        break;
      case 'longname':
        if (invalidName(value, nameConfig) || !value) {
          formErrors.longname = true;
        } else {
          formErrors.longname = false;
        }
        groupName = value;
        groupID = value.replace(/\s/g, '-').normalize('NFD').replace(/[^a-zA-Z0-9-_.]/gi, '');
        this.setState({
          formErrors,
          touched,
          name: groupID,
          longname: groupName,
        });
        if (invalidGroupShortname(groupID, groupNameConfig) || !groupID) {
          formErrors.name = true;
        } else {
          formErrors.name = false;
        }
        touched.longname = true;
        touched.name = true;
        break;
      case 'description':
        formErrors.description = '';
        touched.description = true;
        break;
      default:
        break;
    }
    this.setState({ formErrors, touched, [name]: value });
  }

  isDisabled = () => {
    const {
      formErrors, touched,
    } = this.state;
    let error = false;
    Object.keys(formErrors).forEach((key) => {
      if (formErrors[key]) {
        error = true;
      }
    });
    Object.keys(touched).forEach((key) => {
      if (!touched[key]) {
        error = true;
      }
    });
    return error;
  }

  render() {
    const {
      error, nameConfig, groupNameConfig,
    } = this.props;
    const {
      submitting, formErrors, description, name, longname,
    } = this.state;
    return (
      <div className="add-entity-view">
        {error ? (
          <div className="form-message-container">
            <Message negative header={error} />
          </div>
        ) : null
        }
        <form id="add-group-form" onSubmit={this.handleSubmit} className="add-entity-view-form">
          <div className="add-entity-field">
            <Label htmlFor="longname" text="Group Name" info={nameConfig && nameConfig.description} />
            <Input
              fluid
              name="longname"
              type="text"
              placeholder="Group Name"
              id="longname-field"
              value={longname}
              onChange={e => this.handleChange(e)}
              error={formErrors.longname}
            />
          </div>
          <div className="add-entity-field">
            <Label htmlFor="name" text="Group ID" info={groupNameConfig && groupNameConfig.description} />
            <Input
              fluid
              name="name"
              type="text"
              placeholder="Group ID"
              id="name-field"
              value={name}
              onChange={e => this.handleChange(e)}
              error={formErrors.name}
            />
          </div>
          <div className="add-entity-field">
            <Label htmlFor="description-field" text="Description" />
            <TextArea
              fluid
              name="description"
              id="description-field"
              rows="5"
              onChange={(e => this.handleChange(e))}
              value={description}
              error={formErrors.description}
              placeholder="Description of Group"
            />
          </div>
          <div className="add-entity-field">
            <div className="is-public-checkbox">
              <Checkbox
                id="is-public"
                onChange={this.toggleIsPublic}
                name="isPublic"
                label={(
                  <div className="group-visibility-icon" style={{ display: 'flex' }}>
                    Public
                    <Popup
                      on="hover"
                      content="Public groups are visible to non-members"
                      trigger={(<Icon style={{ marginLeft: '10px' }} width="13px" height="13px" color="primary" name="info" ariaLabel="info" />)}
                      xOffset={-6}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="add-entity-create-button">
            <Button
              variant="primary"
              loading={submitting}
              type="submit"
              id="create-group-btn"
              disabled={this.isDisabled()}
            >
              Create a New Group
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  addGroup: groupsActions.addGroup,
  clearErrors: groupsActions.clearFormErrors,
}, dispatch));

const mapStateToProps = state => ({
  nameConfig: state.configs.configs['grouplongname.pattern'],
  groupNameConfig: state.configs.configs['groupname.pattern'],
  error: state.groups.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupForm);
