import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Notification } from 'rtm-ui-components';
import HasRole from '../../../roles/HasRole';
import { icon } from '../../../utils';
import {
  User, Bot, Group,
} from '../../../propTypes';
import './DetailsTopRow.css';

class DetailsTopRow extends Component {
  state = { openConfirm: false }

  onConfirm = async () => {
    const {
      deactivate, closeModal, user, group, bot, deleteGroup,
    } = this.props;
    if (user) {
      await deactivate(user);
    } else if (group) {
      await deleteGroup(group);
    } else if (bot) {
      await deactivate(bot);
    }
    this.toggleConfirmBlock();
    closeModal();
  }

  toggleConfirmBlock = () => {
    const { openConfirm } = this.state;
    this.setState({ openConfirm: !openConfirm });
  }

  userActivateButton = (user) => {
    const { activate } = this.props;
    return (
      user.active
        ? (
          <HasRole requiredRole="admin">
            <span className="top-row-span" onClick={() => { this.toggleConfirmBlock(); }}>
              <Icon name="deactivate" size="small" id="deactivate-btn" actionable /> Deactivate
            </span>
          </HasRole>
        )
        : (
          <HasRole requiredRole="admin">
            <span className="top-row-span" onClick={async () => { await activate(user); }}>
              <Icon name="activate" size="small" id="activate-btn" actionable /> Activate
            </span>
          </HasRole>
        )
    );
  }

  botActivateButton = (bot) => {
    const { activate } = this.props;
    return (
      bot.active ? (
        <HasRole requiredRole="botEdit" resource={bot}>
          <span className="top-row-span" onClick={() => { this.toggleConfirmBlock(); }}>
            <Icon name="deactivate" size="small" id="deactivate-btn" actionable /> Deactivate
          </span>
        </HasRole>
      )
        : (
          <HasRole requiredRole="botEdit" resource={bot}>
            <span className="top-row-span" onClick={async () => { await activate(bot); }}>
              <Icon name="activate" size="small" id="activate-btn" actionable /> Activate
            </span>
          </HasRole>
        )
    );
  }

  editButton = (requiredRole, entity) => {
    const { toggleEditBlock } = this.props;
    return (
      <HasRole requiredRole={requiredRole} resource={entity}>
        <span className="top-row-span" onClick={toggleEditBlock}>
          <Icon className="top-row-icon" name="edit" size="small" color="primary" id="expand-edit-form-btn" actionable /> Edit
        </span>
      </HasRole>
    );
  }

  nameClass = (entity) => {
    if (!entity.active) {
      return 'name inactive';
    } if (entity.locked) {
      return 'name locked';
    }
    return 'name';
  }

  render() {
    const { openConfirm } = this.state;
    const {
      closeModal,
      bot,
      user, togglePasswordBlock,
      group, back,
    } = this.props;
    let confirmText;
    let confirmButtonText = 'Deactivate';
    if (user) {
      confirmText = 'Are you sure you want to deactivate this user?';
    } else if (group) {
      confirmText = 'Are you sure you want to delete this group?';
      confirmButtonText = 'Delete';
    } else if (bot) {
      confirmText = 'Are you sure you want to deactivate this bot?';
    }
    return (
      <div className={(bot && bot.active && bot.locked) || (user && user.active && user.locked) ? 'top-row locked' : 'top-row'}>
        {user ? <Icon height="40px" width="40px" {...icon(user)} /> : null}
        {group ? <Icon height="40px" width="40px" name="group" color="primary" /> : null}
        {bot ? <Icon height="40px" width="40px" {...icon(bot)} /> : null}
        {bot && !bot.isPublic ? <div className="visibility-icon"><Icon name="private" height="18px" width="18px" color={bot.enabled && !bot.locked ? 'red' : 'dark-grey'} /></div> : null}
        {group && !group.isPublic ? <div className="visibility-icon"><Icon name="private" height="18px" width="18px" color="red" /></div> : null}

        {user ? <div className={this.nameClass(user)}>{user.firstName} {user.lastName}</div> : null}
        {group ? <div className="name">{group.longname}</div> : null}
        {bot ? <div className={this.nameClass(bot)}>{bot.username}</div> : null}

        {user ? (
          <HasRole requiredRole="userPasswordEdit" resource={user}>
            <span className="top-row-span" onClick={togglePasswordBlock}>
              <Icon name="passwordChange" size="small" id="password-change-btn" actionable /> Change password
            </span>
          </HasRole>
        ) : null}

        {user ? this.editButton('userEdit', user) : null}
        {group ? this.editButton('groupEdit', group) : null}
        {bot ? this.editButton('botEdit', bot) : null}

        {user ? this.userActivateButton(user) : null}
        {group ? (
          <HasRole requiredRole="groupEdit" resource={group}>
            <span className="top-row-span" onClick={() => { this.toggleConfirmBlock(); }}>
              <Icon name="delete" color="primary" size="small" id="remove-btn" actionable /> Delete
            </span>
          </HasRole>
        ) : null}
        {bot ? this.botActivateButton(bot) : null}
        <div className="close">
          {back ? <Icon onClick={back} color="primary" name="arrowLeft" id="back-modal-btn" size="small" actionable /> : null}
          <Icon onClick={closeModal} name="cancel" id="close-modal-btn" size="small" actionable />
        </div>
        <Notification
          open={openConfirm}
          onCancel={this.toggleConfirmBlock}
          size="small"
          id="deactivate-notification"
          header="Confirm"
          cancelButton="Cancel"
          confirmButton={confirmButtonText}
          onConfirm={async () => { await this.onConfirm(); }}
        >
          {confirmText}
        </Notification>
      </div>
    );
  }
}

DetailsTopRow.propTypes = {
  closeModal: PropTypes.func.isRequired,
  bot: PropTypes.shape(Bot),
  user: PropTypes.shape(User),
  group: PropTypes.shape(Group),
  togglePasswordBlock: PropTypes.func,
};

DetailsTopRow.defaultProps = {
  bot: null,
  user: null,
  group: null,
  togglePasswordBlock: undefined,
};

export default DetailsTopRow;
