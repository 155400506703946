/* eslint-disable react/destructuring-assignment */

import { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ADMIN, SYSADMIN, USER, EXTERNALUSER,
} from './roles';

class HasRole extends Component {
  static propTypes = {
    currentUserRole: propTypes.string.isRequired,
    requiredRole: propTypes.string.isRequired,
  }

  isAdmin = () => this.props.currentUserRole === ADMIN

  isSysAdmin = () => this.props.currentUserRole === SYSADMIN

  isExternalUser = () => this.props.currentUserRole === EXTERNALUSER

  isNotExternalUser = () => !this.isExternalUser();

  isUser = () => !this.isAdmin() && !this.isSysAdmin() && !this.isExternalUser();

  hasNoSysAdminPrivilege = () => this.isUser() || this.isAdmin() || this.isExternalUser();


  isSelf = user => user.uuid === this.props.currentUser.uuid

  isGroupAdmin = group => this.props.currentUserGroups
    .filter(_group => _group.role.name === 'admin')
    .map(_group => _group.group && _group.group.uuid)
    .includes(group.uuid)

  isBotAdmin = bot => this.props.currentUserBots
    .filter(_bot => _bot.role.name === 'admin')
    .map(_bot => _bot.user && _bot.user.uuid)
    .includes(bot.uuid)

  isBotMember = bot => this.props.currentUserBots
    .filter(_bot => _bot.role.name === 'user')
    .map(_bot => _bot.user && _bot.user.uuid)
    .includes(bot.uuid)


  // user permissions
  hasUserViewRight = user => this.isUser() && !this.isSelf(user)

  hasUserEditRight = user => (this.hasAdminPrivilege() || this.isSelf(user)) && !this.isExternalUser();

  hasUserPasswordEditRight = user => this.isSelf(user)

  // bot permissions
  hasBotEditRight = bot => this.isBotAdmin(bot) || this.hasAdminPrivilege()

  hasBotReadOnlyRight = bot => !this.isBotAdmin(bot) && (this.isUser() || this.isExternalUser())

  hasBotPasswordRight = bot => this.hasAdminPrivilege() || this.isBotMember(bot) || this.isBotAdmin(bot);

  // group permissions
  hasGroupReadOnlyRight = group => !this.isGroupAdmin(group) && (this.isUser() || this.isExternalUser());

  hasGroupEditRight = group => this.isGroupAdmin(group) || this.hasAdminPrivilege();

  hasGroupNameEditRight = () => this.isSysAdmin()

  // admin permissions
  hasAdminPrivilege = () => this.isAdmin() || this.isSysAdmin()

  isAllowed = () => {
    const { requiredRole, resource } = this.props; // resource: a bot / a group
    // substitute for switch cases
    const roleConfig = {
      any: () => (true),
      userEdit: this.hasUserEditRight,
      userView: this.hasUserViewRight,
      userPasswordEdit: this.hasUserPasswordEditRight,

      botView: this.hasBotReadOnlyRight,
      botPassword: this.hasBotPasswordRight,
      botEdit: this.hasBotEditRight,

      groupView: this.hasGroupReadOnlyRight,
      groupEdit: this.hasGroupEditRight,
      groupNameEdit: this.hasGroupNameEditRight,

      sysAdmin: this.isSysAdmin,
      admin: this.hasAdminPrivilege,
      user: this.isUser,
      nonSysAdmin: this.hasNoSysAdminPrivilege,
      externalUser: this.isExternalUser,
      isNotExternalUser: this.isNotExternalUser,
    };

    const matchingRole = roleConfig[requiredRole];

    return matchingRole ? matchingRole(resource) : false;
  }

  // render the children only if user has permission
  render() {
    return this.isAllowed() ? this.props.children : null;
  }
}

const mapStateToProps = (extendWith = {}) => state => ({
  currentUser: state.auth.user,
  currentUserRole: state.auth.user && state.auth.user.appRole ? state.auth.user.appRole : USER,
  currentUserGroups: state.auth.user.groups || [],
  currentUserBots: state.auth.user.bots || [],
  ...extendWith,
});

export default connect(mapStateToProps())(HasRole);
