/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Search } from 'rtm-ui-components';
import Tag from '../../../../Wrappers/Tag/Tag';
import * as botsActions from '../../../../../store/actions/bots';
import { paginationLimit } from '../../../../../constants';


class AddUserBots extends Component {
  state = {
    selectedBots: [],
    name: '',
    loading: false,
  }

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ name: '' });
  };

  handleResultSelect = (result) => {
    const { selectedBots } = this.state;
    const tempBots = [...selectedBots];
    tempBots.push(result);
    this.setState({ selectedBots: tempBots });
    this.resetComponent();
  }

  handleSearchChange = async (event) => {
    await this.setState({ name: event.target.value });
    if (event.target.value.length < 1) {
      this.resetComponent();
    } else {
      this.searchBots();
    }
  }

  searchBots = () => {
    const {
      name,
    } = this.state;
    const { getBots } = this.props;
    const params = {
      name: name || '',
      page: 0,
      limit: paginationLimit,
    };
    getBots(params);
  }

  handleSubmit = async () => {
    const { selectedBots } = this.state;
    const { addAction, selectedUser, addUserBot } = this.props;
    this.setState({ loading: true });
    const promises = selectedBots.map(bot => addUserBot(selectedUser, bot));
    this.setState({ name: '', selectedBots: [] });
    await Promise.all(promises);
    this.setState({ loading: false });
    addAction();
  }

  removeSelectedBot = (index) => {
    const { selectedBots } = this.state;
    const tempBots = [...selectedBots];
    tempBots.splice(index, 1);
    this.setState({ selectedBots: tempBots });
  }

  renderBot = (bot, index) => (
    <Tag key={bot.uuid} onDelete={() => this.removeSelectedBot(index)} text={`${bot.username}`} />
  );

  render() {
    const {
      selectedUser, searchResults, isSearching,
    } = this.props;
    const { selectedBots, loading } = this.state;
    const addedBots = selectedUser.bots.map(({ user }) => user);
    const addedSelectedBots = [...addedBots, ...selectedBots].map(b => b.uuid);
    //let results = _.differenceWith(searchResults, [...addedBots, ...selectedBots], (b1, b2) => b1.uuid === b2.uuid);
    const results = searchResults.map(b => ({
      key: b.uuid,
      id: `${b.username}-result`,
      uuid: b.uuid,
      username: b.username,
      text: b.username,
    }));

    const selectedBotsWrapper = selectedBots && selectedBots.map(this.renderBot);
    return (
      <div className="add-entity-user add-groups">
        <Search
          fluid
          loading={isSearching}
          id="add-bots-to-user"
          placeholder="Search Bots"
          debounceTimer={300}
          onChange={(e) => { this.handleSearchChange(e); }}
          results={results}
          onResultSelect={this.handleResultSelect}
          disabledKeys={addedSelectedBots}
        />
        {selectedBotsWrapper && (<div className="tags">{selectedBotsWrapper}</div>)}
        <div className="btn-container">
          <Button
            loading={loading}
            variant="primary"
            id="add-entity-btn"
            onClick={this.handleSubmit}
            disabled={!selectedBots || selectedBots.length === 0}
          >Add
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.bots.bots.bots,
  isSearching: state.bots.isSearching,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  addUserBot: botsActions.addUserBot,
  searchBot: botsActions.searchBot,
  getBots: botsActions.getBots,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AddUserBots);
