import React from 'react';
import PropTypes from 'prop-types';
import './Message.css';

const getClassName = (className, error, positive, negative) => {
  const classes = ['message-box'];
  if (className) {
    classes.push(className);
  }
  if (error) {
    classes.push('message-error');
  }
  if (positive) {
    classes.push('message-positive');
  }
  if (negative) {
    classes.push('message-negative');
  }
  return classes.join(' ');
};

const Message = ({
  children, error, header, className, positive, negative,
}) => (
  <div className={getClassName(className, error, positive, negative)}>
    {header ? <div className="message-header">{header}</div> : null}
    {children ? <div className="message-content">{children}</div> : null }
  </div>
);

Message.defaultProps = {
  error: false,
  positive: false,
  negative: false,
  header: null,
  className: '',
  children: null,
};
Message.propTypes = {
  error: PropTypes.bool,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  header: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};
export default Message;
