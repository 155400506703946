import * as types from './actionTypes';
import { openModal } from './modals';
import { createUUID, joinWithAnd } from '../../utils';

const loadUsersSuccess = users => ({ type: types.LOAD_USERS_SUCCESS, payload: users });
const loadUsersFailure = error => ({ type: types.LOAD_USERS_FAILURE, payload: error });

const loadingUsers = () => ({ type: types.USERS_LOADING });
const loadingUserMemberships = () => ({ type: types.USER_MEMBERSHIPS_LOADING });

const addUserFailure = error => ({ type: types.ADD_USER_FAILURE, payload: error });
const clearUserErrors = () => ({ type: types.CLEAR_USER_ERRORS });
const addUserSuccess = user => ({ type: types.ADD_USER_SUCCESS, payload: user });

const clearUsersSuccess = () => ({ type: types.CLEAR_USERS_SUCCESS });
const getErrorMessage = error => ((error.response) ? error.response.dataMessage : error.message);

const addUserToGroupFailure = error => ({ type: types.ADD_USER_TO_GROUP_FAILURE, payload: error });

const updatePasswordSuccess = message => ({ type: types.UPDATE_PASSWORD_SUCCESS, payload: message });
const editingUser = () => ({ type: types.EDITING_USER });
const editingUserError = error => ({ type: types.EDITING_USER_ERROR, payload: error });

const loadUser = user => ({ type: types.LOAD_USER_SUCCESS, payload: user });
const updateSelf = user => ({ type: types.UPDATE_SELF, payload: user });

export const clearUsers = () => (dispatch) => {
  dispatch(clearUsersSuccess());
};
export const clearFormErrors = () => (dispatch) => {
  dispatch(clearUserErrors());
};
const parseFetchResponse = response => response.json().then(text => ({
  json: text,
  meta: response,
}));

const fetchUserDetails = async (userId, api, getState) => {
  const { selectedUser } = getState().users;
  const user = await api.get(`/users/${userId}`);
  if (!selectedUser) {
    user.groups = [];
    user.bots = [];
  }
  return { ...selectedUser, organisation: '', ...user };
};

export const getSelectedUserGroups = () => async (dispatch, getState, api) => {
  try {
    dispatch(loadingUserMemberships());
    const { selectedUser } = getState().users;
    if(selectedUser){
      selectedUser.deniedPolicies = await api.get(`/users/updatePolicies/${selectedUser.uuid}`);
    }
    await localStorage.setItem('deniedPolicies', JSON.stringify({
      deniedPolicies: selectedUser.deniedPolicies,
    }));
    const allGroups = await api.get(`/users/${selectedUser.uuid}/groups?nested=true`);
    const onlyDirectMemberships = await api.get(`/users/${selectedUser.uuid}/groups?nested=false`);
    allGroups.forEach((group) => {
      const index = onlyDirectMemberships.findIndex(directGroup => directGroup.group.uuid === group.group.uuid);
      if (index < 0) {
        // eslint-disable-next-line no-param-reassign
        group.isParentGroup = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        group.isParentGroup = false;
      }
      // eslint-disable-next-line no-param-reassign
      group.childGroups = [];
    });
    allGroups.forEach((group) => {
      if (group.groupParentNames && group.groupParentNames.length) {
        group.groupParentNames.forEach((parentGroupName) => {
          const parentGroup = allGroups.find(childGroupFinding => childGroupFinding.group.name === parentGroupName);
          if (parentGroup) {
            parentGroup.childGroups.push(group.group.name);
          }
        });
      }
    });
    // This is checking if the selected user view is closed before the user is loaded -> if not don't go through
    if (getState().users.selectedUser) {
      dispatch(loadUser({ ...selectedUser, groups: allGroups }));
    }
  } catch (error) {
    dispatch(loadUsersFailure(getErrorMessage(error)));
  }
};

export const getSelectedUserBots = () => async (dispatch, getState, api) => {
  try {
    dispatch(loadingUserMemberships());
    const { selectedUser } = getState().users;
    const bots = await api.get(`/users/${selectedUser.uuid}/bots`);
    if (getState().users.selectedUser) {
      dispatch(loadUser({ ...selectedUser, bots }));
    }
  } catch (error) {
    dispatch(loadUsersFailure(getErrorMessage(error)));
  }
};

export const loadUserDetail = userId => async (dispatch, getState, api) => {
  try {
    dispatch(loadingUsers());
    const user = await fetchUserDetails(userId, api, getState);
    dispatch(loadUser(user));
  } catch (error) {
    dispatch(loadUsersFailure(getErrorMessage(error)));
  }
};

export const getUsers = params => async (dispatch, getState, api) => {
  try {
    dispatch(loadingUsers());
    const urlParams = new URLSearchParams({ ...params });
    const users = await api.get(`/users?${urlParams}`);
    dispatch(loadUsersSuccess(users));
  } catch (error) {
    dispatch(loadUsersFailure(getErrorMessage(error)));
  }
};

export const addUser = data => async (dispatch, getState, api) => {
  try {
    dispatch(clearUserErrors());
    const input = data;
    if (input.external) {
      input.appRole = 'ext';
    }
    delete input.external;

    const response = await api.postbody('/users', { ...input, isBot: false });

    const { json, meta } = await parseFetchResponse(response);
    if (meta.status === 201) {
      const groups = await api.get(`/users/${json.uuid}/groups`);
      dispatch(addUserSuccess({ ...json, groups }));
    } else {
      dispatch(addUserFailure(json.message));
      throw json.message;
    }
  } catch (error) {
    throw error;
  }
};

// export const updateUserRole = (user, newRole) => async (dispatch, getState, api) => {
const updateUserRole = async (data, prevRole, api) => {
  if (prevRole === data.role) {
    return false;
  }
  const roles = await api.get('/roles');
  const userRole = roles.find(role => (role.type === 'USER' || role.type === 'EXT_USER') && role.name === data.role);
  const response = await api.put(`/users/${data.uuid}/roles/${userRole.id}`);
  return response;
};

export const updatePassword = data => async (dispatch, getState, api) => {
  try {
    dispatch(editingUser());
    
    const passwords = {
      oldpass: data.oldpassword,
      newpass: data.password
    };

    const pwdResponse = await api.postbody('/users/password', passwords, false); // { password: data.password }

    if (pwdResponse.status === 200) {
      dispatch(updatePasswordSuccess('Password update succeeded'));
    } else {
      throw Error('Failed to update password.');
    }
  } catch (error) {
    dispatch(editingUserError(error.message));
  }
};

const getUpdateError = (response, roleResponse) => {
  const errorVals = [];
  if (!response.ok) {
    errorVals.push('User Details');
  }
  if (roleResponse && !roleResponse.ok) {
    errorVals.push('User Role');
  }

  return errorVals.length ? `Updating ${joinWithAnd(errorVals)} Failed.` : null;
};

export const updateUser = data => async (dispatch, getState, api) => {
  try {
    dispatch(clearUserErrors());
    const prevRole = getState().users.selectedUser.appRole;
    const response = await api.put(`/users/${data.uuid}`, { ...data, isBot: false });
    if (data.uuid === getState().auth.user.uuid && data.username !== getState().auth.user.username) {
      const id = createUUID();
      dispatch(openModal({ // Dispatch action
        id, // from utils
        type: 'Redirect', // Need to make in components/modals/Notifications.jsx
        title: 'Redirecting',
        text: 'Username changed. Please login again.', // error message
        // onClose: () => history.push('/logout'), // Fire at close event
        // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
      }));
      return;
    }

    // const passwordResponse = await updatePassword(data.password, api);
    const roleResponse = await updateUserRole(data, prevRole, api);

    const errorMessage = getUpdateError(response, roleResponse);
    if (errorMessage) {
      throw Error(errorMessage);
    }
    const user = await fetchUserDetails(data.uuid, api, getState);
    dispatch(loadUser(user));
    dispatch(addUserSuccess('User Updated Successfully.'));
    // is self?
    if (data.uuid === getState().auth.user.uuid) {
      dispatch(updateSelf(user));
    }
  } catch (error) {
    const user = await fetchUserDetails(data.uuid, api, getState);
    dispatch(loadUser(user));
    dispatch(addUserFailure(error.message));

    if (data.uuid === getState().auth.user.uuid) {
      dispatch(updateSelf(user));
    }
  }
};

export const deactivateUser = user => async (dispatch, getState, api) => {
  try {
    await api.put(`/users/${user.uuid}/deactivate`, user);
    const userDetail = await fetchUserDetails(user.uuid, api, getState);
    dispatch(loadUser(userDetail));
  } catch (error) {
    dispatch(addUserToGroupFailure(getErrorMessage(error)));
  }
};

export const activateUser = user => async (dispatch, getState, api) => {
  try {
    const response = await api.put(`/users/${user.uuid}/activate`);
    if (response.ok) {
      const userDetail = await fetchUserDetails(user.uuid, api, getState);
      dispatch(loadUser(userDetail));
    } else {
      const id = createUUID();
      dispatch(openModal({ // Dispatch action
        id, // from utils
        type: 'Error', // Need to make in components/modals/Notifications.jsx
        text: getErrorMessage(await response.json()), // error message
        // onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
        // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
      }));
    }
  } catch (error) {
    dispatch(addUserToGroupFailure(getErrorMessage(error)));
  }
};

export const unlockUser = userID => async (dispatch, getState, api) => {
  try {
    await api.put(`/users/${userID}/unlock`);
    const userDetail = await fetchUserDetails(userID, api, getState);
    dispatch(loadUser(userDetail));
  } catch (err) {
    const id = createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Failed to unlock user', // error message
      // onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
      // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
    }));
  }
};


export const clearSelectedUser = () => (dispatch) => {
  dispatch({ type: types.CLEAR_USER_SUCCESS });
};
