/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Button, Dropdown, Label, Spinner,
} from 'rtm-ui-components';
import AddBotGroups from './AddBotGroups/AddBotGroups';
import AddEntityWrapper from '../../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import * as groupActions from '../../../../store/actions/groups';
import * as botActions from '../../../../store/actions/bots';
import { groupVisibilityFilters, paginationLimit } from '../../../../constants';
import HasRole from '../../../../roles/HasRole';
import DetailsCard from '../../../SharedComponents/DetailsCard/DetailsCard';
import Table from '../../../SharedComponents/Table/Table';
import AddFirstEntity from '../../../SharedComponents/AddFirstEntity/AddFirstEntity';
import ChangeView from '../../../SharedComponents/ChangeView/ChangeView';
import { entityCardDetails, ucfirst } from '../../../../utils';
import '../../../../assets/stylesheets/DetailsViewContent.css';


class BotGroups extends Component {
  state = {
    filter: '',
    visibilityFilter: 'all',
    addGroups: false,
    cards: true,
    limit: paginationLimit,
    page: 0,
  };

  componentDidMount() {
    const { getSelectedBotGroups } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    if (settings && settings.detailsListingCards !== undefined && !settings.detailsListingCards) {
      this.setState({ cards: false });
    }
    getSelectedBotGroups();
  }

  onChangeFilter = async (value, type) => {
    switch (type) {
      case 'visibility':
        await this.setState({ visibilityFilter: value });
        break;
      default:
        break;
    }
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.detailsListingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  filteredGroups = (groups, filter, visibilityFilter) => {
    let filteredGroups = [...groups];
    if (visibilityFilter !== 'all') {
      filteredGroups = filteredGroups.filter(({ group }) => (visibilityFilter === 'public' && group.isPublic) || (visibilityFilter === 'private' && !group.isPublic));
    }
    if (filter) {
      filteredGroups = filteredGroups.filter(({ group }) => group.name.includes(filter) || group.longname.includes(filter));
    }
    return filteredGroups;
  }

  toggleModal = () => {
    const { addGroups } = this.state;
    this.setState({ addGroups: !addGroups });
  }

  changePage = async (activePage) => {
    const { getSelectedBotGroups } = this.props;
    await this.setState({ page: activePage });
    getSelectedBotGroups();
  }

  removeUserGroup = async (user, group) => {
    const { removeUserGroup, getSelectedBotGroups } = this.props;
    await removeUserGroup(user, group);
    getSelectedBotGroups();
  }

  addGroupAdmin = async (user, group) => {
    const { addGroupAdmin, getSelectedBotGroups } = this.props;
    await addGroupAdmin(user, group);
    getSelectedBotGroups();
  }

  removeGroupAdmin = async (user, group) => {
    const { removeGroupAdmin, getSelectedBotGroups } = this.props;
    await removeGroupAdmin(user, group);
    getSelectedBotGroups();
  }

  render() {
    const {
      bot, getSelectedBotGroups, loading,
    } = this.props;
    const {
      filter, visibilityFilter, addGroups, cards,
    } = this.state;
    const detailsgroupVisibilityFilters = groupVisibilityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const botGroupsFiltered = bot && bot.groups && this.filteredGroups(bot.groups, filter, visibilityFilter);

    let renderedType;
    if ((!bot || !bot.groups || !bot.groups.length) && loading) {
      renderedType = (
        <div className="details-list-container table">
          <Spinner />
        </div>
      );
    } else if (!bot || !bot.groups || !bot.groups.length) {
      renderedType = (
        <div className="details-list-container table">
          <AddFirstEntity iconName="addGroupIcon" text="There are no groups yet." />
        </div>
      );
    } else if (cards) {
      const groupsCards = botGroupsFiltered && botGroupsFiltered.map(({
        group, role, isParentGroup, childGroups,
      }) => {
        const details = entityCardDetails(group);
        if (isParentGroup) {
          details.push({
            title: 'Member via',
            value: `${childGroups.join(', ')}`,
          });
        }
        details.push({
          title: 'Bot\'s role',
          value: ucfirst(role.name),
        });
        const actions = isParentGroup ? [] : [
          {
            id: role.name === 'user' ? 'add-group-admin' : 'remove-group-admin',
            title: role.name === 'user' ? 'Add group admin' : 'Remove group admin',
            onClick: role.name === 'user' ? () => this.addGroupAdmin(bot, group) : () => this.removeGroupAdmin(bot, group),
            requiredRole: 'botEdit',
            resource: bot,
          },
          {
            id: 'remove-group',
            title: 'Remove bot from group',
            onClick: () => this.removeUserGroup(bot, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        return (
          <DetailsCard
            key={group.uuid}
            icon={{ name: 'group', color: 'primary' }}
            isPrivate={!group.isPublic}
            name={group.longname}
            description={group.name}
            details={details}
            link={{ title: 'Edit group', target: `/groups/${group.uuid}` }}
            actions={actions}
            isAdmin={role.name === 'admin'}
          />
        );
      });
      renderedType = (
        <div className="details-list-container cards">
          { groupsCards }
        </div>
      );
    } else {
      const groupsTableContent = botGroupsFiltered && botGroupsFiltered.map(({
        group, role, isParentGroup, childGroups,
      }) => {
        const details = entityCardDetails(group);
        if (isParentGroup) {
          details.push({
            title: 'Member via',
            value: `${childGroups.join(', ')}`,
          });
        }
        details.push({
          title: 'Bot\'s role',
          value: ucfirst(role.name),
        });
        const actions = isParentGroup ? [] : [
          {
            id: role.name === 'user' ? 'add-group-admin' : 'remove-group-admin',
            title: role.name === 'user' ? 'Add group admin' : 'Remove group admin',
            onClick: role.name === 'user' ? () => this.addGroupAdmin(bot, group) : () => this.removeGroupAdmin(bot, group),
            requiredRole: 'botEdit',
            resource: bot,
          },
          {
            id: 'remove-group',
            title: 'Remove bot from group',
            onClick: () => this.removeUserGroup(bot, group),
            requiredRole: 'groupEdit',
            resource: group,
          },
        ];
        return {
          group, role, details, actions, childGroups: isParentGroup ? childGroups.join(', ') : '',
        };
      });
      const groupsTable = <Table groups={groupsTableContent || null} />;
      renderedType = (
        <div className="details-list-container table">
          { groupsTable }
        </div>
      );
    }

    return (
      <div className="details-membership-row" id="bots-page">
        <div className="user-group-form botGroups">
          <div>
            <div className="details-inputs">
              <Input
                value={filter}
                className="user-search"
                id="filter-user-groups-input"
                placeholder="Filter Groups"
                onChange={event => this.setState({ filter: event.target.value })}
                icon="search"
                iconPosition="left"
              />
              <div className="details-left">
                <HasRole requiredRole="botEdit" resource={bot}>
                  <Button
                    icon="add"
                    id="add-user-to-group"
                    iconPlace="left"
                    iconColor="black"
                    variant="primary"
                    onClick={this.toggleModal}
                  >Add Bot To Groups
                  </Button>
                </HasRole>
                <ChangeView cards={cards} changeListingStyle={this.changeListingStyle} />
              </div>
            </div>
            <div className="filter-container">
              <div className="filters-wrapper">
                <div className="filters">
                  <Label text="Group Visibility" />
                  <Dropdown
                    defaultValue={detailsgroupVisibilityFilters[0].text} options={detailsgroupVisibilityFilters} onChange={item => this.onChangeFilter(item.value, 'visibility')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="details-entity-listing">
            { renderedType }
          </div>
          {addGroups ? (
            <AddEntityWrapper
              onClose={this.toggleModal}
              open={addGroups}
              title="Add Bot to Groups"
            >
              <AddBotGroups
                addAction={async () => {
                  await getSelectedBotGroups();
                  this.toggleModal();
                }}
                selectedBot={bot}
              />
            </AddEntityWrapper>
          ) : null}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => (bindActionCreators({
  removeUserGroup: groupActions.removeUserGroup,
  addGroupAdmin: groupActions.addGroupAdmin,
  removeGroupAdmin: groupActions.removeGroupAdmin,
  getSelectedBotGroups: botActions.getSelectedBotGroups,
}, dispatch));

const mapStateToProps = state => ({
  loading: state.bots.isSearchingBotMembers,
});

export default connect(mapStateToProps, mapDispatchToProps)(BotGroups);
