import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthPage from '../AuthPage';
import * as authActions from '../../../../store/actions/auth';

const ResetPassword = (props) => {
  const { resetPassword } = props;
  return (
    <AuthPage
      passwordResetPage
      submitAction={resetPassword}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  authError: state.auth.resetPasswordError,
});
const mapDispatchToProps = dispatch => (bindActionCreators({
  resetPassword: authActions.resetPassword,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
