/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Label, Button, Input,
} from 'rtm-ui-components';
import Message from '../../../SharedComponents/Message/Message';
import * as userActions from '../../../../store/actions/users';
import { invalidPassword } from '../../../../utils';
import '../../../../assets/stylesheets/EditForm.css';

const passwordsDonotMatch = values => values.password
  && values.confirmpassword && values.password !== values.confirmpassword;

// password matches config setting


class EditPasswordForm extends Component {
  state = {
    values: {
      oldpassword: '',
      password: '',
      confirmpassword: '',
    },
    touched: {
      oldpassword: false,
      password: false,
      confirmpassword: false,
    },
    pristine: true,
  }

  validate = () => {
    const { passwordConfig } = this.props;
    const { values, touched } = this.state;
    const errors = {};
    if (!values.oldpassword && touched.oldpassword) {
      errors.oldpassword = 'Password is required.';
    }
    if (invalidPassword(values.password, passwordConfig) && touched.password) {
      errors.password = passwordConfig && passwordConfig.description;
    }
    if (passwordsDonotMatch(values) && touched.password && touched.confirmpassword) {
      errors.confirmpassword = 'The two passwords do not match.';
    }
    if (!values.password && touched.password) {
      errors.password = 'Password is required.';
    }
    if (!values.confirmpassword && touched.confirmpassword) {
      errors.confirmpassword = 'The two passwords do not match.';
    }
    return errors;
  };

  onChangeHandler = (event) => {
    const { values, touched } = this.state;
    this.setState({
      values: {
        ...values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...touched,
        [event.target.name]: true,
      },
      pristine: false,
    });
  }

  render() {
    const { values, pristine } = this.state;
    const {
      onPasswordChange, onCancel, error, passwordConfig, user, loading, userEditMessage,
    } = this.props;
    const errors = this.validate();
    return (
      <div>
        <form onSubmit={(e) => { e.preventDefault(); onPasswordChange(values); }} id="edit-password-form" className="add-entity-view-form">
          <div className="header-bar">
            <span className="header">PASSWORD CHANGE</span>
          </div>
          <div className="edit-form-fields">
            <div className="edit-fields-left">
              <div className="add-entity-field">
                <Label htmlFor="old-password-field" text="Old password" />
                <Input fluid error={errors.oldpassword} type="password" name="oldpassword" id="old-password-field" onChange={this.onChangeHandler} />
              </div>
              <div className="add-entity-field">
                <Label htmlFor="password-field" text="New password" info={passwordConfig && passwordConfig.description} />
                <Input fluid error={errors.password} onChange={this.onChangeHandler} name="password" type="password" id="password-field" />
              </div>
              <div className="last-password-field">
                <Label htmlFor="confirm-password-field" text="Confirm password" />
                <Input fluid error={errors.confirmpassword} onChange={this.onChangeHandler} name="confirmpassword" type="password" id="confirm-password-field" />
              </div>
              <div className="form-message-container">
                {(userEditMessage)
                  && (
                    <Message positive header="Password Updated Successfully" />
                  )
                }
                {error
                  && (
                    <Message negative header={error} />
                  )
                }
                {user.isPassthrough
                  && (
                    <Message negative header="Cannot Change Password from RTM" />
                  )
                }
              </div>
            </div>
            <div className="side">
              <div className="edit-form-buttons">
                <Button
                  loading={loading}
                  variant="primary"
                  type="submit"
                  id="edit-user-btn"
                  disabled={loading || (Object.keys(errors).length > 0 && errors.constructor === Object) || pristine || user.isPassthrough}
                >
                  Save
                </Button>
                <Button
                  id="close-btn"
                  onClick={onCancel}
                  variant="secondary"
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => (bindActionCreators({
  updatePassword: userActions.updatePassword,
}, dispatch));

const mapStateToProps = (state) => {
  const { selectedUser: user } = state.users;
  return {
    user,
    loggedInUser: state.auth.user,
    passwordConfig: state.configs.configs['password.strength'],
    loading: state.users.isEditingUser,
    userEditMessage: state.users.userEditMessage,
    error: state.users.userEditError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordForm);
