import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from 'rtm-ui-components';
import './ChangeView.css';

class ChangeView extends Component {
  render() {
    let content = '';
    const { changeListingStyle, cards } = this.props;
    content = (
      <div className="change-view-wrapper">
        <ToggleButton id="details-change-listing-type-grid" onClick={changeListingStyle} active={cards} iconId="gridview" iconName="gridview" shape="left-round" />
        <ToggleButton id="details-change-listing-type-list" onClick={changeListingStyle} active={!cards} iconId="listview" iconName="listview" shape="right-round" />
      </div>
    );
    return (content);
  }
}

ChangeView.propTypes = {
  changeListingStyle: PropTypes.func.isRequired,
  cards: PropTypes.bool,
};

ChangeView.defaultProps = {
  cards: false,
};

export default ChangeView;
