import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'rtm-ui-components';
import './ListingCard.css';

const ListingCard = ({
  link, icon, name, description, cardClass, textClass, isPrivate, privateIconColor, source,
}) => (
  <div className="list-row">
    <Link to={{ pathname: link }}>
      <div className={cardClass}>
        <div className="user-role-icon">
          <Icon {...icon} size="massive" />
          {isPrivate
            ? (<div className="private-icon"><Icon name="private" width="12px" height="16px" color={privateIconColor} /></div>)
            : (null)}
          {source == "RTM" ? (null) : (<div className='external-icon'> <Icon name="organization" width="12px" height="16px" color="blue"/></div>)}
        </div>
        <div className={textClass ? `user-info ${textClass}` : 'user-info'}>
          <div className="user-name">{name}</div>
          <div className="more-info">{description}</div>
        </div>
      </div>
    </Link>
  </div>
);

ListingCard.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    stype: PropTypes.shape({
      fill: PropTypes.string,
    }),
  }).isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  cardClass: PropTypes.string,
  textClass: PropTypes.string,
  isPrivate: PropTypes.bool,
  privateIconColor: PropTypes.oneOf(['primary', 'grey', 'red']),
};

ListingCard.defaultProps = {
  name: '',
  description: '',
  cardClass: '',
  textClass: '',
  isPrivate: false,
  privateIconColor: 'red',
};


export default ListingCard;
