import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
  Button, Input, Icon,
} from 'rtm-ui-components';
import Message from '../../../../SharedComponents/Message/Message';
import { isValidEmail } from '../../../../../utils';
import * as authActions from '../../../../../store/actions/auth';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import './RecoverPasswordForm.css';

class RecoverPasswordForm extends Component {
  state = {
    email: '',
  }

  handleInput = (t) => {
    this.setState({ [t.name]: t.value });
  };

  isDisabled = () => {
    const { email } = this.state;
    return (!email || !email.trim() || !isValidEmail(email));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    const { email } = this.state;
    onSubmit(email);
  }

  render() {
    const {
      loading, clearLoginError, errorMessage, recover,
    } = this.props;
    return (
      <div>
        <form onSubmit={async (e) => { await this.handleSubmit(e); }} className="signup-form">
          <p className="login-link"><Link className="login-link-text" to="/login" onClick={clearLoginError}><Icon width="12" height="12" id="back-to-login" name="arrowLeft" /><strong>Login</strong></Link></p>
          <div className="form-message-container">
            {(recover)
              && (
                <Message positive header="Password Recovery Link Sent Successfully" />
              )
            }
            {(errorMessage) && (<ErrorMessage message={errorMessage} />)}
          </div>
          <div className="recover-password-email-field">
            <label htmlFor="password">Email</label>
            <Input id="recover-password-email" placeholder="" name="email" onChange={e => this.handleInput(e.target)} type="text" size="small" fluid />
          </div>
          <Button
            variant="primary"
            loading={loading}
            type="submit"
            id="signup-btn"
            disabled={this.isDisabled()}
            fluid
          >
            RESET PASSWORD
          </Button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  clearLoginError: authActions.clearLoginError,
}, dispatch));

const mapStateToProps = state => ({
  loggedInUser: state.auth.user,
  recover: state.auth.passwordRecoverSuccess,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordForm);
