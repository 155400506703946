import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import 'react-dates/initialize';

import history from './history';
import Routes from './Routes';
import NotificationsModals from './NotificationsModals/NotificationsModals';
import 'rtm-ui-components/dist/assets/main.min.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NotificationsModals />
        <Router history={history}>
          <Routes />
        </Router>
      </React.Fragment>
    );
  }
}
export default App;
