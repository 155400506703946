import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'rtm-ui-components';
import EditGroupForm from './EditGroupForm/EditGroupForm';
import * as groupActions from '../../../store/actions/groups';
import * as navigationActions from '../../../store/actions/navigation';
import GroupUsers from './GroupUsers/GroupUsers';
import GroupBots from './GroupBots/GroupBots';
import NestedGroups from './NestedGroups/NestedGroups';
import DetailsViewWrapper from '../../Wrappers/DetailsViewWrapper/DetailsViewWrapper';
import DetailsTopRow from '../../SharedComponents/DetailsTopRow/DetailsTopRow';
import DetailsInfoRow from '../../SharedComponents/DetailsInfoRow/DetailsInfoRow';

class EditGroup extends Component {
  state = { expand: false }

  async componentDidMount() {
    const { match, loadGroupDetail } = this.props;
    await loadGroupDetail(match.params.groupId);
  }

  componentDidUpdate(prevProps) {
    const { match, clearSelectedGroup, loadGroupDetail } = this.props;
    if (prevProps.match.params.groupId !== match.params.groupId) {
      clearSelectedGroup();
      loadGroupDetail(match.params.groupId);
    }
  }

  componentWillUnmount() {
    const { clearSelectedGroup } = this.props;
    clearSelectedGroup();
  }


  toggleEditBlock = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  }

  closeModal = () => {
    const { clearEditView, history } = this.props;
    clearEditView();
    history.push('/groups');
  }

  backButton = () => {
    const {
      popEditView, editViewStack, history, refreshGroup,
    } = this.props;
    const goToUrl = editViewStack[editViewStack.length - 1];
    if (goToUrl.includes('/groups')) {
      refreshGroup(goToUrl.replace('/groups/', ''));
    }
    popEditView();
    history.push(goToUrl);
  }

  render() {
    const { expand } = this.state;
    const {
      group, nestedGroupsEnabled, editViewStack, pushEditView,
      deleteGroup, searchGroups, refreshGroup, openModal, location,
    } = this.props;

    if (!group) {
      return null;
    }

    const panels = [
      {
        title: 'Users',
        icon: 'userPlain',
        id: 'users-tab',
        content: (<GroupUsers group={group} />),
      },
      {
        title: 'Bots',
        icon: 'botPlain',
        id: 'bots-tab',
        content: (<GroupBots group={group} />),
      },
    ];
    if (nestedGroupsEnabled) {
      panels.push(
        {
          title: 'Groups',
          icon: 'groupPlain',
          id: 'groups-tab',
          content: (<NestedGroups pushEditView={pushEditView} location={location} refreshGroup={refreshGroup} parentGroup={group} />),
        },
      );
    }

    return (
      <DetailsViewWrapper
        openModal={openModal}
        closeModal={this.closeModal}
        topRow={(
          <DetailsTopRow
            group={group}
            openEditForm={expand}
            deleteGroup={async (deletedGroup) => {
              await deleteGroup(deletedGroup);
              searchGroups();
            }}
            back={editViewStack.length ? this.backButton : null}
            closeModal={this.closeModal}
            toggleEditBlock={this.toggleEditBlock}
          />
        )}
        infoRow={(
          <DetailsInfoRow group={group} />
        )}
        editEntity={expand
          ? (
            <EditGroupForm
              searchGroups={searchGroups}
              onCancel={this.toggleEditBlock}
            />
          ) : (null)}
        listing={<Tabs className="groups-tabs" panels={panels} />}
      />
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  updateGroup: groupActions.updateGroup,
  deleteGroup: groupActions.deleteGroup,
  loadGroupDetail: groupActions.loadGroupDetail,
  clearSelectedGroup: groupActions.clearSelectedGroup,
  clearEditView: navigationActions.clearEditView,
  popEditView: navigationActions.popEditView,
  pushEditView: navigationActions.pushEditView,
}, dispatch));

const mapStateToProps = state => ({
  nestedGroupsEnabled: state.configs.configs && state.configs.configs['nested.groups.enabled'] && state.configs.configs['nested.groups.enabled'].value,
  editViewStack: state.navigation.editViewStack,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditGroup));
