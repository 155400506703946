import * as types from './actionTypes';
import * as utils from '../../utils';
import { openModal } from './modals';
import history from '../../components/history';
import { loadUserDetail } from './users';
import { loadBotDetail } from './bots';

const loadGroupsSuccess = groups => ({ type: types.LOAD_GROUPS_SUCCESS, payload: groups });
const loadGroupsFailure = error => ({ type: types.LOAD_GROUPS_FAILURE, payload: error });

const loadUserGroupsSuccess = groups => ({ type: types.LOAD_USER_GROUPS_SUCCESS, payload: groups });

const loadingGroups = () => ({ type: types.GROUPS_LOADING });
const loadingGroupMembers = () => ({ type: types.GROUP_MEMBERS_LOADING });

const searchGroupsSuccess = groups => ({ type: types.SEARCH_GROUPS_SUCCESS, payload: groups });
const searchGroupsFailure = error => ({ type: types.SEARCH_GROUPS_FAILURE, payload: error });
const clearAddGroupErrors = () => ({ type: types.CLEAR_USER_ERRORS });

const addGroupsFailure = error => ({ type: types.ADD_GROUP_FAILURE, payload: error });
const addGroupSuccess = group => ({ type: types.ADD_GROUP_SUCCESS, payload: group });

const getGroupsStarted = () => ({ type: types.GET_GROUP_STARTED });
// const getGroupsFailure = () => ({ type: types.GET_GROUP_FAILURE });
const getGroupsSuccess = () => ({ type: types.GET_GROUP_SUCCESS });

const updateGroupsFailure = error => ({ type: types.UPDATE_GROUP_FAILURE, payload: error });
const clearGroupsError = () => ({ type: types.CLEAR_GROUPS_ERROR });
const updateGroupSuccess = () => ({ type: types.UPDATE_GROUP_SUCCESS });

// const searchGroupsStarted = () => ({ type: types.SEARCH_GROUPS_STARTED });

const clearGroupsSuccess = () => ({ type: types.CLEAR_GROUPS_SUCCESS });

const getErrorMessage = error => ((error.response) ? error.response.dataMessage : error.message);

const loadGroup = group => ({ type: types.LOAD_GROUP_SUCCESS, payload: group });

const parseFetchResponse = response => response.clone().json().then(text => ({
  json: text,
  meta: response,
}));


export const loadGroupUsers = (groupId, params) => async (dispatch, getState, api) => {
  try {
    dispatch(loadingGroupMembers());
    const urlParams = new URLSearchParams({ ...params });
    const { selectedGroup } = getState().groups;
    const users = await api.get(`/groups/${groupId}/users?${urlParams}`);
    if (getState().groups.selectedGroup) {
      dispatch(loadGroup({ ...selectedGroup, users }));
    }
  } catch (error) {
    dispatch(searchGroupsFailure(getErrorMessage(error)));
  }
};
export const loadGroupBots = (groupId, params) => async (dispatch, getState, api) => {
  try {
    dispatch(loadingGroupMembers());
    const urlParams = new URLSearchParams({ ...params });
    const { selectedGroup } = getState().groups;
    const bots = await api.get(`/groups/${groupId}/bots?${urlParams}`);
    if (getState().groups.selectedGroup) {
      dispatch(loadGroup({ ...selectedGroup, bots }));
    }
  } catch (error) {
    dispatch(searchGroupsFailure(getErrorMessage(error)));
  }
};
export const loadNestedGroups = (groupId, params) => async (dispatch, getState, api) => {
  try {
    dispatch(loadingGroupMembers());
    const urlParams = new URLSearchParams({ ...params });
    const { selectedGroup } = getState().groups;
    const childGroups = await api.get(`/nested-groups/${groupId}?${urlParams}`);
    if (getState().groups.selectedGroup) {
      dispatch(loadGroup({ ...selectedGroup, childGroups }));
    }
  } catch (error) {
    dispatch(searchGroupsFailure(getErrorMessage(error)));
  }
};

export const loadGroupDetail = groupId => async (dispatch, getState, api) => {
  try {
    const { selectedGroup } = getState().groups;
    const group = await api.get(`/groups/${groupId}`);
    dispatch(loadGroup({ ...selectedGroup, ...group }));
  } catch (error) {
    dispatch(searchGroupsFailure(getErrorMessage(error)));
  }
};

export const addUserGroup = (user, group) => async (dispatch, getState, api) => {
  const response = await api.put(`/groups/${group.uuid}/members/${user.uuid}`);
  /*if (!response.ok && !response.id) {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: getErrorMessage(await response.json()), // error message
    }));
  }*/
  //TODO some better error handling that only dispatches one error message instead of one for every failed user to prevent spam
};

export const addNestedGroup = (group, parentGroup) => async (dispatch, getState, api) => {
  const response = await api.put(`/nested-groups/${parentGroup.uuid}/${group.uuid}`);
  if (!response.ok && !response.id) {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Could not add group to group', // error message
    }));
  }
};

export const removeUserGroup = (user, group) => async (dispatch, getState, api) => {
  const response = await api.delete(`/groups/${group.uuid}/members/${user.uuid}`);
  if (response.ok || response.id) {
    if (history.location.pathname.startsWith('/users/')) {
      await dispatch(loadUserDetail(user.uuid));
    }
    if (history.location.pathname.startsWith('/bots/')) {
      await dispatch(loadBotDetail(user.uuid));
    }
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Couldn\'t remove user from group', // error message
    }));
  }
};

export const removeNestedGroup = (childGroup, parentGroup) => async (dispatch, getState, api) => {
  const response = await api.delete(`/nested-groups/${parentGroup.uuid}/${childGroup.uuid}`);
  if (response.ok || response.id) {
    // dispatch(loadGroupDetail(parentGroup.uuid));
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Couldn\'t remove user from group', // error message
    }));
  }
};

export const addGroupAdmin = (user, group) => async (dispatch, getState, api) => {
  const roles = await api.get('/roles');
  const groupAdminRole = roles.find(role => role.type === 'GROUP' && role.name === 'admin');
  const response = await api.put(`/groups/${group.uuid}/members/${user.uuid}/roles/${groupAdminRole.id}`);
  if (response.ok || response.id) {
    if (history.location.pathname.startsWith('/users/')) {
      await dispatch(loadUserDetail(user.uuid));
    }
    if (history.location.pathname.startsWith('/bots/')) {
      await dispatch(loadBotDetail(user.uuid));
    }
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Couldn\'t add admin rights to user', // error message
    }));
  }
};

export const removeGroupAdmin = (user, group) => async (dispatch, getState, api) => {
  const roles = await api.get('/roles');
  const groupUserRole = roles.find(role => role.type === 'GROUP' && role.name === 'user');
  const response = await api.put(`/groups/${group.uuid}/members/${user.uuid}/roles/${groupUserRole.id}`);
  if (response.ok || response.id) {
    if (history.location.pathname.startsWith('/users/')) {
      await dispatch(loadUserDetail(user.uuid));
    }
    if (history.location.pathname.startsWith('/bots/')) {
      await dispatch(loadBotDetail(user.uuid));
    }
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Couldn\'t remove admin rights from user', // error message
    }));
  }
};


export const clearGroups = () => (dispatch) => {
  dispatch(clearGroupsSuccess());
};

export const clearFormErrors = () => (dispatch) => {
  dispatch(clearAddGroupErrors());
};

export const getGroups = params => async (dispatch, getState, api) => {
  try {
    dispatch(loadingGroups());
    const urlParams = new URLSearchParams({ ...params });
    const response = await api.get(`/groups?${urlParams}`);
    dispatch(loadGroupsSuccess(response));
  } catch (error) {
    dispatch(loadGroupsFailure(getErrorMessage(error)));
  }
};

export const searchGroups = params => async (dispatch, getState, api) => {
  try {
    dispatch(loadingGroups());
    const urlParams = new URLSearchParams({ ...params });
    const response = await api.get(`/groups?${urlParams}`);
    dispatch(searchGroupsSuccess(response));
  } catch (error) {
    dispatch(searchGroupsFailure(getErrorMessage(error)));
  }
};


export const getUserGroups = () => async (dispatch, getState, api) => {
  try {
    const userId = getState().auth.user.uuid;
    const response = await api.get(`/users/${userId}/groups`);
    dispatch(loadUserGroupsSuccess(response));
  } catch (error) {
    dispatch(loadGroupsFailure(getErrorMessage(error)));
  }
};

export const addGroup = data => async (dispatch, getState, api) => {
  try {
    dispatch(clearGroupsError());
    const response = await api.postbody('/groups', { ...data, isPublic: !!data.isPublic });
    const { json, meta } = await parseFetchResponse(response);
    if (meta.status === 201) {
      await dispatch(getUserGroups());

      const users = await api.get(`/groups/${json.uuid}/users`);
      await dispatch(addGroupSuccess({ ...json, users: { ...users } }));
    } else {
      const err = await response.json();
      throw Error(err.message);
    }
  } catch (error) {
    const _error = getErrorMessage(error);
    await dispatch(addGroupsFailure(_error));
  }
};

export const deleteGroup = data => async (dispatch, getState, api) => {
  try {
    await api.delete(`/groups/${data.uuid}`);
  } catch (error) {
    dispatch(loadGroupsFailure(getErrorMessage(error)));
  }
};

export const getGroup = name => async (dispatch, getState, api) => {
  try {
    dispatch(getGroupsStarted());
    const response = await api.get(`/group/getgroup?name=${name}`);
    dispatch(getGroupsSuccess(response));
  } catch (error) {
    dispatch(searchGroupsFailure(getErrorMessage(error)));
  }
};

export const updateGroup = group => async (dispatch, getState, api) => {
  try {
    dispatch(clearGroupsError());
    const { selectedGroup } = getState().groups;
    const response = await api.put(`/groups/${group.uuid}`, { ...group, isPublic: !!group.isPublic });
    if (!response.ok) {
      throw Error('Updating Group Details Failed');
    } else {
      dispatch(updateGroupSuccess(response));
      const updatedGroup = await api.get(`/groups/${group.uuid}`);
      dispatch(loadGroup({ ...selectedGroup, ...updatedGroup }));
    }
  } catch (error) {
    dispatch(updateGroupsFailure(error.message));
  }
};


export const clearSelectedGroup = () => (dispatch) => {
  dispatch({ type: types.CLEAR_GROUP_SUCCESS });
};
