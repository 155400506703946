import * as types from '../actions/actionTypes';

const initState = {
  user: JSON.parse(localStorage.getItem('user')),
  redirectToReferrer: false,
  message: '',
  error: '',
  loading: false,
  passwordResetSuccess: false,
  passwordRecoverSuccess: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case types.SIGNUP_SUCCESS: {
      return { ...state, user: action.payload, message: 'You have successfully registered and logged in.' };
    }
    case types.SIGNUP_FAILURE: {
      return { ...state, signupError: action.payload };
    }
    case types.LOGIN_ATTEMPT: {
      return { ...state, loading: true };
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state, user: action.payload, error: '', message: 'You have successfully logged in.', loading: false,
      };
    }
    case types.LOGIN_FAILURE: {
      return {
        ...state, user: null, error: '', loginError: action.payload, message: '', recoverPasswordError: '', resetPasswordError: '', loading: false, loginFailed: true,
      };
    }
    case types.LOGOUT_FAILURE:
    case types.LOGOUT_SUCCESS: {
      return { ...initState, user: null };
    }
    case types.CLEAR_AUTH_MESSAGE: {
      return {
        ...state, message: '', error: '', signupError: '', loginError: '', recoverPasswordError: '', resetPasswordError: '',
      };
    }
    case types.LOAD_USER_BOTS_SUCCESS: {
      return { ...state, user: { ...state.user, bots: action.payload } };
    }
    case types.LOAD_USER_GROUPS_SUCCESS: {
      return { ...state, user: { ...state.user, groups: action.payload } };
    }
    case types.RECOVER_PASSWORD_SUCCESS: {
      return { ...state, message: action.payload, passwordRecoverSuccess: true };
    }
    case types.RECOVER_PASSWORD_FAILURE: {
      return { ...state, recoverPasswordError: action.payload, passwordRecoverSuccess: false };
    }
    case types.RESET_PASSWORD_SUCCESS: {
      return { ...state, message: action.payload, passwordResetSuccess: true };
    }
    case types.RESET_PASSWORD_FAILURE: {
      return { ...state, resetPasswordError: action.payload, passwordResetSuccess: false };
    }
    case types.PASSWORD_CONFIG_SUCCESS: {
      return { ...state, passwordConfig: action.payload };
    }
    case types.USERNAME_CONFIG_SUCCESS: {
      return { ...state, usernameConfig: action.payload };
    }
    case types.SAML_URL_CONFIG_SUCCESS: {
      return { ...state, samlUrlConfig: action.payload };
    }
    case types.SAML_ENABLED_CONFIG_SUCCESS: {
      return { ...state, samlEnabledConfig: action.payload };
    }
    case types.UPDATE_SELF: {
      localStorage.setItem('user', JSON.stringify({ ...state.user, ...action.payload }));
      return { ...state, user: { ...state.user, ...action.payload } };
    }

    default: {
      return state;
    }
  }
}
