import * as types from '../actions/actionTypes';
import { paginationLimit } from '../../constants';

const defaultGroups = {
  limit: paginationLimit,
  page: 0,
  resultCount: 0,
  totalPages: 1,
  groups: [],
};

export default function groupsReducer(state = {
  groups: defaultGroups,
  searchedGroups: defaultGroups,
  selectedGroup: null,
  error: '',
  addedGroup: null,
  isSearchingGroupMembers: false,
}, action) {
  switch (action.type) {
    case types.SEARCH_GROUPS_SUCCESS: {
      return {
        ...state,
        searchedGroups: action.payload,
        isSearching: false,
      };
    }
    case types.LOAD_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.payload,
        isSearching: false,
      };
    }
    case types.GROUPS_LOADING: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case types.SEARCH_GROUPS_FAILURE: {
      return {
        ...state,
        searchedGroups: defaultGroups,
        error: action.payload,
        isSearching: false,
      };
    }
    case types.ADD_GROUP_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.UPDATE_GROUP_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.CLEAR_GROUPS_ERROR: {
      return {
        ...state,
        error: '',
      };
    }
    case types.LOAD_GROUPS_FAILURE: {
      return {
        ...state,
        groups: defaultGroups,
        error: action.payload,
        isSearching: false,
      };
    }
    case types.LOGOUT_FAILURE:
    case types.LOGOUT_SUCCESS: {
      return {
        groups: defaultGroups,
        searchedGroups: defaultGroups,
        error: '',
        addedGroup: null,
        selectedGroup: null,
      };
    }
    case types.GROUP_MEMBERS_LOADING: {
      return {
        ...state,
        isSearchingGroupMembers: true,
      };
    }
    case types.LOAD_GROUP_SUCCESS: {
      return {
        ...state,
        selectedGroup: action.payload,
        isSearchingGroupMembers: false,
      };
    }
    case types.CLEAR_GROUP_SUCCESS: {
      return {
        ...state,
        selectedGroup: null,
      };
    }
    case types.ADD_GROUP_SUCCESS: {
      return {
        ...state,
        addedGroup: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
