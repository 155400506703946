import React, { Component } from 'react';
import { Icon, Popup } from 'rtm-ui-components';
import PropTypes from 'prop-types';
import DetailsEntityPopup from '../DetailsEntityPopup/DetailsEntityPopup';
import { User, Group, Bot } from '../../../propTypes';

import './DetailsCard.css';

export default class DetailsCard extends Component {
  textClass = () => {
    const { isLocked, isInactive } = this.props;
    if (isInactive) {
      return 'strike-through';
    } if (isLocked) {
      return 'locked';
    }
    return '';
  }

  render() {
    const {
      isPrivate, isLocked, icon, name, description, isInactive, details, actions, link, isAdmin,
    } = this.props;
    return (
      <Popup
        on="click"
        position="top"
        className="details-card-popup"
        yOffset={20}
        xOffset={12}
        trigger={(
          <div id={name} className="edit-card">
            <div className={!isInactive && isLocked ? 'edit-card-container locked' : 'edit-card-container'}>
              <div className="role-icon">
                <Icon {...icon} size="massive" />
                {isPrivate
                  ? (<div className="bottom-floating-icon"><Icon name="private" width="12px" height="12px" color={isInactive ? 'grey' : 'red'} /></div>) : (null)}
                {isAdmin
                  ? (<div className="top-floating-icon"><Icon name="solidAdmin" color="purple" width="12px" height="12px" /></div>) : null}
              </div>
              <div className={`user-info ${this.textClass()}`}>
                <div className="user-name">{name}</div>
                <div className="more-info">{description}</div>
              </div>
              <Icon className="details-icon" name="details" size="large" />
            </div>
          </div>
        )}
      >
        <DetailsEntityPopup actions={actions} link={link} details={details} />
      </Popup>
    );
  }
}

DetailsCard.propTypes = {
  icon: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    style: PropTypes.shape({
      fill: PropTypes.string,
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isLocked: PropTypes.bool,
  isInactive: PropTypes.bool,
  // Popup content
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      requiredRole: PropTypes.string.isRequired,
      resource: PropTypes.oneOfType([PropTypes.shape(User), PropTypes.shape(Bot), PropTypes.shape(Group)]).isRequired,
    })
  ),
};
DetailsCard.defaultProps = {
  isPrivate: false,
  isLocked: false,
  isAdmin: false,
  isInactive: false,
  actions: [],
  link: null,
};
