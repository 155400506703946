import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const FallbackRoute = (props) => {
  const {
    component: Component, user, defaultRoute, ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!user) {
          return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
        }
        return <Redirect to={{ pathname: defaultRoute, state: { from: routeProps.location } }} />;
      }}
    />
  );
};

const mapStateToProps = state => ({ user: state.auth.user });

export default connect(mapStateToProps)(FallbackRoute);
