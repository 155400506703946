/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'rtm-ui-components';
import { bindActionCreators } from 'redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as authActions from '../../../store/actions/auth';
import LogsHeader from '../LogsHeader/LogsHeader';
import * as auditLogsActions from '../../../store/actions/auditLogs';
import TableRow from '../../SharedComponents/Table/TableRow/TableRow';
import { logsIcon } from '../../../utils';
import { SYSADMIN } from '../../../roles/roles';
import { paginationLimit } from '../../../constants';


import './LogsBlock.css';

const getTarget = (data) => {
  const target = [];
  if (data.UUID) {
    target.push(<div key={data.UUID + data.id}>UUID: {data.UUID}</div>);
  }
  if (data.username) {
    target.push(<div key={data.username + data.id}>User: {data.username}</div>);
  }
  if (data.ParentGroup && data.Group) {
    target.push(<div key={data.ParentGroup.name + data.id}>Parent Group: {data.ParentGroup.name}</div>);
    target.push(<div key={data.Group.name + data.id}>Child Group: {data.Group.name}</div>);
  }
  if (data.Group && !data.ParentGroup) {
    target.push(<div key={data.Group.name + data.id}>Group: {data.Group.name}</div>);
  }
  if (data.Bot) {
    target.push(<div key={data.Bot.username + data.id}>Bot: {data.Bot.username}</div>);
  }
  if (data.User) {
    target.push(<div key={data.User.username + data.id}>User: {data.User.username}</div>);
  }
  if (data.UserOld && data.UserUpdated && data.UserOld.username === data.UserUpdated.username) {
    target.push(<div key={data.UserOld.username + data.id}>User: {data.UserOld.username}</div>);
  }
  if (data.UserOld && data.UserUpdated && data.UserOld.username !== data.UserUpdated.username) {
    target.push(<div key={data.UserOld.username + data.id}>Old User: {data.UserOld.username}</div>);
    target.push(<div key={data.UserUpdated.username + data.id}>New User: {data.UserUpdated.username}</div>);
  }
  if (data.Role) {
    target.push(<div key={data.Role.name + data.id}>Role: {data.Role.name}</div>);
  }
  return <div>{target}</div>;
};


const logsRows = (logs, operations) => (
  logs.map((log) => {
    const date = new Date(log.created);
    let target = '';
    if (log.data && log.data[log.target]) {
      target = log.data[log.target].username ? log.data[log.target].username : log.data[log.target].name;
    }
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const cells = [
      {
        value:
        (
          <div className="audit-timeframe">
            <div className="audit-date">{date.toLocaleDateString('fi-fi', dateOptions)}</div>
            <div className="audit-time">{date.toLocaleTimeString()}</div>
          </div>
        ),
        key: 'time',
      },
      {
        value: log.userName ? log.userName : 'Unknown',
        key: 'name',
      },
      { value: operations[log.type], key: 'type' },
      { value: log.data ? getTarget(log.data) : '', key: 'username' },
      { value: log.ipAddress, key: 'ipaddress' },
    ];
    return (
      <TableRow
        key={log.id}
        uuid={log.principal}
        cells={cells}
        icon={logsIcon(log.role)}
        privateIconColor="red"
        rowClass="audits-table-row"
      />
    );
  })
);


class LogsBlock extends Component {
  state = {
    filters: {
      page: 0,
      asc: false,
      user: '',
    },
    limit: paginationLimit,
  };

  fetchMoreData = () => {
    const { getAuditLogs, logs, hasMore } = this.props;
    const {
      filters, limit,
    } = this.state;
    const obj = { ...filters, page: filters.page + 1 };
    this.setState({ filters: obj }, () => {
      getAuditLogs({ ...obj, limit });
    });
  };

  componentDidMount = () => {
    const { filters, limit } = this.state;
    const {
      currentUserRole, getConfigs, getAuditLogs, clearAuditEvents,
    } = this.props;
    clearAuditEvents();
    if (currentUserRole === SYSADMIN) {
      getAuditLogs(filters, limit);
    }
    getConfigs();
  }

  componentWillUnmount = () => {
    const { clearAuditEvents } = this.props;
    clearAuditEvents();
  }

  addFilter = async (value, filter, value2, filter2) => {
    const {
      currentUserRole, getAuditLogs, clearAuditEvents,
    } = this.props;
    clearAuditEvents();
    const { filters, limit } = this.state;
    const obj = { ...filters, page: 0 };
    obj[filter] = value;
    if (filter2) {
      obj[filter2] = value2;
    }
    await this.setState({ filters: obj });
    if (currentUserRole === SYSADMIN) {
      getAuditLogs(obj, limit);
    }
  }

  removeFilter = async (filter, filter2) => {
    const {
      currentUserRole, getAuditLogs, clearAuditEvents,
    } = this.props;
    clearAuditEvents();
    const { filters, limit } = this.state;
    const obj = { ...filters, page: 0 };
    delete obj[filter];
    if (filter2) {
      delete obj[filter2];
    }
    await this.setState({ filters: obj });
    if (currentUserRole === SYSADMIN) {
      getAuditLogs(obj, limit);
    }
  }

  addAndRemoveFilter = async (addedFilter, value, removedFilter) => {
    const {
      currentUserRole, getAuditLogs, clearAuditEvents,
    } = this.props;
    clearAuditEvents();
    const { filters, limit } = this.state;
    const obj = { ...filters, page: 0 };
    obj[addedFilter] = value;
    delete obj[removedFilter];
    await this.setState({ filters: obj });
    if (currentUserRole === SYSADMIN) {
      getAuditLogs(filters, limit);
    }
  }


  render() {
    const { operations, logs, hasMore } = this.props;
    const { filters } = this.state;
    return (
      <div id="audits" className="audit-table">
        <LogsHeader
          value={filters.asc}
          removeFilter={this.removeFilter}
          addFilter={this.addFilter}
          addAndRemoveFilter={this.addAndRemoveFilter}
          orderContent={this.addFilter}
          operations={operations}
        />
        <div id="grid-body" className="grid-body">
          <InfiniteScroll
            dataLength={logs.length} // This is important field to render the next data
            next={this.fetchMoreData}
            className="infinite-scroll"
            scrollableTarget="grid-body"
            hasMore={hasMore}
            loader={<div style={{ display: 'flex', justifyContent: 'center' }}><Spinner /></div>}
            endMessage={(
              <p style={{ display: 'flex', justifyContent: 'center' }}>
                <b>No more entries</b>
              </p>
            )}
          >
            {logsRows(logs, operations)}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  currentUserRole: state.auth.user.appRole,
  logs: state.auditLogs.events,
  hasMore: state.auditLogs.hasMore,
  operations: state.configs.configs['audit.log.type.descriptions'] ? state.configs.configs['audit.log.type.descriptions'].value : {},
  user: state.auth.user,
  version: state.configs.configs['application.version'],
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getAuditLogs: auditLogsActions.getAuditLogs,
  clearAuditEvents: auditLogsActions.clearAuditEvents,
  getAuditLogOperations: auditLogsActions.getAuditLogOperations,
  getConfigs: authActions.getConfigs,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(LogsBlock);
