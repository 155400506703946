import { applyMiddleware, createStore, compose } from 'redux';

import thunk from 'redux-thunk';

import reducer from './reducers';

import Api from '../api/Api';

const api = new Api();

export default function configureStore() {
  const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(api)),
  );

  let store;

  if (process.env.NODE_ENV !== 'production') {
    // store = createStore(reducer, middleware, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    store = createStore(reducer, enhancer);
  } else {
    const middleware = applyMiddleware(thunk.withExtraArgument(api));
    store = createStore(reducer, middleware);
  }

  api.dispatch = store.dispatch;

  return store;
}
