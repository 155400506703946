/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import {
  Button, Input, Icon,
} from 'rtm-ui-components';
import Message from '../../../SharedComponents/Message/Message';
import './LoginForm.css';

class LoginForm extends Component {
  state = {
    username: '',
    password: '',
    hidePassword: true,
  };

  hideIcon = () => {
    const { hidePassword } = this.state;
    return hidePassword ? 'showPassword' : 'hidePassword';
  }

  togglePassword = () => {
    const { hidePassword } = this.state;
    this.setState({ hidePassword: !hidePassword });
  }

  hideText = () => {
    const { hidePassword } = this.state;
    return hidePassword ? 'Show password' : 'Hide password';
  }

  showMessage = (errorMessage, authMessage) => {
    if (errorMessage) {
      return <Message negative visible header={errorMessage} className="login-error" />;
    }
    if (authMessage) {
      return (
        <Message positive className="login-error" header={authMessage} />
      );
    }
    return null;
  }

  handleInput = (t) => {
    this.setState({ [t.name]: t.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { username, password } = this.state;
    onSubmit({
      username,
      password,
    });
  }

  isDisabled = () => {
    const { username, password } = this.state;

    if (!username || !username.trim()) {
      return true;
    }
    if (!password || !password.trim()) {
      return true;
    }
    return false;
  }

  render() {
    const {
      loading, errorMessage, authMessage, samlEnabled,
    } = this.props;
    const { hidePassword } = this.state;
    return (
      <form onSubmit={async (e) => { await this.handleSubmit(e); }} className="login-form">
        <div className="logo">
          <Icon height="100px" width="250px" name="RTM" />
        </div>
        <div className="login-form-field login-username">
          <label htmlFor="username">Username</label>
          <Input placeholder="Username" name="username" type="text" id="username" onChange={e => this.handleInput(e.target)} fluid />
        </div>
        <div className="login-form-field login-password">
          <label htmlFor="password">Password</label>
          <Input placeholder="Password" name="password" type={hidePassword ? 'password' : 'text'} id="password" onChange={e => this.handleInput(e.target)} fluid />
        </div>
        <div id="toggle-password">
          <label className="login-password-toggle" onClick={this.togglePassword}><Icon className="password-show-icon" size="small" color="primary" actionable name={this.hideIcon()} />{this.hideText()}</label>
        </div>
        {this.showMessage(errorMessage, authMessage)}
        <Button
          variant="primary"
          loading={loading && !authMessage}
          type="submit"
          id="login-btn"
          disabled={this.isDisabled()}
          fluid
        >LOGIN
        </Button>
        <div className="password-recovery-link">
          Forgot your <Link className="reset-password-link" to="/recoverpassword">password?</Link>
        </div>
        {(!samlEnabled || !samlEnabled.value) ? (
          <area></area>
        ): (
          <div className="saml-login">
          <Link className="saml-login-link" to="/login?SSO">Login with SSO (SAML)</Link>
        </div>
        )}
      </form>
    );
  }
}

export default LoginForm;
