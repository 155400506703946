import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Input, Label, Checkbox, Button, Popup, Icon,
} from 'rtm-ui-components';
import Message from '../../SharedComponents/Message/Message';
import * as botsActions from '../../../store/actions/bots';
import { isValidEmail, invalidPassword, invalidUsername } from '../../../utils';
import '../../../assets/stylesheets/AddEntityForm.css';

class AddBotForm extends Component {
  state = {
    submitting: false,
    username: '',
    password: '',
    email: '',
    isPublic: false,
    touched: {
      password: false,
      username: false,
    },
    formErrors: {
      password: false,
      username: false,
    },
  }

  componentDidMount() {
    const { clearErrors } = this.props;
    clearErrors();
  }

  toggleIsPublic = () => {
    const { isPublic } = this.state;
    this.setState({
      isPublic: !isPublic,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      username, email, password, isPublic,
    } = this.state;
    const { onSubmit } = this.props;
    this.setState({ submitting: true });

    const submittedDetails = {
      username,
      email,
      password,
      isPublic,
    };
    try {
      await onSubmit(submittedDetails);
    // eslint-disable-next-line no-empty
    } catch (error) {}
    this.setState({ submitting: false });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const { formErrors, touched, isPublic } = this.state;
    const { usernameConfig, passwordConfig } = this.props;
    switch (name) {
      case 'username':
        if (invalidUsername(value, usernameConfig)) {
          formErrors.username = true;
        } else {
          formErrors.username = false;
        }
        touched.username = true;
        break;
      case 'password':
        if (invalidPassword(value, passwordConfig)) {
          formErrors.password = true;
        } else {
          formErrors.password = false;
        }
        touched.password = true;
        break;
      case 'email':
        if (value && !isValidEmail(value)) {
          formErrors.email = true;
        } else {
          formErrors.email = false;
        }
        touched.email = true;
        break;
      case 'isPublic':
        this.setState({ isPublic: !isPublic });
        break;
      default:
        break;
    }
    this.setState({ formErrors, touched, [name]: value });
  }

  isDisabled = () => {
    const {
      formErrors, touched,
    } = this.state;
    let error = false;
    Object.keys(formErrors).forEach((key) => {
      if (formErrors[key]) {
        error = true;
      }
    });
    Object.keys(touched).forEach((key) => {
      if (!touched[key]) {
        error = true;
      }
    });
    return error;
  }

  render() {
    const {
      usernameConfig, passwordConfig, error,
    } = this.props;
    const {
      submitting, formErrors, isPublic,
    } = this.state;
    return (
      <div className="add-entity-view">
        {error ? (
          <div className="form-message-container">
            <Message negative header={error} />
          </div>
        ) : null
        }
        <form onSubmit={this.handleSubmit} id="add-user-form">
          <div className="add-entity-field">
            <Label text="Username" info={usernameConfig && usernameConfig.description} htmlFor="username-field" />
            <Input
              fluid
              type="text"
              placeholder="Username"
              id="username-field"
              name="username"
              onChange={this.handleChange}
              error={formErrors.username}
            />
          </div>
          <div className="add-entity-field">
            <Label text="Password" info={passwordConfig && passwordConfig.description} htmlFor="password-field" />
            <Input
              fluid
              type="password"
              placeholder="Password"
              id="password-field"
              name="password"
              onChange={this.handleChange}
              error={formErrors.password}
            />
          </div>
          <div className="add-entity-field">
            <Label text="Email" htmlFor="email-field" />
            <Input
              fluid
              type="email"
              placeholder="Email"
              id="email-field"
              name="email"
              onChange={this.handleChange}
              error={formErrors.email}
            />
          </div>
          <div className="add-entity-field">
            <div className="is-public-checkbox">
              <Checkbox
                id="is-public"
                checked={isPublic}
                onChange={this.toggleIsPublic}
                name="isPublic"
                label={(
                  <div className="bot-visiblity-icon" style={{ display: 'flex' }}>
                    Public
                    <Popup
                      on="hover"
                      content="Public bots are visible to non-members"
                      trigger={(<Icon style={{ marginLeft: '10px' }} width="13px" height="13px" color="primary" name="info" ariaLabel="info" />)}
                      xOffset={-6}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="add-entity-create-button">
            <Button
              variant="primary"
              loading={submitting}
              type="submit"
              id="create-bot-btn"
              disabled={this.isDisabled()}
            >Create a New Bot
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  clearErrors: botsActions.clearFormErrors,
}, dispatch));

const mapStateToProps = state => ({
  passwordConfig: state.configs.configs['password.strength'],
  usernameConfig: state.configs.configs['username.pattern'],
  error: state.bots.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBotForm);
