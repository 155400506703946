import * as types from '../actions/actionTypes';
import { paginationLimit } from '../../constants';

export default function auditLogsReducer(state = {
  hasMore: true,
  events: [],
  operations: [],
  error: '',
}, action) {
  switch (action.type) {
    case types.LOAD_AUDITLOGS_SUCCESS:
    case types.SEARCH_AUDITLOGS_SUCCESS: {
      const events = [...state.events, ...action.payload.events];
      const hasMore = events.length - state.events.length === paginationLimit;
      return {
        ...state,
        events,
        isSearching: false,
        error: '',
        hasMore,
      };
    }
    case types.CLEAR_AUDIT_EVENTS: {
      return {
        ...state,
        hasMore: true,
        events: [],
      };
    }
    case types.LOAD_AUDITLOGS_FAILURE:
    case types.SEARCH_AUDITLOGS_FAILURE: {
      return {
        ...state,
        events: [],
        error: action.payload,
        isSearching: false,
      };
    }
    case types.AUDITLOGS_LOADING: {
      return { ...state, isSearching: true };
    }
    case types.LOAD_AUDITLOGOPERATIONS_SUCCESS: {
      return {
        ...state,
        operations: action.payload,
      };
    }
    case types.LOAD_AUDITLOGOPERATIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.LOGOUT_FAILURE:
    case types.LOGOUT_SUCCESS: {
      return {
        events: [],
        operations: [],
        error: '',
      };
    }
    default: {
      return state;
    }
  }
}
