import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  TopNavigation, CurrentUser, Content, Label, Dropdown, Input,
} from 'rtm-ui-components';
import AddEntity from '../SharedComponents/AddEntity/AddEntity';
import { paginationLimit, groupVisibilityFilters } from '../../constants';
import { getTotalRows } from '../../utils';
import * as groupActions from '../../store/actions/groups';
import * as authActions from '../../store/actions/auth';
import EditGroup from '../Groups/EditGroup/EditGroup';
import PageContent from '../SharedComponents/PageContent';

class GroupsRoutes extends Component {
  state = {
    lastSearchName: null,
    visibility: null,
    name: undefined,
    page: 0,
    limit: paginationLimit,
    addGroup: false,
    cards: true,
  };

  componentDidMount = () => {
    const { getConfigs, getLoggedInUserGroups } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    this.searchGroups();
    if (settings && settings.listingCards !== undefined && !settings.listingCards) {
      this.setState({ cards: false });
    }
    getConfigs();
    getLoggedInUserGroups();
  };

  setVisibility = async (value) => {
    if (value === 'all') {
      await this.setState({ visibility: null, page: 0 });
    } else {
      await this.setState({ visibility: value, page: 0 });
    }
    this.searchGroups();
  };

  setName = async (value) => {
    if (!value) {
      await this.setState({ name: undefined, page: 0 });
    } else {
      await this.setState({ name: value, page: 0 });
    }
    const { lastSearchName, name } = this.state;
    if (lastSearchName !== name) {
      this.searchGroups();
    }
    await this.setState({ lastSearchName: name });
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.listingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  searchGroups = () => {
    const {
      name, visibility, page, limit,
    } = this.state;
    const { getGroups } = this.props;
    const params = {
      name: name || '',
      visibility: visibility || '',
      page,
      limit,
    };
    getGroups(params);
  }

  changePage = async (activePage) => {
    await this.setState({ page: activePage });
    this.searchGroups();
  }

  refreshGroup = async (groupId) => {
    const { history } = this.props;
    history.push(groupId);
  }

  render() {
    const { addGroup, cards } = this.state;
    const {
      user, groups, isLarge, userActions, version, selectedGroup, hasBanner,
    } = this.props;

    const detailsgroupVisibilityFilters = groupVisibilityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const filters = [];
    filters.push(
      <div key="filter-groups-input" className="filter-input filter-wrapper">
        <Label text="Filter" />
        <Input
          id="filter-groups-input"
          placeholder="Group name"
          onChange={({ target }) => this.setName(target.value)}
          icon="search"
          debounceTimer={300}
        />
      </div>
    );
    filters.push(
      <div key="visibility" className="filter-wrapper">
        <Label text="Visibility" />
        <Dropdown
          id="filter-visibility"
          defaultValue="All"
          options={detailsgroupVisibilityFilters}
          onChange={item => this.setVisibility(item.value)}
        />
      </div>
    );

    const addGroupContent = {
      function: () => this.setState({ addGroup: true }),
      text: 'Create a new group',
      id: 'add-group-header-btn',
      requiredRole: 'isNotExternalUser',
    };
    return (
      <div>
        <Route
          path="/groups"
          render={() => (
            <React.Fragment>
              <TopNavigation
                id="top-nav"
                isLarge={isLarge}
                currentUser={<CurrentUser actions={userActions} id="current-user" role={user.appRole} version={version ? version.value : ''} username={`${user.firstName} ${user.lastName}`} />}
              >
                <h3>Groups</h3>
              </TopNavigation>
              <Content
                hasBanner={hasBanner}
                isLarge={isLarge}
                className="content-wrapper groups-content"
              >
                <PageContent
                  className={addGroup ? 'disable-scroll' : ''}
                  currentPage="groups"
                  cards={cards}
                  allGroups={groups.groups}
                  totalResults={getTotalRows(groups, 'groups')}
                  totalPages={groups.totalPages}
                  usersPage={groups.page}
                  changePage={this.changePage}
                  changeListingType={this.changeListingStyle}
                  filterDropdowns={filters}
                  createButton={addGroupContent}
                />
              </Content>
            </React.Fragment>
          )}
        />
        <Switch>
          <Route
            exact
            path="/groups/:groupId"
            render={props => (
              <EditGroup
                {...props}
                openModal
                refreshGroup={this.refreshGroup}
                group={selectedGroup}
                searchGroups={this.searchGroups}
              />
            )}
          />
        </Switch>
        {addGroup
          && <AddEntity addGroupOpen={addGroup} searchGroups={this.searchGroups} onClose={() => this.setState({ addGroup: false })} />
        }
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.auth.user,
  groups: state.groups.groups,
  selectedGroup: state.groups.selectedGroup,
  version: state.configs.configs['application.version'],
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getGroups: groupActions.getGroups,
  getConfigs: authActions.getConfigs,
  getLoggedInUserGroups: authActions.getLoggedInUserGroups,
  loadGroupDetail: groupActions.loadGroupDetail,
  clearSelectedGroup: groupActions.clearSelectedGroup,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(GroupsRoutes);
