import * as types from '../actions/actionTypes';

const initialState = {
  notification: '',
  hidden: true,
  error: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notification: action.payload,
        hidden: false,
      };
    }
    case types.DELETE_NOTIFICATION_SUCCESS:
    case types.HIDE_NOTIFICATION: {
      return {
        ...state,
        hidden: true,
      };
    }

    case types.SET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case types.DELETE_NOTIFICATION_FAILURE:
    case types.GET_NOTIFICATION_FAILURE:
    case types.SET_NOTIFICATION_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
