import React from 'react';
import Message from '../../SharedComponents/Message/Message';

const ErrorMessage = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <Message negative header={message} />
  );
};

export default ErrorMessage;
