import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  TopNavigation, CurrentUser, Content, Label, Dropdown, Input,
} from 'rtm-ui-components';
import AddEntity from '../SharedComponents/AddEntity/AddEntity';
import { paginationLimit, userRoleFilters, userActivityFilters } from '../../constants';
import * as usersActions from '../../store/actions/users';
import * as authActions from '../../store/actions/auth';
import { getTotalRows } from '../../utils';
import PageContent from '../SharedComponents/PageContent';
import EditUser from '../Users/EditUser/EditUser';

class UsersRoutes extends Component {
  state = {
    lastSearchName: null,
    name: undefined,
    activityFilter: 'active',
    roleFilter: null,
    page: 0,
    limit: paginationLimit,
    addUser: false,
    cards: true,
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.listingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  componentDidMount = () => {
    const { getConfigs, refreshLoggedInUser } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    this.searchUsers();
    if (settings && settings.listingCards !== undefined && !settings.listingCards) {
      this.setState({ cards: false });
    }
    getConfigs();
    refreshLoggedInUser();
  };


  setRoleFilter = async (value) => {
    if (value === 'all') {
      await this.setState({ roleFilter: null, page: 0 });
    } else {
      await this.setState({ roleFilter: value, page: 0 });
    }
    this.searchUsers();
  };

  setActivityFilter = async (value) => {
    await this.setState({ activityFilter: value, page: 0 });
    this.searchUsers();
  };

  setName = async (value) => {
    if (!value) {
      await this.setState({ name: undefined, page: 0 });
    } else {
      await this.setState({ name: value, page: 0 });
    }
    const { lastSearchName, name } = this.state;
    if (lastSearchName !== name) {
      this.searchUsers();
    }
    await this.setState({ lastSearchName: name });
  }

  searchUsers = async () => {
    const {
      name, activityFilter, roleFilter, page, limit,
    } = this.state;
    const { getUsers } = this.props;
    const params = {
      name: name || '',
      inactive: activityFilter === 'inactive',
      onlyLocked: activityFilter === 'locked',
      role: roleFilter || '',
      page,
      limit,
    };
    getUsers(params);
  }

  changePage = async (activePage) => {
    await this.setState({ page: activePage });
    this.searchUsers();
  }

  render() {
    const { addUser, cards } = this.state;
    const {
      user, users, isLarge, version, userActions, selectedUser, hasBanner,
    } = this.props;

    const detailsUserRoleFilter = userRoleFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });


    const detailsUserActivityFilters = userActivityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });

    const filters = [];
    filters.push(
      <div key="filter-users-input" className="filter-input filter-wrapper">
        <Label text="Filter" />
        <Input
          id="filter-users-input"
          placeholder="Name or ID"
          onChange={({ target }) => this.setName(target.value)}
          icon="search"
          debounceTimer={300}
        />
      </div>
    );

    filters.push(
      <div key="user-status" className="filter-wrapper">
        <Label text="User status" />
        <Dropdown
          id="filter-status"
          defaultValue={detailsUserActivityFilters[0].text}
          options={detailsUserActivityFilters}
          onChange={item => this.setActivityFilter(item.value)}
        />
      </div>
    );

    filters.push(
      <div key="user-role" className="filter-wrapper">
        <Label text="User role" />
        <Dropdown
          id="filter-role"
          defaultValue={detailsUserRoleFilter[0].text}
          options={detailsUserRoleFilter}
          onChange={item => this.setRoleFilter(item.value)}
        />
      </div>
    );
    const addUserContent = {
      function: () => this.setState({ addUser: true }),
      text: 'Create a new user',
      id: 'add-user-header-btn',
      requiredRole: 'admin',
    };
    return (
      <div>
        <Route
          path="/users"
          render={() => (
            <React.Fragment>
              <TopNavigation
                id="top-nav"
                isLarge={isLarge}
                currentUser={<CurrentUser actions={userActions} id="current-user" role={user.appRole} version={version ? version.value : ''} username={`${user.firstName} ${user.lastName}`} />}
              >
                <h3>Users</h3>
              </TopNavigation>
              <Content
                hasBanner={hasBanner}
                isLarge={isLarge}
                className="content-wrapper"
              >
                <PageContent
                  className={addUser ? 'disable-scroll' : ''}
                  currentPage="users"
                  cards={cards}
                  allUsers={users.users}
                  totalResults={getTotalRows(users, 'users')}
                  totalPages={users.totalPages}
                  usersPage={users.page}
                  changePage={this.changePage}
                  changeListingType={this.changeListingStyle}
                  filterDropdowns={filters}
                  createButton={addUserContent}
                />
              </Content>

            </React.Fragment>
          )}
        />
        <Switch>
          <Route
            exact path="/users/:userId"
            render={props => (
              <EditUser
                {...props}
                openModal
                user={selectedUser}
                searchUsers={this.searchUsers}
              />
            )}
          />
        </Switch>
        {addUser
          && <AddEntity addUserOpen={addUser} searchUsers={this.searchUsers} onClose={() => this.setState({ addUser: false })} />
        }
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
  version: state.configs.configs['application.version'],
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getUsers: usersActions.getUsers,
  getConfigs: authActions.getConfigs,
  refreshLoggedInUser: authActions.refreshLoggedInUser,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(UsersRoutes);
