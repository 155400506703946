import React from 'react';
import PropTypes from 'prop-types';
import './Divider.css';

const Divider = ({ children }) => (
  <div className="divider">
    {children}
  </div>
);

Divider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Divider;
