import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'rtm-ui-components';
import './AddEntityWrapper.css';

const AddEntityWrapper = ({
  children, title, onClose, open,
}) => (
  <Modal
    id="add-entity"
    open={open}
    onClose={onClose}
    size="small"
    disableCloseOnDimmerClick
  >
    <div className="add-entity-modal-container">
      <h4>{ title }</h4>
      <div className="add-entity-form-container">
        { children }
      </div>
    </div>
  </Modal>
);

AddEntityWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

AddEntityWrapper.defaultProps = {
  open: false,
};

export default AddEntityWrapper;
