import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Search } from 'rtm-ui-components';
import Tag from '../../../../Wrappers/Tag/Tag';
import { dropDownLimit } from '../../../../../constants';
import * as groupActions from '../../../../../store/actions/groups';

class AddNestedGroups extends Component {
  state = {
    selectedGroups: [],
    name: '',
    page: 1,
    limit: dropDownLimit,
    loading: false,
  }

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ name: '' });
  };

  handleResultSelect = (result) => {
    const { selectedGroups } = this.state;
    const tempGroups = [...selectedGroups];
    tempGroups.push(result);
    this.setState({ selectedGroups: tempGroups });
    this.resetComponent();
  }

  handleSearchChange = async (event) => {
    await this.setState({ name: event.target.value });
    if (event.target.value.length < 1) {
      this.resetComponent();
    } else {
      this.searchGroups();
    }
  }

  handleSubmit = async () => {
    const { selectedGroups } = this.state;
    const {
      addAction, selectedGroup, addNestedGroup,
    } = this.props;
    this.setState({ loading: true });
    const promises = selectedGroups.map(group => addNestedGroup(group, selectedGroup));
    this.setState({ name: '', selectedGroups: [] });
    await Promise.all(promises);
    this.setState({ loading: false });
    addAction();
  }

  removeSelectedGroup = (index) => {
    const { selectedGroups } = this.state;
    const tempGroups = [...selectedGroups];
    tempGroups.splice(index, 1);
    this.setState({ selectedGroups: tempGroups });
  }

  searchGroups = () => {
    const {
      name, page, limit,
    } = this.state;
    const { searchGroups } = this.props;
    const params = {
      name: name || '',
      page: page - 1,
      limit,
    };
    searchGroups(params);
  }

  renderGroup = (groupKey, index) => {
    const group = groupKey;
    return (
      <Tag key={group.uuid} onDelete={() => this.removeSelectedGroup(index)} name={group.key} text={`${group.text}`} />
    );
  };

  render() {
    const {
      selectedGroup, searchResults, isSearching,
    } = this.props;
    const { selectedGroups, loading } = this.state;
    const addedGroups = selectedGroup.childGroups.groups;
    const addedSelectedGroups = [...addedGroups, ...selectedGroups].map(g => g.name);
    //let results = _.differenceWith(searchResults, [...addedGroups, ...selectedGroups, selectedGroup], (u1, u2) => u1.uuid === u2.uuid);
    const results = searchResults.map(g => ({
      id: `${g.name}-result`,
      key: g.name,
      uuid: g.uuid,
      text: g.longname,
    }));

    const selectedGroupsWrapper = selectedGroups && selectedGroups.map(this.renderGroup);
    return (
      <div className="add-entity-group add-users">
        <Search
          fluid
          loading={isSearching}
          debounceTimer={300}
          id="add-nested-groups-to-group"
          placeholder="Search Groups"
          onChange={e => this.handleSearchChange(e)}
          results={results}
          onResultSelect={this.handleResultSelect}
          disabledKeys={addedSelectedGroups}
        />
        {selectedGroupsWrapper && (<div className="tags">{selectedGroupsWrapper}</div>)}
        <div className="btn-container">
          <Button
            loading={loading}
            variant="primary"
            type="submit"
            id="add-entity-btn"
            onClick={this.handleSubmit}
            disabled={!selectedGroups || selectedGroups.length === 0}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.groups.searchedGroups.groups,
  isSearching: state.groups.isSearching,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  addNestedGroup: groupActions.addNestedGroup,
  searchGroups: groupActions.searchGroups,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AddNestedGroups);
