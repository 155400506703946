import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  TopNavigation, CurrentUser, Content, Label, Input, Dropdown,
} from 'rtm-ui-components';
import AddEntity from '../SharedComponents/AddEntity/AddEntity';
import { getTotalRows } from '../../utils';
import {
  paginationLimit, botRoleFilters, botActivityFilters, botVisibilityFilters,
} from '../../constants';
import * as botsActions from '../../store/actions/bots';
import * as authActions from '../../store/actions/auth';
import EditBot from '../Bots/EditBot/EditBot';
import PageContent from '../SharedComponents/PageContent';

class BotsRoutes extends Component {
  state = {
    name: undefined,
    activityFilter: 'active',
    roleFilter: null,
    page: 0,
    visibilityFilter: null,
    lastSearchName: null,
    limit: paginationLimit,
    cards: true,
    addBot: false,
  };

  componentDidMount = () => {
    const { getConfigs, getLoggedInUserBots } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    this.searchBots();
    if (settings && settings.listingCards !== undefined && !settings.listingCards) {
      this.setState({ cards: false });
    }
    getConfigs();
    getLoggedInUserBots();
  };

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.listingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  setName = async (value) => {
    if (!value) {
      await this.setState({ name: undefined });
    } else {
      await this.setState({ name: value });
    }
    const { lastSearchName, name } = this.state;
    if (lastSearchName !== name) {
      this.changePage(0);
      this.searchBots();
    }
    await this.setState({ lastSearchName: name });
  }

  setRoleFilter = async (value) => {
    if (value === 'all') {
      await this.setState({ roleFilter: null });
    } else {
      await this.setState({ roleFilter: value });
    }
    this.changePage(0);
    this.searchBots();
  };

  setActivityFilter = async (value) => {
    await this.setState({ activityFilter: value });
    this.changePage(0);
    this.searchBots();
  };

  setVisibilityFilter = async (value) => {
    if (value === 'all') {
      await this.setState({ visibilityFilter: null });
    } else {
      await this.setState({ visibilityFilter: value});
    }
    this.changePage(0);
    this.searchBots();
  };

  searchBots = () => {
    const {
      name, activityFilter, roleFilter, limit, page, visibilityFilter,
    } = this.state;
    const { getBots } = this.props;
    const params = {
      name: name || '',
      inactive: activityFilter === 'inactive',
      onlyLocked: activityFilter === 'locked',
      visibility: visibilityFilter || '',
      role: roleFilter || '',
      page,
      limit,
    };
    getBots(params);
  }

  getFilteredBots = (visibilityFilter, bots) => {
    let filteredBots = [...bots];
    if (!filteredBots) {
      return filteredBots;
    }
    if (['private', 'public'].includes(visibilityFilter)) {
      filteredBots = filteredBots.filter(bot => (visibilityFilter === 'public' && bot.isPublic) || (visibilityFilter === 'private' && !bot.isPublic));
    }
    return filteredBots;
  }

  changePage = async (activePage) => {
    await this.setState({ page: activePage });
    this.searchBots();
  }

  render() {
    const {
      addBot, cards,
    } = this.state;
    const {
      user, bots, isLarge, userActions, version, selectedBot, hasBanner,
    } = this.props;
    const detailsBotVisibilityFilters = botVisibilityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const detailsBotRoleFilters = botRoleFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const detailsBotActivityFilters = botActivityFilters.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    const filters = [];
    filters.push(
      <div key="filter-bots-input" className="filter-input filter-wrapper">
        <Label text="Filter" />
        <Input
          id="filter-bots-input"
          placeholder="Bot name"
          onChange={({ target }) => this.setName(target.value)}
          icon="search"
          debounceTimer={300}
        />
      </div>
    );
    filters.push(
      <div key="bot-type" className="filter-wrapper">
        <Label text="Bot type" />
        <Dropdown
          id="filter-bots-type"
          defaultValue={detailsBotRoleFilters[0].text}
          options={detailsBotRoleFilters}
          onChange={item => this.setRoleFilter(item.value)}
        />
      </div>
    );
    filters.push(
      <div key="bot-visibility" className="filter-wrapper">
        <Label text="Bot visibility" />
        <Dropdown
          id="filter-bots-visibility"
          defaultValue={detailsBotVisibilityFilters[0].text}
          options={detailsBotVisibilityFilters}
          onChange={item => this.setVisibilityFilter(item.value)}
        />
      </div>
    );
    filters.push(
      <div key="bot-status" className="filter-wrapper">
        <Label text="Bot status" />
        <Dropdown
          id="filter-bots-status"
          defaultValue={detailsBotActivityFilters[0].text}
          options={detailsBotActivityFilters}
          onChange={item => this.setActivityFilter(item.value)}
        />
      </div>
    );
    const addBotContent = {
      function: () => this.setState({ addBot: true }),
      text: 'Create a new bot',
      id: 'add-bot-header-btn',
      requiredRole: 'isNotExternalUser',
    };
    return (
      <div>
        <Route
          path="/bots"
          render={() => (
            <div>
              <TopNavigation
                id="top-nav"
                isLarge={isLarge}
                currentUser={<CurrentUser actions={userActions} id="current-user" role={user.appRole} version={version ? version.value : ''} username={`${user.firstName} ${user.lastName}`} />}
              >
                <h3>Bots</h3>
              </TopNavigation>
              <Content
                hasBanner={hasBanner}
                isLarge={isLarge}
                className="content-wrapper bots-content"
              >
                <PageContent
                  className={addBot ? 'disable-scroll' : ''}
                  currentPage="bots"
                  cards={cards}
                  allBots={bots.bots}
                  totalResults={getTotalRows(bots, 'bots')}
                  totalPages={bots.totalPages}
                  usersPage={bots.page}
                  changePage={this.changePage}
                  changeListingType={this.changeListingStyle}
                  filterDropdowns={filters}
                  createButton={addBotContent}
                />
              </Content>
            </div>
          )}
        />
        <Switch>
          <Route
            exact
            path="/bots/:botId"
            render={props => (
              <EditBot
                {...props}
                openModal
                bot={selectedBot}
                searchBots={this.searchBots}
              />
            )}
          />
        </Switch>
        {addBot
          && (
            <AddEntity
              addBotOpen={addBot}
              searchBots={this.searchBots}
              onClose={() => { this.setState({ addBot: false }); }
              }
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  bots: state.bots.bots,
  selectedBot: state.bots.selectedBot,
  version: state.configs.configs['application.version'],
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getBots: botsActions.getBots,
  getUserBots: botsActions.getUserBots,
  getConfigs: authActions.getConfigs,
  getLoggedInUserBots: authActions.getLoggedInUserBots,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(BotsRoutes);
