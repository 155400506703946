export const botRoleFilters = [
  {
    key: 'all', value: 'all', text: 'All', id: 'filter-type-all',
  },
  {
    key: 'default', value: 'default', text: 'Default', id: 'filter-type-default',
  },
  {
    key: 'integration', value: 'integration', text: 'Integration', id: 'filter-type-integration',
  },
  {
    key: 'admin', value: 'admin', text: 'Admin', id: 'filter-type-admin',
  },
];

export const botActivityFilters = [
  // {
  //   key: 'all', value: 'all', text: 'All', id: 'filter-status-all',
  // },
  {
    key: 'active', value: 'active', text: 'Active', id: 'filter-status-active',
  },
  {
    key: 'inactive', value: 'inactive', text: 'Inactive', id: 'filter-status-inactive',
  },
  {
    key: 'locked', value: 'locked', text: 'Locked', id: 'filter-status-locked',
  },
];

export const botVisibilityFilters = [
  {
    key: 'all', value: 'all', text: 'All', id: 'filter-visibility-all',
  },
  {
    key: 'private', value: 'private', text: 'Private', id: 'filter-visibility-private',
  },
  {
    key: 'public', value: 'public', text: 'Public', id: 'filter-visibility-public',
  },
];

export const groupVisibilityFilters = [
  {
    key: 'all', value: 'all', text: 'All', id: 'filter-visibility-all',
  },
  {
    key: 'private', value: 'private', text: 'Private', id: 'filter-visibility-private',
  },
  {
    key: 'public', value: 'public', text: 'Public', id: 'filter-visibility-public',
  },
];
export const userRoleFilters = [
  {
    key: 'all', value: 'all', text: 'All', id: 'filter-type-all',
  },
  {
    key: 'users', value: 'user', text: 'Users', id: 'filter-type-users',
  },
  {
    key: 'admins', value: 'admin', text: 'Admins', id: 'filter-type-admins',
  },
  {
    key: 'sysadmin', value: 'sysadmin', text: 'System admins', id: 'filter-type-sysadmins',
  },
  {
    key: 'external', value: 'ext', text: 'External users', id: 'filter-type-external',
  },
];

export const userGroupRoleFilters = [
  {
    key: 'all', value: 'all', text: 'All', id: 'filter-group-all',
  },
  {
    key: 'users', value: 'user', text: 'Users', id: 'filter-group-users',
  },
  {
    key: 'admins', value: 'admin', text: 'Admins', id: 'filter-group-admins',
  },
];

export const userActivityFilters = [
  // {
  //   key: 'all', value: 'all', text: 'All', id: 'filter-status-all',
  // },
  {
    key: 'active', value: 'active', text: 'Active', id: 'filter-status-active',
  },
  {
    key: 'inactive', value: 'inactive', text: 'Inactive', id: 'filter-status-inactive',
  },
  {
    key: 'locked', value: 'locked', text: 'Locked', id: 'filter-status-locked',
  },
];


export const paginationLimit = 100;
export const dropDownLimit = 5000;