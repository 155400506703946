import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Icon, Input, Label, RadioButton, Checkbox,
} from 'rtm-ui-components';
import Message from '../../../SharedComponents/Message/Message';
import * as botsActions from '../../../../store/actions/bots';
import HasRole from '../../../../roles/HasRole';
import { isValidEmail, invalidPassword, invalidUsername } from '../../../../utils';
import '../../../../assets/stylesheets/EditForm.css';

class EditBotForm extends Component {
  state = {
    submitting: false,
    submitted: false,
    email: '',
    username: '',
    password: '',
    isPublic: '',
    role: '',
    uuid: '',
    touched: {
      email: true,
      username: true,
      password: true,
      role: true,
      isPublic: true,
    },
    formErrors: {
      email: false,
      username: false,
      password: false,
      isPublic: false,
    },
  }

  componentDidMount() {
    const { initialValues } = this.props;
    this.setState({
      email: initialValues.email,
      username: initialValues.username,
      password: initialValues.password,
      isPublic: initialValues.isPublic,
      role: initialValues.role,
      uuid: initialValues.uuid,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      email, username, password, isPublic, uuid, role,
    } = this.state;
    const { updateBot, searchBots } = this.props;
    this.setState({ submitting: true });
    const submittedDetails = {
      email,
      username,
      password,
      isPublic,
      role,
      uuid,
    };
    await updateBot(submittedDetails);
    searchBots();
    this.setState({ submitted: true, submitting: false });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const { formErrors } = this.state;
    const { passwordConfig, usernameConfig } = this.props;
    switch (name) {
      case 'email':
        if (!isValidEmail(value)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        break;
      case 'username':
        if (invalidUsername(value, usernameConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        break;
      case 'password':
        if (invalidPassword(value, passwordConfig)) {
          formErrors[name] = true;
        } else {
          formErrors[name] = false;
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors, [name]: value, submitted: false,
    });
  }

  isDisabled = () => {
    const { formErrors, role } = this.state;
    const { bot } = this.props;
    let error = false;
    Object.keys(formErrors).forEach((key) => {
      if (formErrors[key]) {
        error = true;
      }
    });
    if (error) { return true; }
    const fields = ['password', 'username', 'email', 'isPublic'];
    let notChanged = true;
    fields.forEach((field) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (bot[field] !== this.state[field]) {
        notChanged = false;
      }
    });
    if (bot.appRole !== role) {
      notChanged = false;
    }
    return notChanged;
  }

  toggleIsPublic = () => {
    const { isPublic } = this.state;
    this.setState({
      isPublic: !isPublic,
      submitted: false,
    });
  }

  changeRole = (event) => {
    this.setState({
      role: event.target.value,
      submitted: false,
    });
  }

  render() {
    const {
      onCancel, error, bot, botPassword, passwordConfig, usernameConfig, showPassword, hidePassword,
    } = this.props;
    const {
      email, username, password, formErrors, submitting, isPublic, role, submitted,
    } = this.state;
    return (
      <div className="details-edit-container">
        <form onSubmit={this.handleSubmit} id="add-group-form" className="add-entity-view-form">
          <div className="header-bar">
            <span className="header">EDIT DETAILS</span>
          </div>
          <div className="edit-form-fields">
            <div className="edit-fields-left">
              <div className="add-entity-field">
                <Label htmlFor="email" text="Email" />
                <Input
                  fluid
                  name="email"
                  value={email}
                  id="bot-email-field"
                  type="text"
                  placeholder="Email"
                  onChange={e => this.handleChange(e)}
                  error={formErrors.email}
                />
              </div>
              <div className="add-entity-field">
                <Label htmlFor="username" text="Username" info={usernameConfig && usernameConfig.description} />
                <Input
                  fluid
                  name="username"
                  value={username}
                  id="bot-username-field"
                  type="text"
                  placeholder="Username"
                  onChange={e => this.handleChange(e)}
                  error={formErrors.username}
                />
              </div>
              <div className="add-entity-field">
                <Label htmlFor="password" text="Password" info={passwordConfig && passwordConfig.description} />
                <Input
                  fluid
                  name="password"
                  type={botPassword ? 'text' : 'password'}
                  placeholder="Password"
                  passwordIcon
                  value={password}
                  id="bot-password-field"
                  onChange={e => this.handleChange(e)}
                  error={formErrors.password}
                />
                {botPassword
                  ? <p className="show-password" onClick={hidePassword}> <Icon color="primary" name="hidePassword" size="small" ariaLabel="hide Password" /> <span>Hide Password</span></p>
                  : <p className="show-password" onClick={() => showPassword(bot)}><Icon color="primary" name="showPassword" size="small" ariaLabel="show Password" /> <span>Show Password</span> </p>
                }
              </div>
              <HasRole requiredRole="admin">
                <Label text="Roles" />
                <div className="roles">
                  <RadioButton
                    className="role"
                    id="default"
                    onChange={this.changeRole}
                    name="role"
                    value="default"
                    checked={role === 'default'}
                    defaultChecked
                    label={(
                      <span className="role-icon-wrapper"><Icon color="primary" name="defaultBot" size="small" /> Default</span>
                    )}
                  />
                  <RadioButton
                    className="role"
                    id="integration"
                    onChange={this.changeRole}
                    name="role"
                    value="integration"
                    checked={role === 'integration'}
                    defaultChecked
                    label={(
                      <span className="role-icon-wrapper"><Icon style={{ fill: '#A198BE' }} name="integrationBot" size="small" /> Integration</span>
                    )}
                  />
                  <RadioButton
                    className="role"
                    id="admin"
                    onChange={this.changeRole}
                    name="role"
                    value="admin"
                    checked={role === 'admin'}
                    defaultChecked
                    label={(
                      <span className="role-icon-wrapper"><Icon color="pink" name="adminBot" size="small" /> Admin</span>
                    )}
                  />
                </div>
              </HasRole>
              <Label text="Status" />
              <div className="status roles">
                <Checkbox
                  className="role"
                  id="edit-bot-public"
                  defaultChecked
                  checked={isPublic}
                  label={(<span className="role-icon-wrapper"><Icon name="public" color="primary" ariaLabel="public" />Public</span>)}
                  name="isPublic"
                  onChange={this.toggleIsPublic}
                />
              </div>
            </div>
            <div className="side">
              <div className="edit-form-buttons">
                <Button
                  loading={submitting}
                  type="submit"
                  buttonClass="rootPurpleButton"
                  id="edit-bot-btn"
                  disabled={this.isDisabled()}
                  variant="primary"
                >Save
                </Button>
                <Button
                  id="close-btn"
                  onClick={onCancel}
                  variant="secondary"
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          <div className="form-message-container">
            {(submitted && !error) && (<Message className="updated-entity" positive header="Bot Updated" />)}
            {submitted && error && (<Message negative header={error} />)}
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  updateBot: botsActions.updateBot,
  showPassword: botsActions.showPassword,
  hidePassword: botsActions.hidePassword,
}, dispatch));

const mapStateToProps = (state) => {
  const { selectedBot: bot } = state.bots;
  return {
    initialValues: {
      username: bot.username,
      email: bot.email,
      password: bot.password,
      role: bot.appRole,
      uuid: bot.uuid,
      isPublic: bot.isPublic,
    },
    bot,
    botPassword: state.bots.password,
    error: state.bots.error,
    passwordConfig: state.configs.configs['password.strength'],
    usernameConfig: state.configs.configs['username.pattern'],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBotForm);
