import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'rtm-ui-components';
import {
  User, Bot, Group,
} from '../../../propTypes';
import Table from '../../SharedComponents/Table/Table';
import './TablesWrapper.css';

const TablesWrapper = ({
  totalPages, onPageChange,
  users, bots, groups, id, activePage, showPagination, className,
}) => (
  <div id="list-table" className={`${className} list-container table `}>
    <Table
      users={users || null}
      allGroups={groups || null}
      bots={bots || null}
    />
    { showPagination ? (
      <Pagination
        id={id}
        totalPages={totalPages}
        activePage={activePage}
        onPageChange={onPageChange}
      />
    ) : null}
  </div>
);

TablesWrapper.propTypes = {
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  activePage: PropTypes.number,
  users: PropTypes.arrayOf(
    PropTypes.shape(User)
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape(Group)
  ),
  bots: PropTypes.arrayOf(
    PropTypes.shape(Bot)
  ),
  id: PropTypes.string,
  showPagination: PropTypes.bool,
};

TablesWrapper.defaultProps = {
  showPagination: false,
  totalPages: 0,
  onPageChange: null,
  activePage: 1,
  bots: null,
  users: null,
  groups: null,
  id: '',
};

export default TablesWrapper;
