import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'rtm-ui-components';
import './CardsWrapper.css';

const CardsWrapper = ({
  totalPages, activePage, onPageChange,
  blocks, id, showPagination, className,
}) => (
  <div className={`content-wrap ${className}`}>
    <div id="list-cards" className="list-container">
      {blocks}
    </div>
    { showPagination ? (
      <Pagination
        id={id}
        totalPages={totalPages}
        activePage={activePage}
        onPageChange={onPageChange}
      />
    ) : null}
  </div>
);

CardsWrapper.propTypes = {
  totalPages: PropTypes.number,
  activePage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

CardsWrapper.defaultProps = {
  totalPages: undefined,
  activePage: undefined,
};

export default CardsWrapper;
