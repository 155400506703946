import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Icon } from 'rtm-ui-components';
import EditBotForm from './EditBotForm/EditBotForm';
import * as botActions from '../../../store/actions/bots';
import HasRole from '../../../roles/HasRole';
import BotUsers from './BotUsers/BotUsers';
import BotGroups from './BotGroups/BotGroups';
import DetailsViewWrapper from '../../Wrappers/DetailsViewWrapper/DetailsViewWrapper';
import DetailsTopRow from '../../SharedComponents/DetailsTopRow/DetailsTopRow';
import * as navigationActions from '../../../store/actions/navigation';
import { ucfirst, convertISODate } from '../../../utils.js';
import DetailsInfoRow from '../../SharedComponents/DetailsInfoRow/DetailsInfoRow';

class EditBot extends Component {
  state = { expand: false, showPassword: false, passwordCopied: false }

  async componentDidMount() {
    const { match, loadBotDetail } = this.props;
    await loadBotDetail(match.params.botId);
  }

  componentWillUnmount() {
    const { clearSelectedBot } = this.props;
    clearSelectedBot();
  }

  toggleEditBlock = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  }

  password = () => {
    const { showPassword } = this.state;
    const { bot } = this.props;
    if (showPassword) {
      return bot.password;
    }
    return '* '.repeat(8);
  }

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  passwordIcon = () => {
    const { showPassword } = this.state;
    if (showPassword) {
      return 'hidePassword';
    }
    return 'showPassword';
  }

  copyPassword = async () => {
    const { bot } = this.props;
    if(window.isSecureContext){
      navigator.clipboard.writeText(bot.password);
    }else{
      const textArea = document.createElement("textarea");
      textArea.value = bot.password;
            
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";
            
      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }

    const { passwordCopied } = this.state;
    this.setState({ passwordCopied: !passwordCopied });

    this.changeCopyTextBack();
  }
  changeCopyTextBack = () => {
      setTimeout(() => {
        const { passwordCopied } = this.state;
        this.setState({ passwordCopied: !passwordCopied });
      },1000)
  }

  copyPasswordText = () => {
    const { passwordCopied } = this.state;
    if(passwordCopied){
      return 'copied';
    }
    return 'copy';
  }

  botStatus = (bot) => {
    const { unlockBot, searchBots } = this.props;
    if (!bot.active) {
      return (
        <div className="col">
          <div className="head">Status</div>
          <div className="value">Inactive</div>
        </div>
      );
    } if (bot.locked) {
      return (
        <div className="col">
          <div className="head">Status</div>
          <div className="value">
            <div className="locked">Locked
              <HasRole requiredRole="botEdit" resource={bot}>
                <Icon
                  id="unlock-bot-button"
                  name="lock"
                  size="small"
                  ariaLabel="lock"
                  onClick={async () => {
                    await unlockBot(bot.uuid);
                    searchBots();
                  }}
                  className="unlock-button"
                  color="red"
                />
              </HasRole>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  activateBot = async (bot) => {
    const { activateBot, searchBots } = this.props;
    await activateBot(bot);
    searchBots();
  }

  deactivateBot = async (bot) => {
    const { deactivateBot, searchBots } = this.props;
    await deactivateBot(bot);
    searchBots();
  }

  closeModal = () => {
    const { clearEditView, history } = this.props;
    clearEditView();
    history.push('/bots');
  }

  backButton = () => {
    const { popEditView, editViewStack, history } = this.props;
    const goToUrl = editViewStack[editViewStack.length - 1];
    popEditView();
    history.push(goToUrl);
  }

  render() {
    const { expand } = this.state;
    const {
      openModal, bot, editViewStack,
    } = this.props;

    const {
      addUser, searchBots,
    } = this.props;

    if (!bot) {
      return null;
    }
    const panels = [
      {
        id: 'users-tab',
        icon: 'userPlain',
        title: 'Users',
        content: (
          <BotUsers
            bot={bot}
            addUser={addUser}
          />
        ),
      },
      {
        id: 'groups-tab',
        icon: 'groupPlain',
        title: 'Groups',
        content: (<BotGroups bot={bot} />),
      },
    ];

    return (
      <DetailsViewWrapper
        openModal={openModal}
        closeModal={this.closeModal}
        topRow={(
          <DetailsTopRow
            bot={bot}
            openEditForm={expand}
            activate={this.activateBot}
            deactivate={this.deactivateBot}
            closeModal={this.closeModal}
            back={editViewStack.length ? this.backButton : null}
            toggleEditBlock={this.toggleEditBlock}
          />
        )}
        infoRow={(
          <DetailsInfoRow
            role={ucfirst(bot.appRole)}
            status={this.botStatus(bot)}
            bot={bot}
            created={convertISODate(bot.created)}
            togglePassword={() => this.togglePassword()}
            passwordIcon={this.passwordIcon()}
            password={this.password()}
            showPassword={this.state.showPassword}
            copyPassword={() => this.copyPassword()}
            copyPasswordText={this.copyPasswordText()}
          />
        )}
        editEntity={expand
          ? (
            <EditBotForm
              searchBots={searchBots}
              onCancel={this.toggleEditBlock}
            />
          )
          : (null)}
        listing={<Tabs panels={panels} />}
      />
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  loadBotDetail: botActions.loadBotDetail,
  updateBot: botActions.updateBot,
  getBots: botActions.getBots,
  deactivateBot: botActions.deactivateBot,
  activateBot: botActions.activateBot,
  unlockBot: botActions.unlockBot,
  clearEditView: navigationActions.clearEditView,
  popEditView: navigationActions.popEditView,
  clearSelectedBot: botActions.clearSelectedBot,
}, dispatch));

const mapStateToProps = state => ({
  editViewStack: state.navigation.editViewStack,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBot);
