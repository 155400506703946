import React from 'react';
import PropTypes from 'prop-types';

const TableCell = ({
  id, children, customContent, text, className,
}) => (
  <span id={id} className={`${className} table-cell`}>{customContent ? children : text}</span>
);

TableCell.propTypes = {
  id: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  customContent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
};

TableCell.defaultProps = {
  id: null,
  text: null,
  customContent: false,
  children: null,
  className: '',
};

export default TableCell;
