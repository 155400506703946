import * as types from '../store/actions/actionTypes';

const loginFailure = error => ({ type: types.LOGIN_FAILURE, payload: error });

const getHeaders = async (plainTextResponse = false) => {
  const user = localStorage.getItem('user');
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;  charset=utf-8',
  };
  if (plainTextResponse) {
    headers['Content-Type'] = 'text/html';
  }
  if (user) {
    headers.Authorization = `Bearer ${JSON.parse(user).token}`;
  }
  return headers;
};


class Api {
  constructor() {
    this.state = { user: null };
    this.dispatch = null;
    this.baseurl = window.location.origin;
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    if (response.status === 401) {
      localStorage.removeItem('user');
      this.dispatch(loginFailure(response.error));
    }
    if (response.status === 504) {
      throw new Error('504');
    }
    throw new Error(response.statusText);
  }

  async get(uri, plainTextResponse = false) {
    const response = await fetch(`${this.baseurl}/api${uri}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: await getHeaders(plainTextResponse),
    });
    this.checkStatus(response);
    if (plainTextResponse) {
      return response.text();
    }
    return response.json();
  }

  async post(uri, data = {}) {
    const response = await fetch(`${this.baseurl}/api${uri}`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: await getHeaders(),
      body: data,
    });

    return response;
  }


  async postbody(uri, data = {}, plainText = false) {
    const postData = plainText ? data : JSON.stringify(data);
    const response = await fetch(`${this.baseurl}/api${uri}`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: await getHeaders(),
      body: postData,
    });
    return response;
  }

  async login(params = {}) {
    const response = await fetch(`${this.baseurl}/api/auth/login`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type': 'application/json;  charset=utf-8' },
      body: JSON.stringify(params),
    });
    const responseToken = await response.json();

    let loginRespObj = {};
    switch (response.status) {
      case 200:
        loginRespObj = {
          respCode: response.status,
          token: responseToken,
          user: params.username,
          userAuthorisation: !response.url.includes('login?error'),
        };
        break;
      case 204:
      case 302:
      default:
        loginRespObj = {
          respCode: response.status, response, error: responseToken.message, user: params.username,
        };
        break;
    }
    return loginRespObj;
  }

  async put(uri, params = {}) {
    const response = await fetch(`${this.baseurl}/api${uri}`, {
      method: 'PUT',
      headers: await getHeaders(),
      body: JSON.stringify(params),
    });
    return response;
  }

  async delete(uri, params = {}) {
    const response = await fetch(`${this.baseurl}/api${uri}`, {
      method: 'DELETE',
      headers: await getHeaders(),
      body: JSON.stringify(params),
    });
    return response;
  }
}

export default Api;
