import * as types from './actionTypes';

export const clearEditView = () => ({
  type: types.NAV_CLEAR_EDIT_VIEW,
});

export const pushEditView = payload => ({
  type: types.NAV_PUSH_EDIT_VIEW,
  payload,
});

export const popEditView = () => ({
  type: types.NAV_POP_EDIT_VIEW,
});
