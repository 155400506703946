import * as types from './actionTypes';

export const openModal = payload => ({
  type: types.OPEN_MODAL,
  payload,
});

export const closeModal = payload => ({
  type: types.CLOSE_MODAL,
  payload,
});
