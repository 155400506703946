import { combineReducers } from 'redux';

import auth from './auth';
import users from './users';
import groups from './groups';
import bots from './bots';
import modals from './modals';
import auditLogs from './auditLogs';
import configs from './configs';
import navigation from './navigation';
import notifications from './notifications';

const rootReducer = combineReducers({
  auditLogs,
  auth,
  users,
  bots,
  groups,
  modals,
  configs,
  navigation,
  notifications,
});

export default rootReducer;
