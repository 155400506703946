import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import AuthPage from '../AuthPage';
import * as authActions from '../../../../store/actions/auth';

const RecoverPassword = (props) => {
  const { user, recoverPassword } = props;

  if (user) {
    return (<Redirect to={"/logout"} />);
  }

  return (
    <AuthPage
      passwordrecoveryPage
      submitAction={recoverPassword}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  authError: state.auth.recoverPasswordError || state.auth.loginError,
});
const mapDispatchToProps = dispatch => (bindActionCreators({
  recoverPassword: authActions.recoverPassword,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
