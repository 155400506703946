import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from '../../store/actions/auth';

class LogoutPage extends Component {
  componentDidMount() {
    const { logout } = this.props;
    logout();
  }

  render() {
    const { user, location } = this.props;
    if (!user) {
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }
    return null;
  }
}

const mapStateToProps = state => ({ user: state.auth.user });
const mapDispatchToProps = dispatch => (bindActionCreators({
  logout: authActions.logout,
}, dispatch));


export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
