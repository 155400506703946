import * as types from '../actions/actionTypes';

const initialState = {
  modals: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case types.OPEN_MODAL:
    return {
      ...state,
      modals: state.modals.concat(action.payload)
    };
  case types.CLOSE_MODAL:
    return {
      ...state,
      modals: state.modals.filter(item => item.id !== action.payload.id),
    };
  case types.LOGOUT_FAILURE:
  case types.LOGOUT_SUCCESS: {
    return {
      modals: [],
    };
  }
  default:
    return state;
  }
}
