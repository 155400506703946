/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'rtm-ui-components';
import EditUserForm from './EditUserForm/EditUserForm';
import EditPasswordForm from './EditPasswordForm/EditPasswordForm';
import DetailsTopRow from '../../SharedComponents/DetailsTopRow/DetailsTopRow';
import * as userActions from '../../../store/actions/users';
import * as navigationActions from '../../../store/actions/navigation';
import UserGroups from './UserGroups/UserGroups';
import UserBots from './UserBots/UserBots';
import { resetPasswordLink } from '../../../store/actions/auth';
import DetailsViewWrapper from '../../Wrappers/DetailsViewWrapper/DetailsViewWrapper';
import { ucfirst } from '../../../utils.js';
import DetailsInfoRow from '../../SharedComponents/DetailsInfoRow/DetailsInfoRow';

class EditUser extends Component {
  state = { openEditForm: false, openPasswordForm: false };

  async componentDidMount() {
    const { match, loadUserDetail } = this.props;
    await loadUserDetail(match.params.userId);
  }

  componentWillUnmount() {
    const { clearSelectedUser } = this.props;
    clearSelectedUser();
  }

  toggleEditBlock = async () => {
    const { openEditForm, openPasswordForm } = this.state;
    const { clearUserErrors } = this.props;
    clearUserErrors();
    if (!openEditForm && openPasswordForm) {
      this.togglePasswordBlock();
    }
    await this.setState({
      openEditForm: !openEditForm,
    });
  }

  togglePasswordBlock = async () => {
    const { openEditForm, openPasswordForm } = this.state;
    const { clearUserErrors } = this.props;
    clearUserErrors();
    if (!openPasswordForm && openEditForm) {
      this.toggleEditBlock();
    }
    await this.setState({
      openPasswordForm: !openPasswordForm,
    });
  }

  deactivateUser = async (user) => {
    const { deactivateUser, searchUsers } = this.props;
    await deactivateUser(user);
    searchUsers();
  }

  activateUser = async (user) => {
    const { activateUser, searchUsers } = this.props;
    await activateUser(user);
    searchUsers();
  }

  unlockUser = async (uuid) => {
    const { unlockUser, searchUsers } = this.props;
    await unlockUser(uuid);
    searchUsers();
  }

  closeModal = () => {
    const { clearEditView, history } = this.props;
    clearEditView();
    history.push('/users');
  }

  backButton = () => {
    const { popEditView, editViewStack, history } = this.props;
    const goToUrl = editViewStack[editViewStack.length - 1];
    popEditView();
    history.push(goToUrl);
  }

  render() {
    const {
      openModal,
      selectedUser, authUser,
      updatePassword,
      searchUsers, editViewStack,
    } = this.props;

    const { openEditForm, openPasswordForm } = this.state;
    const user = authUser || selectedUser;

    if (!user) {
      return null;
    }

    const panels = [
      {
        id: 'groups-tab',
        title: 'Groups',
        icon: 'groupPlain',
        content: (<UserGroups user={user} />),
      },
      {
        title: 'Bots',
        icon: 'botPlain',
        id: 'bots-tab',
        content: (<UserBots user={user} />),
      },
    ];

    return (
      <DetailsViewWrapper
        openModal={openModal}
        closeModal={this.closeModal}
        topRow={(
          <DetailsTopRow
            user={user}
            deactivate={this.deactivateUser}
            activate={this.activateUser}
            back={editViewStack.length ? this.backButton : null}
            closeModal={this.closeModal}
            togglePasswordBlock={this.togglePasswordBlock}
            toggleEditBlock={this.toggleEditBlock}
          />
        )}
        infoRow={(
          <DetailsInfoRow
            user={user}
            username={user.username}
            role={ucfirst(user.appRole)}
            unlockUser={this.unlockUser}
          />
        )}
        editPassword={openPasswordForm
          ? (
            <EditPasswordForm
              onPasswordChange={async (data) => {
                await updatePassword(data);
              }}
              onCancel={this.togglePasswordBlock}
            />
          ) : null}
        editEntity={openEditForm
          ? (
            <EditUserForm
              searchUsers={searchUsers}
              onCancel={this.toggleEditBlock}
            />
          ) : (null)}
        listing={<Tabs className="user-tabs" panels={panels} />}
      />
    );
  }
}

const mapDispatchToProps = dispatch => (bindActionCreators({
  updateUser: userActions.updateUser,
  getUsers: userActions.getUsers,
  deactivateUser: userActions.deactivateUser,
  activateUser: userActions.activateUser,
  unlockUser: userActions.unlockUser,
  updatePassword: userActions.updatePassword,
  clearUserErrors: userActions.clearFormErrors,
  resetPasswordLink,
  clearEditView: navigationActions.clearEditView,
  popEditView: navigationActions.popEditView,
  clearSelectedUser: userActions.clearSelectedUser,
  loadUserDetail: userActions.loadUserDetail,
}, dispatch));

const mapStateToProps = state => ({
  selectedUser: state.users.selectedUser,
  loggedInUser: state.auth.user,
  editViewStack: state.navigation.editViewStack,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser));
